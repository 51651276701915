<template>
            <div style="height: 100% !important;">
                <!-- <div class="font-weight-medium text-center primary--text" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h4' : 'pt-10 kindmind-title-font-h2'">
                    Explore 
                </div> -->
                <!-- <v-col cols="12"> -->
                    <!-- <div class=" text-center">
                        <div :class="$vuetify.breakpoint.smAndDown ? 'kindmind-font-h7-bold mb-0' : 'kindmind-font-h4-bold mb-8'">Select one of the categories below and start exploring</div>
                    </div> -->
                <!-- </v-col> -->

                <!-- Buttons -->
                 <div class="questionsGrid" :class="phoneviewport ? 'pt-6' : 'mt-14 pt-8'">
                 <div v-for="item in requirementQuestions" :key="item.name" :style="`border:1px solid var(--v-${item.requirementName}-base); border-radius: 20px;`" >
                    <div @click="sectionComponentRequest(item.requirementName)"
                        :dense="smalldevice"
                        :height="smalldevice ? 60 : 90"
                        class="rounded-km text-capitalize grey lighten-4 d-flex justify-start align-center"
                        :style="smalldevice ? 'height: 60px !important;' : 'height: 90px !important;'"
                        block
                        >
                    <div class="cardtagstatus" :class="`${item.requirementName}`"></div>
                        <div class="px-4" :class="$vuetify.breakpoint.smAndDown ? `kindmind-DMSans-font-h5-bold ${item.requirementName}--text` : `kindmind-DMSans-font-h4-bold ${item.requirementName}--text`">{{item.requirementName}}</div>
                        <v-spacer/>
                        <img :src="item.requirementImg" :alt="`${item.requirementName} section image`" :height="smalldevice ? 56 : 80" class="px-2">
                    </div>
                </div>
                 </div>

           </div>  
</template>

<script>
export default {
    name: 'explore-buttons-component',
    // * DATA
     data: () => ({
            requirementQuestions: [
            {requirementName: 'pause', requirementColor: 'pause', path: "/explore/pause", requirementImg: require('@/assets/explore_img/pause.png')},
            {requirementName: 'move', requirementColor: 'move', path: "/explore/move", requirementImg: require('@/assets/explore_img/move.png')},
            {requirementName: 'fuel', requirementColor: 'fuel', path: "/explore/fuel", requirementImg: require('@/assets/explore_img/fuel.png')},
            {requirementName: 'grow', requirementColor: 'grow', path: "/explore/grow", requirementImg: require('@/assets/explore_img/grow.png')},
         ],
         
    }),
    // * METHODS
    methods: {
        sectionComponentRequest(section) {
                this.$emit('newComponentRequest', section); 
        },
    },
    // * COMPUTED
     computed: {
        smalldevice() {
            return window.innerHeight < 600;
        },
        phoneviewport() {
            return window.innerWidth < 600;
        },
        }
}
</script>

<style scoped>
* {
    box-shadow: none !important;
}
.questionsGrid {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(390px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 16px;
    margin: 18px;
}

/* Styling the bar on each card */
.cardtagstatus {
    height: 100% !important;
    min-width: clamp(12px, 4vw, 18px);
    max-width: clamp(12px, 4vw, 18px);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

</style>