<template>
    <div class="grey lighten-4 allowscrolling">
        <!-- Induction questions for users. Divided in categories. Child element of Questions.vue -->
        <div class="grey lighten-4" style="height: 12vh !important"></div>
        <!-- TITLE AND QUESTIONS -->
        <div class="rounded-km-top white--text d-flex align-center flex-column" :class="sectionstyle" style="height: 87vh !important; width: 100% !important">
            <logo-initial-questions v-if="$vuetify.breakpoint.xsOnly" :requirementColor="sectionstyle" :padding="'pt-6'" :widthavatar="100" :heightavatar="112" />
            <logo-initial-questions v-else :requirementColor="sectionstyle" :padding="'pt-6'" :widthavatar="296" :heightavatar="154" />
            <v-row no-gutters :class="sectionstyle" style="width: 100% !important" class="d-flex align-center justify-center">
                <!-- MOBILE VIEW -->

                <!-- Single and multiple answer questions -->
                <v-col v-if="$vuetify.breakpoint.width < 1000 && !rankorder" cols="12" xs="12" sm="12" class="mt-10 pa-0 d-flex flex-column align-center justify-start mobileWidth" style="width: 100vw !important">
                    <div class="text-center text-capitalize" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h5' : 'kindmind-title-font-h4'">{{ sectionstyle }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-h7-bold text-center mt-3 mx-4' : 'kindmind-font-h5-5-bold text-center mx-3 mt-2'">{{ question.questionName }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center mt-3 mx-2' : 'kindmind-font-h7 text-center mx-3 mt-2'">{{ multiple ? "Select as many as you like" : "Please choose one answer to continue" }}</div>
                    <div class="mobileWidth d-flex flex-row flex-wrap align-start justify-space-between overflow-y-auto" style="width: 100% !important; height: 100% !important">
                        <v-btn-toggle v-model="myanswers" :multiple="multiple" active-class="animate__animated animate__pulse animate__repeat-3 black--text" :class="sectionstyle" class="d-flex align-center flex-column">
                            <v-btn @click="updateAnswers(answerIndex)" v-for="(answer, answerIndex) in JSON.parse(question.questionAnswers)" :key="answerIndex" :value="answer.text" class="mobileView white rounded-km text-capitalize mt-4 d-flex flex-row align-center justify-start" style="height: 12vh !important; width: 90% !important"
                                ><div class="d-flex flex-row align-center mx-4">
                                    <v-icon class="mr-2 kindmind-font-h4" :class="`${sectionstyle}--text`">{{ answer.icon }}</v-icon
                                    ><span :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h7' : 'kindmind-font-h6'">{{ answer.text }}</span>
                                </div>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>
                <!-- Ranking Questions - Mobile -->
                <v-col v-else-if="$vuetify.breakpoint.width < 1000 && rankorder" cols="12" xs="12" sm="12" class="mt-0 pt-0 d-flex flex-column align-center justify-center">
                    <div class="text-center text-capitalize" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h5' : 'kindmind-title-font-h4'">{{ sectionstyle }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-h7-bold text-center mt-3 white--text mx-4' : 'white--text kindmind-font-h5-5-bold text-center mx-4 mt-2'">{{ question.questionName }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center mt-3 mx-2' : 'kindmind-font-h7 text-center mx-3 mt-2'">Please drag and drop items.</div>
                    <drop-list v-if="$vuetify.breakpoint.width < 1000 && rankorder" :items="parseAnswers" @reorder="$event.apply(parseAnswers)" @insert="reOrderAnswer">
                        <template v-slot:item="{ item }">
                            <drag :key="item.text">
                                <v-col cols="12" xs="12" md="6" class="">
                                    <v-btn :value="item.text" block class="mobileView white rounded-km text-capitalize d-flex flex-row align-center justify-center" style="height: 12vh !important; width: 100%">
                                        <v-icon class="mr-2 kindmind-font-h4" :class="`${sectionstyle}--text`" large>icons8-drag-reorder</v-icon>
                                        <span :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h7' : 'kindmind-font-h6'">{{ item.text }}</span>
                                        <v-icon class="ml-2 kindmind-font-h6" :class="`${sectionstyle}--text`">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </v-col>
                            </drag>
                        </template>
                        <template v-slot:feedback="{ data }">
                            <div :key="data">{{ data }}</div>
                        </template>
                    </drop-list>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>

                <!-- DESKTOP VIEW BUTTON -->

                <!-- Ranking Questions -->
                <v-col v-else-if="$vuetify.breakpoint.width >= 1000 && rankorder" cols="12" xs="12" sm="12" class="mt-0 pt-0 d-flex flex-column align-center justify-center">
                    <div class="text-center text-capitalize" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h5' : 'kindmind-title-font-h4'">{{ sectionstyle }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1-bold text-center mt-3' : 'kindmind-font-h5-5-bold text-center mx-3 mt-2'">{{ question.questionName }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center mt-3 mx-2' : 'kindmind-font-h7 text-center mx-3 mt-2'">Please drag and drop items.</div>
                    <drop-list v-if="$vuetify.breakpoint.width > 1000 && rankorder" :items="parseAnswers" @reorder="handleReorder" @insert="reOrderAnswer">
                        <template v-slot:item="{ item }">
                            <drag :key="item.text">
                                <v-col cols="12" xs="12" md="6">
                                    <v-btn :value="item.text" class="mobileView white rounded-km text-capitalize d-flex flex-row align-center justify-start" style="height: 12vh !important">
                                        <v-icon class="mr-3 kindmind-font-icons" :class="`${sectionstyle}--text`" large>icons8-drag-reorder</v-icon>
                                        <span class="kindmind-font-h6">{{ item.text }}</span>
                                        <v-icon class="ml-2 kindmind-font-h4" :class="`${sectionstyle}--text`">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </v-col>
                            </drag>
                        </template>
                        <template v-slot:feedback="{ data }">
                            <div :key="data">{{ data }}</div>
                        </template>
                    </drop-list>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>

                <!-- single and multiple questions -->
                <v-col v-else cols="12" xs="12" sm="12" lg="6" class="d-flex flex-column align-center justify-center" style="width: 100vw !important" no-gutters>
                    <div class="kindmind-title-font-h4 text-center text-capitalize">{{ sectionstyle }}</div>
                    <div class="kindmind-font-h5 text-center mx-3 mt-2">{{ question.questionName }}</div>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center mt-3 mx-2' : 'kindmind-font-h7 text-center mx-3 mt-2'">{{ multiple ? "Select as many as you like" : "Please choose one answer to continue" }}</div>
                    <v-btn-toggle v-model="myanswers" class="d-flex flex-column flex-wrap justify-start" :multiple="multiple" active-class="animate__animated animate__pulse animate__repeat-3 black--text" :class="sectionstyle">
                        <v-btn @click="updateAnswers(answerIndex)" v-for="(answer, answerIndex) in JSON.parse(question.questionAnswers)" :key="answerIndex" :value="answer.text" class="white rounded-km text-capitalize mt-4 d-flex justify-center mx-2" style="width: 36vw !important; height: 12vh !important"
                            ><div class="d-flex flex-row align-center mx-4">
                                <v-icon :class="`${sectionstyle}--text mr-3 kindmind-font-icons`">{{ answer.icon }}</v-icon
                                ><span class="kindmind-font-h6">{{ answer.text }}</span>
                            </div>
                        </v-btn>
                    </v-btn-toggle>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>
            </v-row>
        </div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'footerbanner' : 'none'" :id="sectionstyle"></div>
    </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";

export default {
    name: "section-questions",
    questionId: "",
    components: {
        Drag,
        DropList,
    },
    props: {
        sectionstyle: { type: String, default: "" },
        datadown: {
            type: Object,
            default: () => {},
        },
        activeQuestionCount: {
            type: Number,
        },
    },
    data: () => ({
        question: {},
        // Type of question
        multiple: false,
        rankorder: false,

        // Answers
        useranswers: [],
        myanswers: [],
        parseAnswers: [],
        useranswer: {},
        useranswerDefault: { questionId: "", questionSection: "", questionInsights: "", preferenceField: "", questionAnswers: [], questionCore: "", createdUserId: "", createdOrgId: "", questionAnswersValue: null, createdDate: 1665052505451, modifiedUserId: "", modifiedOrgId: "", modifiedDate: 1665052505451, deletedUserId: "", deletedOrgId: "", deletedDate: 1665052505451, deleted: false },
    }),
    methods: {
        //initialise passes question the information from datadown
        initialise() {
            this.question = { ...this.datadown };

            this.useranswer = { ...this.useranswerDefault };
            this.myanswers = [];

            // Checks if it's a multiple answer question
            if (this.question.questionAnswerType == "Multiple Choice") {
                this.multiple = true;
                this.useranswer = { ...this.useranswerDefault };
                this.myanswers = [];
            } else if (this.question.questionAnswerType == "Rank Order") {
                this.computeAnswers();
                this.rankorder = true;
                this.useranswer = { ...this.useranswerDefault };
                this.myanswers = [];
            }
        },
        // Links the chosen answer to the matching question
        updateAnswers(answerIndex) {
            this.$set(this.useranswers, this.activeQuestionCount, answerIndex);
        },
        // Parses the answers to be used in the drag and drop
        computeAnswers() {
            if (this.question.questionAnswerType == "Rank Order") {
                this.parseAnswers = JSON.parse(this.question.questionAnswers);
            }
        },
        // Maked the drag and drop function
        reOrderAnswer(event) {
            this.parseAnswers.splice(event.index, 0, event.data);
        },
    },

    watch: {
        // datadown is watching changes for new questions coming from the parent
        datadown: {
            handler() {
                this.initialise();
            },
            deep: true,
        },
        // question is watching changes for the answers coming from the child
        question: {
            handler() {
                this.$emit("update", this.question);
            },
            deep: true,
        },
        // useranswer is watching changes for the answers coming from the child
        myanswers: {
            handler() {
                // check if myanswers is reactive
                
                if (this.myanswers !== "" || this.myanswers !== null || this.myanswers !== undefined) {
                    const answers = JSON.parse(JSON.stringify(this.myanswers));
                    this.useranswer.questionId = this.question.entityId;
                    this.useranswer.questionSection = this.question.questionSection;
                    this.useranswer.preferenceField = this.question.preferenceField;
                    this.useranswer.questionCore = "";
                    if (this.multiple == true) {
                        this.useranswer.questionAnswers = answers;
                    } else {
                        this.useranswer.questionAnswers.push(answers);
                    }
                    this.$emit("updateanswer", { ...this.useranswer });
                }
            },
            deep: true,
        },
        handleReorder(newOrder) {
            this.parseAnswers.apply(null, [newOrder]);
        },
        parseAnswers: {
            handler() {
                this.useranswer.questionId = this.question.entityId;
                this.useranswer.questionSection = this.question.questionSection;
                this.useranswer.preferenceField = this.question.preferenceField;
                // if (this.rankorder == true) {
                let answer = [];

                for (let i = 0; i < this.parseAnswers.length; i++) {
                    answer.push(this.parseAnswers[i].text);
                }
                this.useranswer.questionAnswers = answer;
                // }

                this.$emit("updateanswer", { ...this.useranswer });
            },
            deep: true,
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.v-avatar {
    top: -6vh !important;
}

.rounded-km-top {
    border-radius: 30px 30px 0px 0px !important;
}

.v-col {
    display: flex !important;
    align-content: center !important;
    justify-content: center !important;
}

.allowscrolling {
    overflow-y: auto;
    overflow-x: hidden;
}

.v-btn.mobileView {
    min-width: 64vw !important;
    max-height: 10vh !important;
}

.fullviewpoint {
    height: 100vh !important;
    width: 100vw !important;
}

.mobileWidth {
    width: 100vw !important;
    display: flex !important;
    align-content: center !important;
    justify-content: center !important;
}

.footerbanner {
    z-index: 0 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    height: 18vh !important;
}

/* Different gradient colours */
#pause {
    background: -moz-linear-gradient(top, rgb(119, 196, 180) 30%, rgba(119, 196, 180, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(119, 196, 180) 30%, rgba(119, 196, 180, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(119, 196, 180) 30%, rgba(119, 196, 180, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#move {
    background: -moz-linear-gradient(top, rgb(247, 170, 106) 30%, rgba(247, 170, 106, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(247, 170, 106) 30%, rgba(247, 170, 106, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(247, 170, 106) 30%, rgba(247, 170, 106, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#fuel {
    background: -moz-linear-gradient(top, rgb(161, 174, 218) 30%, rgba(161, 174, 218, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(161, 174, 218) 30%, rgba(161, 174, 218, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(161, 174, 218) 30%, rgba(161, 174, 218, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#grow {
    background: -moz-linear-gradient(top, rgb(231, 167, 178) 30%, rgba(231, 167, 178, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(231, 167, 178) 30%, rgba(231, 167, 178, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(231, 167, 178) 30%, rgba(231, 167, 178, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
