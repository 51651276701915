<template>
    <!-- Header -->
    <div class="fullviewpoint allowscrolling">
        <!-- Loading circle - renders when loading is true -->
        <v-card style="z-index: 99999 !important" class="white--text transparent-card pt-5 kindmind-font-h4 text-center" flat>Hello {{ firstName }} </v-card>
        <v-card style="z-index: 99999 !important" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6'" class="px-2 transparent-card white--text text-center">As this is your first time here, we need to work out what type of activities you enjoy the most.</v-card>
        <!-- <br /> -->
        <v-card style="z-index: 99999 !important" :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-b1 ' :$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pb-6' : 'kindmind-font-h6 pb-6'" class="px-2 transparent-card white--text text-center">
            To get started, click on the <span :class="`text-capitalize ${activeButton}--text`">{{ activeButton }}</span> button
        </v-card>

        <!-- Section Buttons -->
        <div class="questionsGrid">
            <!-- <div :style="activeButton !== item.requirementName ? 'opacity: 0.8' : 'z-index:99999 !important;'" v-for="(item, index) in requirementQuestions" :key="index">
                <v-btn @click="clicked(activeButton, item.requirementName)" depressed :dense="smalldevice" :height="smalldevice ? 60 : 90" class="white--text rounded-km text-capitalize font-weight-light kindmind-font-h6-bold" block :class="item.requirementColor">
                    <div><v-icon large class="mr-2 white--text">icons8-xbox-cross</v-icon></div>
                    <div>{{ item.requirementName }}</div>
                </v-btn>
            </div> -->
            <div v-for="(item, index) in requirementQuestions" :key="index" :style="activeButton !== item.requirementName ? 'opacity: 0.8' : 'z-index:99999 !important;'" >
                <!-- Render the button if it's the active button -->
                <v-btn v-if="activeButton === item.requirementName" @click="clicked(activeButton, item.requirementName)" depressed :dense="smalldevice" :height="smalldevice ? 60 : 90" class="white--text rounded-km text-capitalize font-weight-light kindmind-font-h6-bold" block :class="item.requirementColor">
                    <v-icon large class="mr-2 white--text">icons8-xbox-cross</v-icon>
                    <div>{{ item.requirementName }}</div>
                </v-btn>

                <!-- Render the image from the asset folder if the button is not active -->
                <img v-else class="inactive-image" :src="requirementImage(item.requirementName)" alt="Inactive Image" :dense="smalldevice" :height="smalldevice ? 60 : 90" />
            </div>
        </div>

        <!-- BOTTOM MARGIN -->
        <div :style="$vuetify.breakpoint.width < 400 ? 'padding-bottom: 160px !important; opacity: 0 !important;' : 'padding-bottom: 160px !important;'"></div>

        <!-- NAV BAR -->
        <div>
            <v-bottom-navigation app flat style="padding-left: 16px;padding-right: 16px;" :active-class="''" elevation="0" class="bottomNavigation mt-0 py-0 mb-3" grow :style="$vuetify.breakpoint.width < 400 ? 'height: 76px !important;' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'height: 86px !important;' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'height: 86px !important;' : $vuetify.breakpoint.width > 999 && $vuetify.breakpoint.width < 1300 ? 'height: 86px !important;' : 'height: 86px !important;'">
                <div :class="homeCover" class="d-flex justify-center align-center primary white--text rounded-km" style="width: 100%; height: 100%">
                    <v-btn v-for="(item, itemRouteIndex) in links" :key="itemRouteIndex" class="mx-0 px-0 rounded-km" active-class="primary rounded-km" depressed :ripple="false">
                        <span :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-b1' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h7' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h7' : 'kindmind-font-h7'" class="white--text pt-2">{{ item.text }}</span>
                        <span v-if="item.text == 'Me' && unreadmessagestotal > 0">
                            <v-badge bordered color="error" overlap dot>
                                <v-icon :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h5-5' : 'kindmind-font-h5-5'" class="white--text">{{ item.icon }}</v-icon></v-badge
                            ></span
                        >
                        <span v-else>
                            <v-icon :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h4' : 'kindmind-font-h4'" class="white--text">{{ item.icon }}</v-icon>
                        </span>
                    </v-btn>
                </div>
            </v-bottom-navigation>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "requirement-questions",
    props: {
        activeButton: {
            type: String,
            default: "",
        },
    },
    // * DATA
    data: () => ({
        toggle: false,
        activeSection: "",
        counter: 0,
        requirementQuestions: [
            { requirementName: "pause", requirementColor: "pause", path: "/questions/pause" },
            { requirementName: "move", requirementColor: "move", path: "/questions/move" },
            { requirementName: "fuel", requirementColor: "fuel", path: "/questions/fuel" },
            { requirementName: "grow", requirementColor: "grow", path: "/questions/grow" },
        ],
        userName: "",
        firstName: "",
        loading: "",

        // bottom nav
        links: [
            { icon: "icons8-calendar", text: "Today", route: "/dashboard" },
            { icon: "icons8-compass-west", text: "Explore", route: "/explore" },
            { icon: "icons8-communication", text: "Social", route: "/social" },
            { icon: "icons8-user", text: "Me", route: "/profile" },
        ],
    }),

    // * METHODS
    methods: {
        // Sets padding depending on viewport width
        setPadding() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 382:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 381 && this.$vuetify.breakpoint.width < 400:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 399 && this.$vuetify.breakpoint.width < 700:
                    return "px-6";
                case this.$vuetify.breakpoint.width > 700 && this.$vuetify.breakpoint.width < 1000:
                    return "px-16";
                case this.$vuetify.breakpoint.width > 1000:
                    return "px-9";
                default:
                    return "";
            }
        },
        // Function to get the image for the requirement
        requirementImage(item) {
            // Return the URL of the image based on the requirementName
            return require(`@/assets/home-background/${item}-tile.png`);
        },
        //
        clicked(activeButton, section) {
            if (activeButton == section) {
                return this.$router.push(`/questions/${activeButton}`);
            } else {
                return;
            }
        },
        // Retrives userName and cuts it so it only returns the first name
        async firstNamefunc() {
            let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.userName = currUserResult.data.userName;
            let indexOfSpace = this.userName.indexOf(" ");
            if (indexOfSpace === -1) {
                return (this.firstName = this.userName);
            } else {
                return (this.firstName = this.userName.substring(0, indexOfSpace));
            }
        },
    },
    // * COMPUTED
    computed: {
        smalldevice() {
            return window.innerHeight < 600;
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    created() {
        this.loading = true;
        this.firstNamefunc();
        this.loading = false;
    },
};
</script>

<style scoped>
.transparent-card {
    background-color: rgba(0, 0, 0, 0); /* rgba with alpha (0.5) for transparency */
}

.inactive-image {
    max-width: 100%;
}

.questionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 4px;
    margin: 16px;
    z-index: 99999 !important;
}
</style>
