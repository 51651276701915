<template>
    <div class="primary allowscrolling overflow-y-auto overflow-x-hidden" style="height: 100% !important">
        <!--Log In-->
        <v-row class="d-flex align-center justify-center mt-6">
            <v-col cols="12" xs="12" sm="8" md="8" class="d-flex align-center justify-center">
                <v-card class="pa-5 mx-5 elevation-0 rounded-xl white px-0">
                    <div class="primary--text text-center">
                        <div :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4-bold'">Login</div>
                        <div class="grey--text" :class="smalldevice ? 'font-weight-thin kindmind-font-h6' : 'kindmind-font-h6'">Welcome to Kind Mind</div>
                    </div>

                    <!--Form-->
                    <div class="my-5">
                        <v-form ref="loginForm" v-on:submit.prevent="login()" v-model="valid" lazy-validation>
                            <v-row class="d-flex align-center justify-center mx-2">
                                <v-col cols="12" xs="12" md="8" class="py-0">
                                    <v-text-field v-model="userEmail" solo text background-color="grey lighten-3" :rules="rules.emailRules" hide-details="auto" placeholder="Email..." class="rounded-km"> </v-text-field>
                                </v-col>

                                <v-col cols="12" xs="12" md="8">
                                    <v-text-field :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="rules.passwordRules" autocomplete="off" required v-on:keyup.enter="login" v-model="userPassword" solo text background-color="grey lighten-3" hide-details="auto" placeholder="Password..." :type="show ? 'text' : 'password'" @click:append="show = !show" class="rounded-km"> </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="8" class="text-center">
                                    <div style="cursor: pointer" class="kindmind-font-b1-bold primary--text" @click="$router.push('/resetpassword')">Forgot your password?</div>
                                </v-col>
                                <v-col cols="12" xs="12" md="8">
                                    <v-btn depressed :height="smalldevice ? 35 : 55" @click="login" :loading="loading" text class="kindmind-font-b1 rounded-km secondary white--text text-capitalize" style="color: white !important" block>Login</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <!-- <div class="grey--text kindmind-font-b1 d-flex justify-center">or login with</div>
                    <div class="social_media d-flex justify-center my-3">
                        <v-btn class="mx-1 blue elevation-0 rounded-km"><v-icon class="white--text">icons8-facebook-f</v-icon></v-btn>
                        <v-btn outlined depressed class="mx-1 primary elevation-0 rounded-km"><v-icon class="white--text">icons8-google</v-icon></v-btn>
                        <v-btn class="mx-1 black elevation-0 rounded-km"><v-icon class="white--text">icons8-apple-logo</v-icon></v-btn>
                    </div> -->
                    <div :class="$vuetify.breakpoint.xsOnly ? ' kindmind-font-h7-thin' : 'kindmind-font-h6'" class="grey--text lighten-4 text-center mt-6" @click="sendComponentRequest('register')">Don't have an account <a href="">Sign up</a></div>
                </v-card>
            </v-col>
        </v-row>
        <footer-component />
        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "login-component",
    data: () => ({
        // emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+/.test(v) || "Email must be valid"],
        inputBackgroundColor: "white",
        loading: false,
        // passwordRules: [(v) => !!v || "Password is required"],
        valid: true,
        show: false,
        rules: [],
        userEmail: "",
        userPassword: "",
        userData: {},
        alertData: { title: "", message: "", color: "", timeout: "2000", show: false },
    }),
            // * BEFORE MOUNT
    //    beforeMount() {
    //     let t = this;
    //     let brandingData = t.GET_setBranding;
    //     // console.log("brandingData", brandingData);
    //     if (brandingData.brandingName != 'KindMind' && brandingData.brandingName != undefined) {
    //         t.MIX_brandingTheme(brandingData);
    //         t.pageLogo = t.MIX_brandingImg(brandingData);
    //     }
    // },
    //* METHODS
    methods: {
        ...mapActions({ 
            ACT_FIREBASE_userAuth: "firebase_auth_store/ACT_FIREBASE_userAuth" ,
            ACT_currentUser: "ACT_currentUser"
        }),
        async checkuserAuth(userID) {
            let t = this;
            let userDataResult = await t.MIX_firestore_read(userID, "users");
            if (userDataResult.code === 1) {
                return userDataResult;
            } else {
                t.alertData = { title: "Account not found", message: "Account not found", color: "error", timeout: 2000, show: true };
                // t.MIX_alertBox({ color: "error", timeout: 2000, message: "Account not found", show: true });
                return userDataResult;
            }
        },
        // * Gets currentuser data once user has logged in
        async userInfo(userID) {
            let currUserResult = await this.MIX_firestore_read(userID, "users");
            this.userData = currUserResult.data;
        },
        login() {
            let t = this;
            t.loading = true;
            t.userEmail = t.userEmail.toLowerCase().trim();
            t.rules = {
                emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
                passwordRules: [(v) => !!v || "Password is required"],
            };
            if (t.userEmail === "" || t.userPassword === "") {
                t.loading = false;
                t.alertData = { title: "Error", message: "Please fill in all fields", color: "error", timeout: 2000, show: true };
                return;
            }
            t.$nextTick(() => {
                if (t.$refs.loginForm.validate()) {
                    t.$firebase.auth
                        .signInWithEmailAndPassword(t.userEmail, t.userPassword.trim())
                        .then(async (data) => {
                            
                            // checks that the email has been verified
                            if (data.user.multiFactor.user.emailVerified || t.userEmail === "vindico@admin.com") {
                                var checkUserResult = await t.checkuserAuth(data.user.uid);
                                if (checkUserResult.code === 1 && checkUserResult.data.userStatus === "Approved") {
                                    await t.MIX_FIREBASE_userAuth(data.user);
                                    let userdata = await this.MIX_firestore_read(data.user.uid, "users");
                                    t.ACT_currentUser(userdata);
                                    await t.userInfo(data.user.uid);
                                } else if (checkUserResult.code === 1 && checkUserResult.data.userStatus != "Approved") {
                                    t.$router.push("/holding").catch((error) => {
                                        console.error(error.message);
                                    });
                                } else if (checkUserResult.code === 0) {
                                    t.alertData = { title: "Account not found", message: "Account not found", color: "error", timeout: 2000, show: true };
                                }
                            } else {
                                throw new Error("Email not verified"); // stop the method from running
                            }
                        })
                        .then(async (data) => {
                            if (this.userData.onboarding === true) {
                                t.$router.push("/dashboard").catch((error) => {
                                    console.error(error.message);
                                });
                            } else {
                                t.$router.push("/questions/onBoarding").catch((error) => {
                                    console.error(error.message);
                                });
                            }
                        })
                        .catch((err) => {
                            if (err.message === "Email not verified") {
                                t.loading = false;
                                t.alertData = { title: "Email not verified", message: "Please verify your email", color: "error", timeout: 2000, show: true };
                                return;
                            }
                            t.alertData = { title: "Wrong password", message: "The email password combination is incorrect", color: "error", timeout: 2000, show: true };
                            t.loading = false;
                        });
                } else {
                    t.alertData = { title: "Wrong password", message: "The email is incorrect", color: "error", timeout: 2000, show: true };
                    t.loading = false;
                }
            });
        },
        sendComponentRequest(event) {
            if (event == "register") {
                this.$emit("componentRequest", "register");
            } else {
                this.$emit("componentRequest", "reset");
            }
        },
    },
    // * COMPUTED
    computed: {
        // Checks the size of viewport
        smalldevice() {
            return window.innerWidth < 680;
        },
    },
    // Gets user information from Firebase
    ...mapGetters({
        GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        GET_setBranding: "GET_setBranding",
    }),
    // * CREATED
    async created() {},
};
</script>
