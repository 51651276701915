/* eslint-disable */
import { doc, setDoc, getDoc, getDocs, deleteDoc, collection, query, where, orderBy, limit, startAt } from "firebase/firestore";
import { mapActions, mapGetters } from "vuex";

// * GLOBAL MIXIN
const mixin = {
	data: () => ({
		isUploading: false,
        uploadValue: 0,
	}),
	methods: {
		...mapGetters({
			GET_showUploadPicture: "GET_showUploadPicture",
		}),
		...mapActions({
			ACT_showUploadPicture: "ACT_showUploadPicture",
		}),
		
		/**
		 * This method updates specific fields in a collection according to the collection id
		 * @param {*} collectionName Collection in DB
		 * @param {*} id Collection ID
		 * @param {*} fields Fields that exist in the collection that will be updated 
		 * @returns promise
		 */
		MIX_updateDocumentFieldsById: function (collectionName, id, fields) {
            const t = this
            return new Promise(function(resolve) {
                fields = JSON.parse(JSON.stringify(fields))
                fields.modifiedUserData = t.GET_currentUserData;
                fields.modifiedDateTime = t.$moment().format('x'); // add created timestamp to document
                if (collectionName !== null) { //check if collection is empty or if exists
                    if (id === undefined) {
                        resolve ({ code: 1, message: 'Warning Fields Could not be Updated in Document', data: { id: id }, error: null })
                    } else {
                        t.$firebase.db.collection(collectionName).doc(id).update(fields)
                            .then(() => resolve ({ code: 1, message: 'Fields Updated in Document', data: { id: document.id }, error: null }))
                            .catch((error) => resolve ({ code: -1, message: 'Error Writing Fields to Document', data: null, error: error }));
                    }
                } else {
                    resolve ({ code: 0, message: 'Warning Collection was not specified', data: null, error: null });
                }
            })
        },


		/**
		 * Updates a Firestore Picture can be also use to update one specific field
		 * @param {*} documentId Document ID in Collection
		 * @param {*} collectionName Collection in DB
		 * @param {*} fieldToUpdate The field that is going to be Updated
		 * @param {*} value The value that the existing field will be upadted to
		 */
		async MIX_firestore_updateData(documentId, collectionName, fieldToUpdate, value) {
			let t=this;
			this.$firebase.db.collection(collectionName).doc(documentId).update({
				[fieldToUpdate]: value
			  }).then(function() {			
			  });
		},
		/**
		  * GOOGLE CLOUD STORAGE INTEGRATION WITH FIREBASE 
		  * @param {*} userId 
		  * @param {*} folder filepath/storage path in firebase storage
		  * @param {*} fileData 
		  * @param {*} docLink 
		  * @returns promise
		  */
			MIX_uploadFile: function (userId, folder, fileData, docLink) {
				var t = this;
				return new Promise(function(resolve) {
					//GCS storage reference
					if (folder !== undefined){
						var location = folder + `/${fileData.name}`;
					}else {
						var location = fileData.name;
					}
					const storageRef = t.$firebase.storage.ref().child(location).put(fileData);
					storageRef.on(`state_changed`,
						(snapshot) => {
							t.uploadValue =(snapshot.bytesTransferred / snapshot.totalBytes) * 100; //upload progress
							t.isUploading = true;
						},
						(error) => resolve ({ code: -1, message: 'Error Uploading File', data: null, error: error }),
						() => {
							t.uploadValue = 100; // upload value after loading file
							t.isUploading = false;
							storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
								// t.fileURL = url; // asign GCS url to fileURL // if needed
								// Create the file metadata
								var storeFile = {
									fileName: fileData.name,
									fileLocation: location,
									fileSize: fileData.size,
									fileType: fileData.type,
									fileURL: url,
									docLink: docLink
								}
								//Create the file in storage and DB and update the field
								await t.MIX_firestore_updatePicture(userId, 'users', 'userProfile', storeFile.fileURL);
							});
						}
					);
				})
			},
		/**
		 * Updates multiple fields of a document with a targeted ID
		 * @param {*} documentId Document ID
		 * @param {*} collectionName Collection IN ID
		 * @param {*} fields Fields param needs to be an object
		 * @returns promise
		 */
		async MIX_firestore_updateMultipleFields(documentId, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, documentId);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Updated Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Updating Firestore Document", data: null, error: error };
			}
		},

		/**
		 * Retrive all the data from a collection
		 * @param {*} collectionName 
		 * @returns an object contain all the data
		 */
		 async MIX_firestore_allData(collectionName){
			const querySnapshot = await getDocs(collection(this.$firebase.db, collectionName));
			let returnData =[];
			querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			returnData.push(doc.data())
			});
			return returnData
		},

		/**
		 * Read a Firestore Document according to the document id
		 * @param {*} documentId 
		 * @param {*} collectionName Collection in DB
		 * @returns 
		 */
		 async MIX_firestore_read(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				const docRef = doc(this.$firebase.db, collectionName, documentId);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					return { code: 1, mesage: "Firestore Document Read Successfully", data: docSnap.data(), error: null };
				} else {
					return { code: 0, mesage: "Firestore Document does not exist", data: null, error: null };
				}
			} catch (error) {
				return { code: -1, mesage: "Error Occured Reading Firestore Document", data: null, error: error };
			}
		},
		// Create a new Firestore Document
		async MIX_firestore_create(document, collectionName) {
			try {
				// Add a new document in collectionName requires id to be passed
				return await setDoc(doc(this.$firebase.db, collectionName, document.id), document).then(() => {
					return { code: 1, mesage: "Firestore Document Created Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Creating Firestore Document", data: null, error: error };
			}
		},
		// Read a Firestore Document
		async MIX_firestore_read(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				const docRef = doc(this.$firebase.db, collectionName, documentId);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					return { code: 1, mesage: "Firestore Document Read Successfully", data: docSnap.data(), error: null };
				} else {
					return { code: 0, mesage: "Firestore Document does not exist", data: null, error: null };
				}
			} catch (error) {
				return { code: -1, mesage: "Error Occured Reading Firestore Document", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readMany(collectionName, showDeleted, orderField, orderDirection, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				// // Loop through and add Where Clauses to Conditions
				// for (var i = 0; i < whereConditions.length; i++) {
				// 	conditions.push(where(whereConditions[i].field, whereConditions[i].operator, whereConditions[i].value));
				// }
				if (orderField !== null && orderField !== undefined) {
					if (orderDirection === 'desc') { 
						conditions.push(orderBy(orderField, orderDirection))
					} else {
						conditions.push(orderBy(orderField))
					}
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.error(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readManyWhere(collectionName, showDeleted, whereConditions, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName where conditions are defined
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				// // Loop through and add Where Clauses to Conditions
				for (var i = 0; i < whereConditions.length; i++) {
					conditions.push(where(whereConditions[i].field, whereConditions[i].operator, whereConditions[i].value));
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.error(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},		

		/**
		 * Get multiple documents form a collection
		 * @param {*} collectionName Collection in DB
		 * @param {*} field The targeted field in the condition
		 * @param {*} condition == <= >= includes etc...
		 * @param {*} target the targeted value of the field
		 * @returns an array with the documents that satisfy the condition
		 */
		async MIX_firestore_readManyWhereCondition(collectionName, field, condition, target){
			const q = query(collection(this.$firebase.db, collectionName), where( field, condition, target));
			const querySnapshot = await getDocs(q);
			let retriveDocuments = [];
			querySnapshot.forEach((doc) => {
				retriveDocuments.push(doc.data());
			});
			return retriveDocuments;
		},
				/**
		 * Get all documents form a collection
		 * @param {*} collectionName Collection in DB
		 * @returns an array with all the documents in that collection
		 */
				async MIX_firestore_readAll(collectionName){
					const q = collection(this.$firebase.db, collectionName);
					const querySnapshot = await getDocs(q);
					let retriveDocuments = [];
					querySnapshot.forEach((doc) => {
						retriveDocuments.push(doc.data());
					});
					return retriveDocuments;
				},
		// Update a Firestore Document
		async MIX_firestore_update(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Updated Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Updating Firestore Document", data: null, error: error };
			}
		},
		// Delete a Firestore Document (Mark as Delete)
		async MIX_firestore_delete(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document.id);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Deleted Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Deleting/Updating Firestore Document", data: null, error: error };
			}
		},
		// Destroy a Firestore Document (Remove from collectionName)
		async MIX_firestore_destroy(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				return await deleteDoc(doc(this.$firebase.db, collectionName, documentId)).then(() => {
					return { code: 1, mesage: "Firestore Document Destroyed Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Destroying Firestore Document", data: null, error: error };
			}
		},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
