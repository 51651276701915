<template>
    <div class="flat-html5-player-wrapper">
        <div class="flat-player" id="player">
            <div class="flat-video-preview-wrapper pointer">
                <video :src="videourl" ref="videoElem" class="flat-video pointer" controlsList="nodownload" preload="metadata"></video>
            </div>
            <div class="flat-video-footer" ref="videoControls">
                <div class="flat-video-progressbar pointer" @click="skipVideo">
                    <div :style="{ width: progressPercentage() + '%' }" :class="`flat-video-current-progress pointer bg-${colour}`"></div>
                </div>

                <div class="flat-video-controls">
                    <div class="flat-video-controls-left">
                        <div class="">
                            <v-btn v-if="isPaused()" @click="play" class="flat-video-control-single" small text>
                                <v-icon class="" size="12">icons8-play-button</v-icon>
                            </v-btn>
                            <v-btn v-if="!isPaused()" @click="pause" class="flat-video-control-single" small text>
                                <v-icon class="" size="12">icons8-pause</v-icon>
                            </v-btn>
                        </div>
                        <div class="flat-video-volume-rocker">
                            <v-btn @click="muteToggle" v-if="isMuted()" class="flat-video-control-single" small text>
                                <v-icon class="" size="12">icons8-volume-up</v-icon>
                            </v-btn>
                            <v-btn @click="muteToggle" v-if="!isMuted()" class="flat-video-control-single" small text>
                                <v-icon class="" size="12">icons8-mute-2</v-icon>
                            </v-btn>
                            <div class="flat-video-volume-progress pointer" @click="changeVolume">
                                <div :style="{ width: volume() + '%' }" :class="`flat-video-volume-progress-current pointer bg-${colour}`"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flat-video-controls-right">
                        <div class="flat-video-time-container">
                            <span class="flat-video-current-time kindmind-font-cap-s">{{ formatTime(currentTime()) }}</span
                            ><span>/</span><span class="flat-video-total-time kindmind-font-cap-s">{{ formatTime(duration()) }}</span>
                        </div>
                        <div class="flat-video-buttons-right-wrapper">
                            <v-btn  @click="toggleFullScreen" class="flat-video-control-single" small text>
                                <v-icon class="" size="12">{{ isFullScreen ? "icons8-collapse" : "icons8-expand" }}</v-icon>
                            </v-btn>
                            <!-- <v-btn v-if="!isFullScreen" @click="toggleFullScreen" class="flat-video-control-single">
                                <v-icon class="" size="12">icons8-expand</v-icon>
                            </v-btn> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import screenfull from "screenfull";

export default {
    name: "video-component",
    props: {
        videourl: {
            type: String,
            required: true,
        },
        colour: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        isFullScreen: false,
    }),

    // * MOUNTED
    mounted() {
        let events = ["timeupdate", "volumechange", "seeked", "loadedmetadata"];

        // Hides default video controls
        const video = this.$refs.videoElem;
        const videoControls = this.$refs.videoControls;
        const videoWorks = !!document.createElement("video").canPlayType;
        if (videoWorks) {
            video.controls = false;
            videoControls.classList.remove("hidden");
        }

        events.map((e) => {
            this.$refs.videoElem.addEventListener(e, () => {
                this.$forceUpdate();
            });
        });

        this.$refs.videoElem.addEventListener("loadedmetadata", () => {
            this.$refs.videoElem.volume = 0.3;
            this.$forceUpdate();
        });

        this.$refs.videoElem.addEventListener("click", () => {
            if (this.isPaused()) {
                this.play();
            } else {
                this.pause();
            }
        });

        this.$refs.videoElem.addEventListener("dblclick", () => {
            this.toggleFullScreen();
        });
    },
    // * METHODS
    methods: {
        muteToggle() {
            this.$refs.videoElem.muted = !this.$refs.videoElem.muted;
        },

        isMuted() {
            return this.$refs.videoElem ? this.$refs.videoElem.muted : false;
        },

        isPaused() {
            return this.$refs.videoElem ? this.$refs.videoElem.paused : true;
        },

        play() {
            this.$refs.videoElem.play();
        },

        pause() {
            this.$refs.videoElem.pause();
        },

        currentTime() {
            return this.$refs.videoElem?.currentTime || 0;
        },

        duration() {
            return this.$refs.videoElem?.duration || 0;
        },

        progressPercentage() {
            return (this.currentTime() / this.duration()) * 100;
        },

        formatTime(time) {
            if (!time || !parseInt(time)) {
                return `00:00`;
            }

            let hours, minutes, seconds;
            (minutes = Math.floor((time / 60) % 60)), (seconds = Math.floor(time % 60)), (hours = Math.floor(time / 60 / 60));

            if (minutes < 10) minutes = `0${minutes}`;
            if (seconds < 10) seconds = `0${seconds}`;

            return `${hours ? hours + ":" : ""}${minutes}:${seconds}`;
        },

        skipVideo(event) {
            let wrapper_offset = event.currentTarget.getBoundingClientRect().left;
            let clicked_offset = event.clientX - wrapper_offset;

            let progress_width = (clicked_offset / event.currentTarget.getBoundingClientRect().width) * 100;
            let newTime = (this.duration() / 100) * progress_width;

            this.$refs.videoElem.currentTime = newTime;
        },

        // toggleFullScreen() {
        //     console.log('toggleFullScreen')
        //     screenfull.toggle(this.$refs.videoElem);
        //     this.isFullScreen = !this.isFullScreen;
        //     this.$forceUpdate();
        // },

        toggleFullScreen() {
            // console.log("toggleFullScreen");
            // console.log("videoElem",this.$refs.videoElem);
            // console.log("document.fullscreenElement ",document.fullscreenElement );
            // console.log("document",document );
            // console.log("this.isFullScreen",this.isFullScreen );

            const elem = this.$refs.videoElem;
            this.isFullScreen = !this.isFullScreen;
            if (document.fullscreenElement === null) {
                if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE/Edge */
                    elem.msRequestFullscreen();
                } else {
                    elem.requestFullscreen();
                }
            } else {
                if (document.webkitExitFullscreen) {
                    /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE/Edge */
                    document.msExitFullscreen();
                } else {
                    document.exitFullscreen();
                }
            }
        },

        changeVolume(event) {
            // console.log("changeVolume")
            let wrapper_offset = event.currentTarget.getBoundingClientRect().left;
            let clicked_offset = event.clientX - wrapper_offset;
            let volume_bar_width = (clicked_offset / event.currentTarget.getBoundingClientRect().width) * 100;
            this.$refs.videoElem.volume = volume_bar_width / 100;
        },

        volume() {
            return this.$refs.videoElem ? this.$refs.videoElem.volume * 100 : 0;
        },
    },
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}

.flat-video-preview-wrapper {
    height: 100%;
    display: flex !important;
    align-content: bottom;
}

video.flat-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px !important;
}

.flat-video-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    color: black;
}

.flat-video-progressbar {
    width: 100%;
    height: 5px !important;
    background-color: #cfcfcf;
}

.flat-video-current-progress {
    height: 100%;
    width: 0%;
    /* background-color: #77C4B4; */
    /* transition: width 0.5s; */
}

.flat-video-controls {
    max-height: 22px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 20px; */
    flex: 1;
}

.flat-video-control-single {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
    padding: 5px 5px;
    cursor: pointer;
}

.flat-video-control-single:focus {
    outline: none;
}

.flat-video-controls-left,
.flat-video-controls-right {
    display: flex;
}

.flat-video-volume-rocker {
    display: flex;
    align-items: center;
}

.flat-video-volume-progress {
    width: 100px;
    height: 5px;
    border-radius: 5px;
    background-color: #cfcfcf;
}

.flat-video-volume-progress-current {
    width: 30%;
    height: 100%;
    border-radius: 5px;
    /* background-color: orangered; */
    /* background-color:var(--v-primary-base) */
}

.flat-video-time-container {
    line-height: 2;
    /* font-size: small; */
    margin-right: 20px;
    align-items: center;
    display: flex;
}

/* Background colours */
.bg-move {
    background-color: var(--v-move-base) !important;
}
.bg-pause {
    background-color: var(--v-pause-base) !important;
}
.bg-fuel {
    background-color: var(--v-fuel-base) !important;
}
.bg-grow {
    background-color: var(--v-grow-base) !important;
}
</style>
