<template>
    <div class="overflow-y-auto white allowscrolling fullviewpoint">
        <app-bar-component app :appbarcolour="'primary'" :setmargin="$vuetify.breakpoint.xsOnly ? 'mx-4' : 'mx-10'" />

        <!-- <v-sheet id="scrolling-techniques-2" class=" overflow-x-hidden" max-height="75vh"> -->
        <!-- Main User Component -->
        <div v-if="$vuetify.breakpoint.smAndUp">
            <br />
        </div>
        <component v-if="contentCreatorProfile" @returnToMessages="openMesaggesComponent" :performanceindex="performanceIndex" :currentuser="loggedUserData" :chat="computedArray" @refreshChat="computedMessages" :is="component" :loggeduser="loggedUserData" :selectedUser="selectedUser" :creatorprofile="creatorid" @handleClickOnMessage="openMesaggesComponent" @openChat="openChat" :unreadmessagestotal="unreadmessagestotal" @returnToProfile="loadprofile" :allmessages="allmessages"></component>

        <!-- Footer -->
        <bottom-navigation @loadprofile="loadprofile" :setpadding="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'" />
        <!-- </v-sheet> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserInformationComponent from "./UserInformationComponent.vue";
import { doc, onSnapshot } from "firebase/firestore";
// import { currentUser } from '@/firebase/firebase-config';

export default {
    components: { UserInformationComponent },
    name: "profile-screen",
    data: () => ({
        component: "user-information-component",
        overlay: false,
        loading: "",
        creatorProfile: "",
        creatorid: "",

        // User performance data
        climateScore: 0,
        copingScore: 0,
        cultureScore: 0,
        presenteeismScore: 0,
        performanceIndex: 0,
        loading: false,

        // User data
        loggedUserData: {},

        // Messages
        openMessage: false,
        allmessages: [],
        unreadmessagestotal: "",

        //Item for child component
        selectedUser: { id: "", userProfile: "", username: "" },
        currentUserData: [],
        computedArray: [],
    }),
    // * COMPUTED
    computed: {
        // Gets user data from firebase
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        contentCreatorProfile() {
            if (!this.creatorid) {
                this.component = "user-information-component";
                return true;
            } else {
                this.component = "content-creator-component";
                return true;
            }
        },
    },

    // * METHODS
    methods: {
        // Fetch unread messages
        async unreadMessages() {
            let t = this;
            // Fetches unread messages
            t.unreadmessagestotal = localStorage.getItem("unreadMessages");

            // Fetches all messages
            let searchQuery = `@messageRecipient:{${t.currentUserData.id}} @messageStatus:{Sent} @deleted:{false}`;
            let allMessagesQuery = await t.REDIS_searchFor("message", null, null, "createdDate", "DESC", searchQuery);
            t.allmessages = allMessagesQuery.data.documents;
        },
        //Reads all messages
        async readAllMessages() {
            // Fetches all messages
            let searchQuery = `@deleted:{false}`;
            let allMessagesQuery = await this.REDIS_searchFor("message", null, null, "createdDate", "DESC", searchQuery);
            this.allmessages = allMessagesQuery.data.documents;
            return true;
        },

        // Called by child component BottomNavigation - switches component back to current user profile
        loadprofile() {
            this.component = "user-information-component";
        },
        // Called by child component - switches component to messages
        async openMesaggesComponent() {
            let t = this;
            await t.readAllMessages();
            t.component = "messages-component";
        },
        openChat(message) {
            let t = this;
            // Sets the promps for the child component
            t.component = "messagesChat-component";
            t.currentUserData = t.loggedUserData;
            t.selectedUser = message.sender;
            t.computedMessages(t.selectedUser, t.currentUserData);
        },
        //  Reads allMessages and returns only the messages that have either sent or received by the current user
        async computedMessages(sender, recipient) {
            let t = this;
            // Gets all messages
            let searchQuery = `@messageStatus:{Sent} @deleted:{false}`;
            let allMessagesQuery = await t.REDIS_searchFor("message", null, null, null, null, searchQuery);
            t.allmessages = allMessagesQuery.data.documents;
            t.computedArray = [];

            if (sender.id) {
                t.allmessages.forEach((message) => {
                    let condition1 = message.createdUserId === recipient.id && message.messageRecipient === sender.id;
                    let condition2 = message.createdUserId === sender.id && message.messageRecipient === recipient.id;
                    if (condition1 || condition2) {
                        t.computedArray.push(message);
                    }
                });

                t.computedArray = t.computedArray.sort((a, b) => {
                    return a.createdDate > b.createdDate ? 1 : -1;
                });
            }
            return t.computedArray;
        },

        // Gets the Performance Index from the user that is logged in
        async getScores() {
            try {
                const response = await this.$axios.get(process.env.VUE_APP_API_HOST + "score?days=90&userId=" + this.loggedUserData.id);
// console.log("id", this.loggedUserData.id);
// console.log("response", response);
                if (response.status === 200) {
                    this.climateScore = Math.floor(response.data.data.climate);
                    this.copingScore = Math.floor(response.data.data.coping);
                    this.cultureScore = Math.floor(response.data.data.culture);
                    this.presenteeismScore = Math.floor(response.data.data?.presenteeism);
                    this.performanceIndex = Math.floor(response.data.data.total);
                    // console.log(this.climateScore);
                }
            } catch (error) {
                console.error(error);
                this.loading = false;
            }
        },
        // gets thes user information from vuex
        async getUserInformation() {
            this.loggedUserData = this.GET_currentUser.data;
        },
    },

    // * MOUNTED
    mounted() {
        let userDataResult = "";
        // When the userStatus will change by an admin the snapshot will fire give the according permission to the current user
        onSnapshot(doc(this.$firebase.db, "users", this.GET_FIREBASE_userAuth.uid), (doc) => {
            userDataResult = doc.data();
            // this.currentUserData = userDataResult;
        });
    },

    // * CREATED
    async created() {
        this.loading = true;
        // Fetches current user information
        await this.getUserInformation();

        // Calls the function to get the performance index
        this.getScores();

        // Content creator information being sent from Media page
        this.creatorid = this.$route.params.data;
        this.creatorProfile = this.$route.params.section;

        this.unreadMessages();
        this.loading = false;
    },
};
</script>

<style>
/* Style applied to the slide group for ActivityButtonsComponent to remove previous arrow and spacing. It also changes size of next arrow
 */
#feed-slideGroup-arrows > .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 0px;
}

#feed-slideGroup-arrows > .v-slide-group__next {
    flex: 0 1 auto;
    min-width: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-bottom: 86px;
}
</style>
