<template>
    <div>
        <!-- Carousels for CC and users -->
        <div v-if="favourites">
            <!-- User Favourites Carousel style="height: 22vh !important"-->
            <v-slide-group multiple show-arrows="always" id="user-feed-slideGroup-arrows" >
                <template v-slot:next>
                    <v-icon size="20">icons8-forward</v-icon>
                </template>
                <template v-slot:prev>
                    <v-icon style="width: 0px !important" small class="px-0 mx-0"></v-icon>
                </template>
                <v-slide-item v-for="(item, itemIndex) in content" :key="itemIndex" >
                    <v-card @click="resourceLaunch(item.content, item.section)" text class="d-flex flex-column align-center pt-2 pr-2" :width="$vuetify.breakpoint.width < 460 ? '132' : '172'" :class="$vuetify.breakpoint.smAndUp ? 'carouselCard-Style-Desktop' : 'carouselCard-Style'">
                        <v-icon style="position: absolute; right: 0; top: 0; z-index: 999" color="red" size="32">icons8-favorite</v-icon>
                        <!-- Content Image -->
                        <v-img v-if="item.content[`${item.section}` + 'ThumbnailImageId']" cover :src="MIX_imageURL + ('images/thumbnails/' + item.content[`${item.section}` + 'ThumbnailImageId'] + '.jpeg?')" alt="Image from media" class="rounded-km" :style="$vuetify.breakpoint.width < 460 ? 'min-height:126px !important;max-height:126px !important;' : 'min-height:142px !important;max-height:142px !important;'">
                            <template v-slot:placeholder>
                                <v-sheet>
                                    <v-skeleton-loader :loading="true" :height="120" :width="110" type="image" class="rounded-km start"></v-skeleton-loader>
                                </v-sheet>
                            </template>
                            <div v-if="`${item.section}` !== 'fuel'" :class="`${item.section} white--text kindmind-font-cap rounded-km-left text-center py-1`" style="width: 58%; position: absolute; bottom: 0">{{ item.content[`${item.section}` + "DurationMin"] }} - {{ item.content[`${item.section}` + "DurationMax"] }}</div>
                            <div v-if="`${item.section}` === 'fuel'" :class="`${item.section} white--text kindmind-font-cap text-center py-1`" :style="`width: 58%; position: absolute; bottom: 0; left:0; border: 1px solid var(--v-${item.section}-base) !important;border-radius: 0px 20px 20px 20px !important;`">{{ (item.content[`${item.section}` + "CookMins"] + item.content[`${item.section}` + "PrepMins"])-10 }} - {{ item.content[`${item.section}` + "CookMins"] + item.content[`${item.section}` + "PrepMins"] }}</div>
                            <!-- <div v-else :class="`${item.section} white--text kindmind-font-cap rounded-km-left text-center py-1`" style="width: 58%; position: absolute; bottom: 0">Over 15</div> -->
                        </v-img>

                        <!--If an image isn't present, render an icon-->
                        <v-card v-else :class="`${item.section} rounded-km d-flex justify-center align-center`" style="width: 100%; height: 142px; border-radius: 20px 20px 20px 20px; !important" :style="$vuetify.breakpoint.width < 460 ? 'max-height:126px !important;' : 'max-height:142px'">
                            <div v-if="`${item.section}` !== 'fuel'" :class="`${item.section}--text white kindmind-font-cap text-center py-1`" :style="`width: 58%; position: absolute; bottom: 0; left:0; border: 1px solid var(--v-${item.section}-base) !important;border-radius: 0px 20px 20px 20px !important;`">{{ item.content[`${item.section}` + "PrepMins"] }} - {{ item.content[`${item.section}` + "DurationMax"] }}</div>
                            <div v-if="`${item.section}` === 'fuel'" :class="`${item.section}--text white kindmind-font-cap text-center py-1`" :style="`width: 58%; position: absolute; bottom: 0; left:0; border: 1px solid var(--v-${item.section}-base) !important;border-radius: 0px 20px 20px 20px !important;`">{{ item.content[`${item.section}` + "CookMins"] + item.content[`${item.section}` + "DurationMax"] }}</div>
                            <!-- <div v-else :class="`${item.section}--text white kindmind-font-cap text-center py-1`" :style="`width: 58%; position: absolute; bottom: 0; left:0; border: 1px solid var(--v-${item.section}-base) !important;border-radius: 0px 20px 20px 20px !important;`">Over 15</div> -->
                            <v-icon class="icons8-picture white--text rounded-km" :size="$vuetify.breakpoint.xsOnly ? 92 : 112"> </v-icon>
                        </v-card>
                        
                        <!-- Content Title -->
                        <v-card-subtitle :class="$vuetify.breakpoint.smAndUp ? 'kindmind-font-b1 text-center ' : 'text-center kindmind-font-b2'" class="py-0">{{ item.content[`${item.section}` + "Title"] }}</v-card-subtitle>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
        </div>

        <!-- Content Creator Carousel -->
        <div v-else v-for="(item, itemIndex) in content" :key="itemIndex">
            <div v-for="(arrayInfo, arrayInfoIndex) in item.content" :key="arrayInfoIndex" class="d-flex flex-column">
                <span class="text-capitalize" :class="$vuetify.breakpoint.smAndUp ? `${item.section}--text kindmind-title-font-h6-bold ` : `${item.section}--text kindmind-title-font-h7-bold`">{{ item.section }}</span>

                <v-slide-group multiple show-arrows="always" id="feed-slideGroup-arrows" >
                    <template v-slot:next>
                        <v-icon size="20">icons8-forward</v-icon>
                    </template>
                    <template v-slot:prev>
                        <v-icon style="width: 0px !important" small class="px-0 mx-0"></v-icon>
                    </template>
                    <v-slide-item v-for="(arraycontent, arraycontentIndex) in arrayInfo" :key="arraycontentIndex" style="height: 22vh !important">
                        <v-card @click="resourceLaunch(arraycontent, item.section)" text class="d-flex flex-column align-center rounded-km mr-4" :width="$vuetify.breakpoint.width < 460 ? '140' : '168'" :height="$vuetify.breakpoint.xsOnly ? '196' : $vuetify.breakpoint.smAndUp ? '250' : '196'">
                            <img v-if="arraycontent[`${item.section}` + 'ThumbnailImageId']" cover :src="MIX_imageURL + ('images/thumbnails/' + arraycontent[`${item.section}` + 'ThumbnailImageId'] + '.jpeg?')" alt="Image from media" class="rounded-km" width="100%" :style="$vuetify.breakpoint.width < 460 ? 'height:154px !important;' : 'height:164px'" />

                            <!--If an image isn't present, render an icon-->
                            <div v-else :class="`${item.section} rounded-km d-flex justify-center align-center`" :style="$vuetify.breakpoint.width < 460 ? 'width: 140px !important;min-height: 141px !important;' : 'width: 168px !important;height: 164px !important;'">
                                <v-icon class="icons8-picture white--text" :size="$vuetify.breakpoint.xsOnly ? 102 : 142"> </v-icon>
                            </div>

                            <v-card-subtitle class="pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'kindmind-font-b1 text-center ' : 'text-center kindmind-font-b2'">{{ arraycontent[`${item.section}` + "Title"] }}</v-card-subtitle>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "user-content-component",
    props: {
        datadown: {
            type: Array,
            default: () => {},
        },
        favourites: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        content: [],
    }),
    // * METHODS
    methods: {
        // Pases information to the parent to open up a new component with the sent value
        resourceLaunch(value, section) {
            this.$emit("newResource", value, section);
        },
        // initialise passes the information to the child component
        initialise() {
            this.content = { ...this.datadown };
        },
    },

    // * WATCH
    watch: {
        // datadown is watching changes for new content coming from the parent
        datadown: {
            handler() {
                this.initialise();
            },
            deep: true,
        },
        // content is watching changes for the answers coming from the child
        content: {
            handler() {
                this.$emit("update", this.content);
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.loading = true;
        this.initialise();
        this.loading = false;
    },
};
</script>

<style>
#feed-slideGroup-arrows > .v-slide-group__next,
.v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-top: 22px;
}

#feed-slideGroup-arrows > .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 0px;
}

#feed-slideGroup-arrows > .v-slide-group__next {
    flex: 0 1 auto;
    min-width: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-bottom: 148px;
}
#user-feed-slideGroup-arrows > .v-slide-group__next,
.v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-top: 22px;
}

#user-feed-slideGroup-arrows > .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 0px;
}

#user-feed-slideGroup-arrows > .v-slide-group__next {
    flex: 0 1 auto;
    min-width: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-bottom: 68px;
}
</style>

<style scoped>
* {
    box-shadow: none !important;
}

.carouselCard-Style {
    /* width: clamp(170px, 20vw, 188px) !important; */
    /* height: 30vh; */
    margin-bottom: 4vh;
}
.creator-carouselCard-Style {
    width: clamp(170px, 20vw, 188px) !important;
    margin-bottom: 4vh;
}
.carouselCard-Style-Desktop {
    height: clamp(182px, 22vw, 228px) !important;
    margin-bottom: 18vh;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.sidescroll {
    z-index: 1 !important;
    right: 0px !important;
    width: 10vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 100vh !important;
    background: -moz-linear-gradient(left, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
