<template>
    <div class="allowscrolling fullviewpoint" :class="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'">
        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading" />

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading()" no-gutters>
            <!-- HEADER -->
            <v-col cols="12" xs="12" lg="12" class="">
                <div class="d-flex flex-row align-center justify-end mt-2">
                    <v-icon @click="backToProfile" class="primary--text">icons8-cancel</v-icon>
                </div>
            </v-col>
        </v-row>
        <!-- List of Messages -->
        <v-row no-gutters :class="$vuetify.breakpoint.width <= 832 ? '' : 'd-flex justify-center'">
            <v-col cols="12" xs="12" lg="6">
                <!-- Message if inbox is empty -->
                <div v-if="computedMessageData.length == 0" class="d-flex flex-column align-center justify-center my-12">
                    <v-icon :size="$vuetify.breakpoint.width <= 700 ? '48' : '52'" class="py-6">icons8-secured-letter</v-icon>
                    <app-text :category="$vuetify.breakpoint.width <= 700 ? 'KM-h5' : 'KM-h4'">Your messages are empty.</app-text>
                </div>

                <!-- Mobile Cards -->
                <div v-else>
                    <div>
                        <v-card elevation="1" @click="handleOpenMessage(message)" v-for="(message, messageIndex) in computedMessageData" :key="messageIndex" flat class="rounded-lg mt-4 grey lighten-5">
                            <div class="d-flex align-center pa-4">
                                <div class="d-flex ml-4" style="width: 100%">
                                    <v-avatar color="primary" size="56" class="rounded-km">{{ senderInitials(message.sender.modifiedUserName) }}</v-avatar>
                                    <app-text category="KM-h7" class="ml-4">{{ message.sender.modifiedUserName }}</app-text>
                                    <v-spacer />
                                    <div class="align-end">
                                        <app-text category="KM-h7" class="">{{ MIX_dayTimeFormat(message.date, "short") }}</app-text>

                                        <v-icon :class="message.read ? '' : 'primary--text'" :size="$vuetify.breakpoint.width <= 700 ? '22' : '28'">{{ message.read ? "icons8-open-envelope" : "icons8-secured-letter" }}</v-icon>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "messages-component",
    props: ["allmessages", "currentuser"],
    data: () => ({
        // General
        hover: false,
        oneMessageFromSender: [],

        //Loader
        isPageLoading: false,
        ismessagesDataLoaded: false,

        // Computed Message
        computedMessageData: [],
        draftComputedMessage: {
            sender: {},
            senderOrg: "",
            date: "",
            messageSubject: "",
            messageBody: "",
            id: "",
            recipient: {},
        },
        originalComputedMessage: {
            sender: {},
            senderOrg: "",
            date: "",
            messageSubject: "",
            messageBody: "",
            read: "",
            id: "",
            recipient: {},
        },

        // overlay
        overlay: false,
        openMessage: {},

        // Edit message
        message: {
            messageRead: false,
        },
    }),

    // * METHODS
    methods: {
        // gets the user's name initial letters
        senderInitials(sender) {
            let name = sender.split(" ");
            return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
        },
        //
        backToProfile() {
            this.$emit("returnToProfile");
        },
        /**
         * Computed Is Page Loading
         *
         * Return a boolean for the page loading spinner to denote if all data has been loaded.
         *
         * @returns {boolean} if data has been loaded or not
         */
        computedIsPageLoading() {
            const t = this;
            t.isPageLoading = !t.ismessagesDataLoaded;
            return t.isPageLoading;
        },

        // Prepares data from messages for rendering
        async computedMessage(messagesList) {
            let t = this;

            // Loop through all messages and check if the sender is the current user
            let newMessagesList = [];
            for (let i = 0; i < messagesList.length; i++) {
                if (this.currentuser.id !== messagesList[i].createdUserId) {
                    newMessagesList.push(messagesList[i]);
                }
            }

            // Find the first object in the unique array with a matching key value
            const result = newMessagesList.filter((obj) => {
                return obj.messageRecipient === this.currentuser.id;
            });


const uniqueList = [];
const uniqueUserIds = [];

result.forEach(item => {
  if (!uniqueUserIds.includes(item.createdUserId)) {
    uniqueList.push(item);
    uniqueUserIds.push(item.createdUserId);
  }
});
            for (let i = 0; i < uniqueList.length; i++) {
                // Fetch sender
                let messageSender = await this.MIX_firestore_read(uniqueList[i].createdUserId, "users");
                messageSender = messageSender.data;
                t.originalComputedMessage.sender = messageSender;

                // Fetch sender
                let messageRecipient = await this.MIX_firestore_read(uniqueList[i].messageRecipient, "users");
                messageRecipient = messageRecipient.data;
                t.originalComputedMessage.recipient = messageRecipient;

                // Other data
                t.originalComputedMessage.read = uniqueList[i].messageRead;
                t.originalComputedMessage.id = uniqueList[i].entityId;
                t.originalComputedMessage.senderOrg = uniqueList[i].createdOrgId;
                t.originalComputedMessage.date = uniqueList[i].createdDate;
                t.originalComputedMessage.messageSubject = uniqueList[i].messageSubject;
                t.originalComputedMessage.messageBody = uniqueList[i].messageBody;
                t.computedMessageData.push(t.originalComputedMessage);
                t.originalComputedMessage = { ...t.draftComputedMessage };
            }
            t.ismessagesDataLoaded = true;
        },

        // Redirects the user to the message page
        handleOpenMessage(message) {
            this.$emit("openChat", message);
        },
    },
    // * CREATED
    created() {
        // renders redis content on loading
        this.computedMessage(this.allmessages);
    },
};
</script>

<style scoped></style>
