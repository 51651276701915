const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_API_HOST,
    }),
    computed: {

    },
    methods: {
        // CREATE JSON RECORD IN REDIS
        REDIS_create(collection, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/`;
                        let result = await this.$axios.post(url, data)
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // READ JSON RECORD FROM REDIS
        REDIS_read(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else if (result.status === 204) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // UPDATE JSON RECORD IN REDIS
        REDIS_update(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // DELETE JSON RECORD IN REDIS BY MARKING AS DELETED
        REDIS_delete(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RESTORE JSON RECORD IN REDIS BY REMOVING MARK AS DELETED
        REDIS_restore(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // DESTROY JSON RECORD IN REDIS BY REMOVING FROM REDIS
        REDIS_destroy(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.delete(url)
                        if (result.status === 202) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            })
        },
        // EXPIRE JSON RECORD IN REDIS BY SETTING TTL
        REDIS_expire(collection, entityId, expirySeconds) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/expire/${entityId}?seconds=${expirySeconds}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS
        REDIS_searchAll(collection, offset, count, sortBy, sortOrder) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/search/all?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY
        REDIS_searchFor(collection, offset, count, sortBy, sortOrder, searchQuery) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, searchQuery } );
                        const url = `${this.apiUrl}${collection}/search/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&searchquery=${searchQuery}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
