import Vue from "vue";
import VueRouter from "vue-router";
// Auth Routes
import SplashScreen from "@/views/SplashScreen.vue";
import LoginScreen from "@/views/auth/Auth.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import HoldingPage from "@/views/auth/HoldingPage.vue";
import createNewpassword from "@/views/auth/createNewpassword.vue";

// Social Routes
import QuestionScreen from "@/views/questions/Questions.vue";
import HomeScreen from "@/views/home/HomeScreen.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import ExploreScreen from "@/views/explore/ExploreScreen.vue";
import SectionsMenuScreen from "@/views/explore/SectionsMenuScreen.vue";
import TodayScreen from "@/views/TodayScreen.vue";
import UserProfileScreen from "@/views/userProfile/UserProfileScreen.vue";
import SocialDirectory from "@/views/social/SocialDirectory.vue";
import MediaPage from "@/views/explore/MediaPage.vue";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "@/store.js";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "splash-screen",
        component: SplashScreen,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: false, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            // userLevels: ['ClientUser', 'ClientManager' , 'ClientAdmin', 'PartnerAdmin', 'KindmindManager', 'KindmindAdmin'] // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/auth/:authId?",
        name: "login-screen",
        component: LoginScreen,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: false, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            // userLevels: ['ClientUser', 'ClientManager' , 'ClientAdmin', 'PartnerAdmin', 'KindmindManager', 'KindmindAdmin'] // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/holding",
        name: "HoldingPage",
        component: HoldingPage,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: false, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            // userLevels: ['ClientUser', 'ClientManager' , 'ClientAdmin', 'PartnerAdmin', 'KindmindManager', 'KindmindAdmin'] // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/resetpassword",
        name: "resetpassword",
        component: ResetPassword,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: false, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            // userLevels: ['ClientUser', 'ClientManager' , 'ClientAdmin', 'PartnerAdmin', 'KindmindManager', 'KindmindAdmin'] // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/createNewpassword",
        name: "createNewpassword",
        component: createNewpassword,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: false, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            // userLevels: ['ClientUser', 'ClientManager' , 'ClientAdmin', 'PartnerAdmin', 'KindmindManager', 'KindmindAdmin'] // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/dashboard",
        name: "dashboard-screen",
        component: Dashboard,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },

    {
        path: "/questions/:questionId?",
        name: "question-screen",
        component: QuestionScreen,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/home/:homeId?",
        name: "home-screen",
        component: HomeScreen,
        meta: {
            hidePoll: true, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: false, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/explore",
        name: "explore-screen",
        component: ExploreScreen,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/exploreSections",
        name: "sections-screen",
        component: SectionsMenuScreen,
        meta: {
            hidePoll: false, // hides the component on request
            // params: true,
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/today",
        name: "today-screen",
        component: TodayScreen,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/social/:socialId?",
        name: "social-screen",
        component: SocialDirectory,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/profile/:userId?",
        name: "profile-screen",
        component: UserProfileScreen,
        params: true,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/media/:id",
        name: "media-page",
        component: MediaPage,
        // params: true,
        props: true,
        meta: {
            hidePoll: false, // hides the component on request
            requiresAuth: true, // Checks if current user exists in app
            requiresOnboarding: true, // Checks if current user has completed onboarding
            userLevels: ["ClientUser", "ClientManager", "ClientAdmin", "PartnerAdmin", "KindmindManager", "KindmindAdmin"], // Limits user access to application depending on their permissions
        },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: (to) => {
            return { path: "/", query: { q: to.params.pathMatch } };
        },
    },
    // { path: '/',
    // name: 'Login',
    // component: Login, meta: { requiresAuth: false, hidePoll: true, hideAppBar: true }},
    //   // CMS Routes
    //   { path: '/today', name: 'today-screen', component: TodayScreen, meta: { requiresAuth: true, hidePoll: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
    //   { path: '/videos/', name: 'Videos', component: Videos, meta: { requiresAuth: true, hidePoll: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
    //   { path: '/streams/', name: 'Streams', component: Streams, meta: { requiresAuth: true, hidePoll: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// Each time the path changes it'll check if the action requires authorisation. E.g. userProfile requires authorisation so we can manipulate data
router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        navigator.serviceWorker.register("/service-worker.js").then((reg) => {
            reg.update();
        });
        const auth = getAuth();
        // Get User Profile
        let userProfile = store.state.currentUser;
        onAuthStateChanged(auth, (user) => {
            if (user !== null) {
                user.getIdToken(true)
                    .then((idToken) => {
                        var Buffer = require("buffer/").Buffer;
                        var token = JSON.parse(Buffer.from(idToken.split(".")[1], "base64").toString("utf8"));
                        if (token.user_status === "Approved") {
                            let authFail = 0;
                            if (to.meta.requiresOnboarding) {
                                if (userProfile.data.onboarding === false) {
                                    next("/questions/onBoarding");
                                } else {
                                    next();
                                }
                            }
                            if (to.meta.userLevels !== "" && to.meta.userLevels !== undefined && to.meta.userLevels !== null) {
                                if (!to.meta.userLevels.includes(token.user_level)) {
                                    authFail++;
                                }
                            }
                            if (authFail === 0) {
                                next();
                            } else {
                                next("/auth");
                            }
                        } else {
                            next("/holding"); // If issues the function takes you to a holding page
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        next("/auth");
                    });
            } else {
                next("/auth");
            }
        });
    } else {
        next();
    }
});

export default router;
