<template>
    <!-- This Component is the Navigation Bar at the bottom of the app. It naviagates between the four main sections of the app: Dashboard/ Home, Explore, Social and Profile -->
    <!-- Nav Bar -->
    <v-bottom-navigation app flat :class="setpadding" :active-class="''" elevation="0" class="bottomNavigation mt-0 py-0 mb-3" grow :style="$vuetify.breakpoint.width < 400 ? 'height: 76px !important;' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'height: 86px !important;' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'height: 86px !important;' : $vuetify.breakpoint.width > 999 && $vuetify.breakpoint.width < 1300 ? 'height: 86px !important;' : 'height: 86px !important;'" >
        <div :class="homeCover" class="d-flex justify-center align-center primary white--text rounded-km" style="width: 100%; height: 100%">
            <v-btn v-for="(item, itemRouteIndex) in links" :key="itemRouteIndex" @click="handleButtonClick(item.route)" class="mx-0 px-0 rounded-km" active-class="primary rounded-km" depressed :ripple="false">
                <span :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-b1' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h7' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h7' : 'kindmind-font-h7'" class="white--text pt-2">{{ item.text }}</span>
                <span v-if="item.text == 'Me' && unreadmessagestotal > 0">
                    <v-badge bordered color="error" overlap dot>
                        <v-icon :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h5-5' : 'kindmind-font-h5-5'" class="white--text">{{ item.icon }}</v-icon></v-badge
                    ></span
                >
                <span v-else>
                    <v-icon :class="$vuetify.breakpoint.width < 400 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h5-5' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-h4' : 'kindmind-font-h4'" class="white--text">{{ item.icon }}</v-icon>
                </span>
            </v-btn>
        </div>
    </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "bottom-navigation",
    props: {
        setpadding: {
            type: String,
            default: null,
        },
        homeCover: {
            type: String,
            default: null,
        },
    },
    // * DATA
    data: () => ({
        sectionstyle: "primary",
        currentUserData: "",
        unreadmessagestotal: 0,
        links: [
            { icon: "icons8-calendar", text: "Today", route: "/dashboard" },
            { icon: "icons8-compass-west", text: "Explore", route: "/explore" },
            { icon: "icons8-communication", text: "Social", route: "/social" },
            { icon: "icons8-user", text: "Me", route: "/profile" },
        ],
    }),
    // * METHODS
    methods: {
        /**
         * Handles the click on the buttons and redirects user to the requested page
         */
        handleButtonClick(routeName) {
            if (routeName === "/explore") {
                this.MIX_go(routeName);
                this.$emit("reloadexplore");
            } else if (routeName === "/profile") {
                this.MIX_go({ name: "profile-screen", params: { userId: `${this.currentUserData.id}` } });
                this.$emit("loadprofile");
            } else {
                this.MIX_go(routeName);
            }
        },
        // Sets padding depending on viewport width
        setPadding() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 382:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 381 && this.$vuetify.breakpoint.width < 400:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 399 && this.$vuetify.breakpoint.width < 700:
                    return "px-6";
                case this.$vuetify.breakpoint.width > 700 && this.$vuetify.breakpoint.width < 1000:
                    return "px-16";
                case this.$vuetify.breakpoint.width > 1000:
                    return "px-6";
                default:
                    return "";
            }
        },
        // Fetch unread messages
        async unreadMessages(userId) {
            let t = this;
            // Fetches unread messages total number and sets it to local storage
            let unreadMessagessearchQuery = `@messageRecipient:{${userId}} @messageStatus:{Sent} @messageRead:{false} @deleted:{false}`;
            
            let unreadMessages = await t.REDIS_searchFor("message", null, null, null, null, unreadMessagessearchQuery);
           
           let unreadNewMessages = unreadMessages.data.documents;
            t.unreadmessagestotal = unreadNewMessages.length;
            let setMessages = localStorage.setItem("unreadMessages", t.unreadmessagestotal);
        },
    },
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    // * MOUNTED
    async mounted() {
        
        // Fetches current user information
        let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
        let currentUserData = currUserResult.data;
        this.currentUserData = currentUserData;
        await this.unreadMessages(this.currentUserData.id);
        // console.log(`currentUserData: ${this.currentUserData}`);
    },
    // * WATCH
    watch: {},
    // * CREATED
    async created() {

    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.bottomNavigation {
    z-index: 999 !important;
    background-color: transparent !important;
    /* background-color: rgba(255, 255, 255, 0) !important; */
    /* height: 64px !important */
    /* height: clamp(77px, 22vw, 112px) !important;  */
    /* background-color: var(--v-primary-base); */
}
</style>
