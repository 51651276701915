<template>
    <div class="fullviewpoint allowscrolling primary overflow-y-auto overflow-x-hidden">
        <!--Logo-->
        <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" md="8" class="d-flex align-center justify-center" :class="$vuetify.breakpoint.smAndUp ? 'pb-10 pt-10 mt-4' : 'pt-8'">
        <img :src="pageLogo" alt="Company's Logo" width="240">

            </v-col>
        </v-row>
        <component :is="component" @componentRequest="newComponentRequest"></component>
        <div style="padding-bottom: 120px !important;"></div>
    </div>
</template>

<script>
// import RegisterComponent from "@/views/auth/RegisterComponent.vue";
import { mapGetters } from "vuex";
import LoginComponent from "@/views/auth/LoginScreen.vue";
import RegisterComponent from "@/views/auth/Register.vue";
import ResetPasswordComponent from "@/views/auth/ResetPasswordComponent.vue";
export default {
    name: 'auth-view',
    component: { LoginComponent, RegisterComponent, ResetPasswordComponent},
    data: () => ({
        component: 'login-component',
        listc: ["register-component","reset-component"],
        authId: '',
        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),
     methods: {
            newComponentRequest(componentName) {
                if (componentName == 'register') {
                    this.component = this.listc[0]
                    this.$router.push('/auth/register')
                    this.authId = 'register'
                } else if (componentName == 'login') {
                    this.component = 'login-component'
                    this.$router.push('/auth/login')

                    this.authId = 'login'
                } else {
                    this.component = this.listc[1]
                    this.$router.push('/auth/resetpassword')
                    this.authId = 'resetpassword'
                }
                
            },
        //             getBranding() {
        //     let t = this;
        // let brandingData = t.GET_setBranding;
        // // console.log("brandingData", brandingData);
        // if (brandingData.brandingName != 'KindMind' && brandingData.brandingName != undefined) {
        //     t.MIX_brandingTheme(brandingData);
        //     t.pageLogo = t.MIX_brandingImg(brandingData);
        // }
        // }
        },

    //     // * BEFORE MOUNT
    //    beforeMount() {
    //     let t = this;
    //     let brandingData = t.GET_setBranding;
    //     // console.log("brandingData", brandingData);
    //     if (brandingData.brandingName != 'KindMind' && brandingData.brandingName != undefined) {
    //         t.MIX_brandingTheme(brandingData);
    //         t.pageLogo = t.MIX_brandingImg(brandingData);
    //     }
    // },
        // * MOUNTED
    // mounted() {
    //     let t = this;
    //     if (t.$route.query.q || (t.GET_setBranding && t.GET_setBranding.brandingName)) {
    //         setTimeout(() => {
    //             t.getBranding();
    //         }, 500);
    //     }
    //     // Times out the view
    //     setTimeout(() => {
    //         this.$router.push("/dashboard");
    //     }, 3000);
    // }, 
        // * COMPUTED
        computed: {
        ...mapGetters(['GET_setBranding']),
    },

    // * CREATED
          created () {
            this.authId = this.$route.params.authId
            if (this.authId == 'resetpassword') {
                this.component = this.listc[1]
            } else if(this.authId == 'register') {
                this.component = this.listc[0]
            }
        }
}
</script>
