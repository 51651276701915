<template>
    <div v-if="getFavouriteContent" style="background-color: white !important">
        <div :class="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'">
            <div class="d-flex py-2">
                <!-- Settings Button -->
                <v-spacer />
            </div>
            <!-- Profile image and info  -->
            <v-row no-gutters class="d-flex justify-start mt-0" style="min-height: 18vh" :class="$vuetify.breakpoint.xsOnly ? '' : 'mb-12 justify-center'">
                <v-col cols="12" xs="12" lg="6" xl="6" :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-space-between align-end' : 'd-flex align-end'">
                    <v-img class="rounded-km pb-0 mr-2" v-if="loggeduser.userProfile !== ''" :src="loggeduser.userProfile" :style="$vuetify.breakpoint.width > 400 ? 'max-width: 34%; height: 100% !important' : 'max-width: 40%; height: 100% !important'">
                        <ImageUpload-component :loggeduser="loggeduser" class="d-flex justify-center align-start" style="position: absolute; z-index: 9" :style="$vuetify.breakpoint.height > 600 ? 'margin-left: 0; bottom:0;' : 'margin-top: 126px !important'" />
                    </v-img>
                    <!-- If an image isn't present, render an icon -->
                    <div v-else class="primary d-flex align-center justify-center rounded-km mr-2" style="min-width: 40%; height: 100%">
                        <ImageUpload-component :loggeduser="loggeduser" />
                    </div>

                    <div class="py-0 my-0" style="width: 100%; height: 100%" :class="$vuetify.breakpoint.xsOnly ? '' : 'ml-2'">
                        <div text class="grey lighten-4 text-start px-2 align-center d-flex" style="height: 20% !important" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h7' : 'kindmind-font-h6'">{{ loggeduser.userName }}</div>

                        <!-- Messages -->
                        <v-container @click="handleClickOnMessage" text class="grey lighten-4 text-start d-flex flex-column align-center justify-center mt-2" style="height: 73% !important">
                            <v-btn v-if="unreadmessagestotal > 0" small style="width: 20%">
                                <v-badge bordered color="error" overlap> <v-icon size="48">icons8-secured-letter</v-icon></v-badge></v-btn
                            >
                            <v-btn v-else small style="width: 18%"><v-icon size="48">icons8-secured-letter</v-icon></v-btn>
                        </v-container>
                    </div>
                </v-col>
            </v-row>

            <!-- Notifications panel -->
            <v-row class="justify-center" no-gutters :class="$vuetify.breakpoint.xsOnly ? 'pt-4' : ''">
                <v-col cols="12" xs="12" sm="12" lg="6" class="d-flex flex-row justify-space-between" style="column-gap: 4px" v-if="userInformation">
                    <v-card v-for="(data, dataIndex) in userData" :key="dataIndex" text class="grey lighten-4 d-flex flex-column justify-center align-center rounded-km" height="14vh" :width="$vuetify.breakpoint.lgAndUp ? '14vw' : '30vw'">
                        <v-card-title :class="$vuetify.breakpoint.smAndUp ? `kindmind-font-h4 ${data.colour}--text` : `font-weight-bold kindmind-font-h6-bold ${data.colour}--text`" class="primary--text pb-0 text-capitalize">{{ data.data }}</v-card-title>
                        <v-card-text :class="$vuetify.breakpoint.smAndUp ? 'kindmind-font-h7 mt-2' : 'mt-1 kindmind-font-b2'" class="black--text text-center">{{ data.title }}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Filter -->
            <v-row class="d-flex flex-column justify-center align-center">
                <v-col cols="12" xs="12" lg="6" class="rounded-km d-flex flex-row justify-start">
                    <div class="primary--text d-flex flex-row" :class="$vuetify.breakpoint.smOnly ? 'kindmind-font-h6' : 'kindmind-font-b1'">
                        My favourites
                        <dropdown-menu-component input-type="select" class="ml-2 kindmind-font-b2" background-color="grey lighten-3" label="Search by Section" :append-icon="'icons8-search'" :items="sections" v-model="filters.searchSection" clearable @click:clear="clearFilters('searchSection', '')" />
                    </div>
                </v-col>

                <!-- Component - favourite media slides -->
                <v-col cols="12" xs="12" lg="6">
                    <div v-if="favouriteContent2.length == 0" class="grey--text text-center" :class="$vuetify.breakpoint.smOnly ? 'kindmind-font-h5' : 'kindmind-font-h7'">No data available</div>
                    <user-content-component v-else :datadown="favouriteContent2" :favourites="true" @newResource="openSelectedMedia" />
                </v-col>
            </v-row>
        </div>
        <div class="footerbanner"></div>
        <div v-if="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>
</template>

<script>
import DropdownMenuComponent from "@/components/DropdownMenuComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: { DropdownMenuComponent },
    name: "user-information-component",
    props: ["loggeduser", "unreadmessagestotal", "performanceindex"],

    data: () => ({
        userId: "",

        // User favourite content
        favouriteContent: [],
        favouriteContent2: [],
        favouriteSection: [],
        totalContentViewed: [],
        defaultSections: { section: "", content: [] },
        sections: { section: "", content: [] },

        // User Activity Tiles
        userData: [
            { data: "0", title: "Activities Viewed", color: "primary" },
            { data: "", title: "Performance Index", color: "primary" },
            { data: "", title: "Favourite Category", color: "" },
        ],

        // Month Filter
        sections: ["Pause", "Move", "Fuel", "Grow"],
        // month_timestamp: [{ date_timestamp: "1672531200 1675209599" }, { date_timestamp: "1675209600 1677628799" }, { date_timestamp: "1677628800 1680303599" }, { date_timestamp: "1680303600 1682895599" }, { date_timestamp: "1682895600 1685573999" }, { date_timestamp: "1685574000 1688165999" }, { date_timestamp: "1688166000 1690844399" }, { date_timestamp: "1690844400 1693522799" }, { date_timestamp: "1693522800 1696114799" }, { date_timestamp: "1696114800 1698796799" }, { date_timestamp: "1698796800 1701388799" }, { date_timestamp: "1701388800 1704067199" }],
        filters: {
            searchSection: "",
        },
    }),
    // * METHODS
    methods: {
        // Clear Filter
        clearFilters(filter, value) {
            let t = this;
            setTimeout(() => {
                t.filters[filter] = value;
                t.getFavouriteContent();
            }, 1);
        },

        // Gets information from redis
        async getContent() {
            this.favouriteContent2 = [];
            for (let i = 0; i < this.favouriteContent.length; i++) {
                if (this.favouriteContent[i].statRelatedSection !== null) {
                    this.defaultSections = { ...this.sections };
                    let entityId = `${this.favouriteContent[i].statRelatedId}`;
                    let section = `${this.favouriteContent[i].statRelatedSection}`;
                    let contentResult = await this.REDIS_read(section, entityId);
                    if (contentResult !== [] && contentResult !== "" && contentResult.data !== 0) {
                        this.defaultSections.content = contentResult.data;
                        this.defaultSections.section = section;
                        this.favouriteContent2.push(this.defaultSections);
                    }
                }
            }
        },
        // Receives the information passed from ActivityButtonsComponent and converts it into an object so it can be passed down to the media view
        openSelectedMedia(value, section) {
            this.MIX_go({ name: "media-page", params: { data: value, section: section, explore: this.fromExplore, id: `${value.entityId}` } });
        },

        // Opens a page with the messages
        handleClickOnMessage() {
            this.$emit("handleClickOnMessage");
        },

        getUserInformation() {
            // const currentUser = this.GET_currentUser;
            this.userId = this.loggeduser.id;
        },
    },
    // * COMPUTED
    computed: {
        //
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),
        // Computed Search Query
        computedSearchQuery() {
            let searchQuery = `@createdUserId:{${this.userId}} @statType:{heart} @deleted:{false}`;
            // Additional Filters
            if (this.filters.searchSection !== "" && this.filters.searchSection !== null && this.filters.searchSection !== undefined) {
                // replace spaces in the search query with \\
                searchQuery += ` @statRelatedSection:{${this.filters.searchSection.toLowerCase().replace(/ /g, "\\")}}`;
            }
            return searchQuery;
        },
        // Gets information on favourite content from redis
        async getFavouriteContent() {
            // Get user's fav Content
            let contentResult = await this.REDIS_searchFor("stat", null, null, null, null, this.computedSearchQuery);
            this.favouriteContent = contentResult.data.documents;

            // Get user's fav sections
            let sectionSearchQuery = `@createdUserId:{${this.userId}} @statType:{heart} @deleted:{false}`;
            let sectionResult = await this.REDIS_searchFor("stat", null, null, null, null, sectionSearchQuery);
            this.favouriteSection = sectionResult.data.documents;

            // Get user's viewed content
            let searchQueryViews = `@createdUserId:{${this.userId}} @statType:{view} @deleted:{false}`;
            let searchResult = await this.REDIS_searchFor("stat", null, null, null, null, searchQueryViews);
            this.totalContentViewed = searchResult.data.documents;
            this.getContent();
            return true;
        },
        // Gather user info
        async userInformation() {
            // Get total Number of favourite items
            let favourites = this.favouriteContent.length;
            this.userData[1].data = favourites;

            // Get section with the largest number of favourite items
            let elementCount = {};
            for (let i = 0; i < this.favouriteSection.length; i++) {
                let element = this.favouriteSection[i].statRelatedSection;
                if (element !== undefined && element !== null && element !== "") {
                    if (elementCount[element]) {
                        elementCount[element] += 1;
                    } else {
                        elementCount[element] = 1;
                    }
                }
            }
            let max = Object.entries(elementCount).reduce((max, entry) => (entry[1] >= max[1] ? entry : max), [0, -Infinity]);

            this.userData[2].data = max[0];
            this.userData[2].colour = max[0];
            // Get total Activities Viewed by user
            this.userData[0].data = this.totalContentViewed.length;

            // Get the performance index
            this.userData[1].data = this.performanceindex;
            return;
        },
    },
    // * WATCH
    watch: {
        handleSelection: {
            handler() {
                this.computedSearchQuery();
            },
            deep: true,
        },
    },
    // * CREATED
    created() {
        this.getUserInformation();
        // renders redis content on loading
        // this.userId = this.$route.params.userId;
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 16vh !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
