import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// * Import modular stores
import firebase_auth_store from "@/firebase/firebase_auth_store.js";

// Persisted State Required for Maintaining Login State
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [ createPersistedState(
    // {paths: ['userAuth', 'userAccessToken']}
  )  ],

  state: {
    currentUser: {},
    alertBox: { color: "success", timeout: 2000, message: "Success", show: false }, // alertBox state

  },
  getters: {
    GET_currentUser(state) { return state.currentUser;},
    GET_alertBox(state) { return state.alertBox }, // alertBox getters
    // USER DATA    
    GET_currentUserData(state) { return {
        userId: state.currentUser.id,
        userName: state.currentUser.userName,
        userEmail: state.currentUser.userEmail
    }},
    GET_setBranding(state){return state.setBranding},

  },
  mutations: {
    MUT_alertBox(state, payload) { state.alertBox =  payload }, // alertBox mutation
    MUT_currentUser(state, payload) { state.currentUser = payload; },
    SET_currentUser(state, payload) {
      state.currentUser = payload;
    },
    MUT_setBranding(state, payload) { state.setBranding =  payload },
  },
  actions: {
    ACT_alertBox({commit}, payload) { commit('MUT_alertBox', payload) }, // alertBox action
    // ACT_showUploadPicture({commit}, payload) { commit('MUT_showUploadPicture', payload) }, // alertBox action
    ACT_currentUser({commit}, payload) { commit('MUT_currentUser', payload) },
    ACT_setBranding({commit}, payload) { commit('MUT_setBranding', payload) },
  },
  modules: {
    firebase_auth_store: firebase_auth_store,
    // photoUpload_store: photoUpload_store,

  }
})
