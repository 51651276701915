// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
//
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: "AIzaSyAD-Kl-GHn3HBsWrpRSmi9_UKuaWEcpk8Y",
  authDomain: "kind-mind-82b03.firebaseapp.com",
  projectId: "kind-mind-82b03",
  storageBucket: "kind-mind-82b03.appspot.com",
  messagingSenderId: "268950691123",
  appId: "1:268950691123:web:550766b3cea97809f1f246",
  measurementId: "G-0WHBZWW1HJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const privateStorage = firebase.app().storage("gs://kind-mind-storage-private");
const publicStorage = firebase.app().storage("gs://kind-mind-storage-public");
const fv = firebase.firestore.FieldValue;

export {
    auth,
    currentUser,
    db,
    fv,
    privateStorage,
    publicStorage,
    storage,
};