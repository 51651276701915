<template>
    <div class="primary">
        <!--Logo-->
        <!-- <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" md="8" class="mt-8 pb-2 d-flex align-center justify-center">
                <img width="280" alt="Kind Mind logo" src="@/assets/logo.png" />
            </v-col>
        </v-row> -->

        <!--Register Account-->
        <v-row dense class="d-flex align-center justify-center mt-6">
            <v-col cols="12" xs="12" sm="10" md="8" class="d-flex align-center justify-center">
                <v-card class="pa-5 mx-5 elevation-0 rounded-xl white px-0">
                    <!--Card Information-->
                    <div class="primary--text text-center">
                        <div :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4'">Register Account</div>
                        <div class="grey--text" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'">Enter your email and password</div>
                    </div>

                    <!--Form-->
                    <div class="">
                        <v-form ref="registerUser" v-on:submit.prevent="login()" v-model="valid" lazy-validation>
                            <v-row class="d-flex align-center justify-center my-4 mx-2">
                                <!--User name-->
                                <v-col cols="12" xs="12" md="8" class="my-0 py-0">
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="form.userName" label="Full Name" append-icon="mdi-account" :rules="rules.nameRules" required></v-text-field>
                                </v-col>

                                <!--User Age-->
                                <v-col cols="12" xs="12" md="8" class="my-0 py-0">
                                    <v-menu offset-y ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="auto" required>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :rules="rules.userBirthday" solo fill v-model="dateFormatted" :dense="smalldevice" :height="smalldevice ? 35 : 55" label="Birthday date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="primary--text rounded-km" background-color="grey lighten-3" ></v-text-field>
                                        </template>
                                        <v-date-picker no-title v-model="date" :active-picker.sync="activePicker" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" min="1950-01-01" @change="save"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <!--User email-->
                                <v-col cols="12" xs="12" md="8" class="my-0 py-0">
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="form.userEmail" label="Email" type="email" append-icon="mdi-email" :rules="rules.emailRules" required></v-text-field>
                                </v-col>

                                <!--User Organisation-->
                                <v-col cols="12" xs="6" md="8" class="my-0 py-0">
                                    <v-select clearable solo text="auto" :dense="smalldevice" :height="smalldevice ? 35 : 55" background-color="grey lighten-3" class="rounded-km" :items="organisationsArray" label="Organisation" item-text="orgName" item-value="entityId" :menu-props="{ top: false, offsetY: true }" v-model="form.userOrgId"> </v-select>
                                </v-col>

                                <!--User Password-->
                                <v-col cols="12" xs="6" md="8" class="my-0 py-0">
                                    <div :class="smalldevice ? 'kindmind-font-b2' : 'kindmind-font-b1'">Password must have a minimum of 8 characters, including one uppercase character, one numeric character, and one symbol character. </div>
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="password" label="Password" ref="passwordField" :rules="rules.passwordRules" autocomplete="off" required @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-lock' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"></v-text-field>
                                </v-col>

                                <v-col cols="12" xs="6" md="8" class="my-0 py-0">
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="confirmPassword" label="Confirm Password" :append-icon="show ? 'mdi-lock' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" :rules="rules.confirmRules" autocomplete="off" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <!--Actions-->

                    <div class="text-center">
                        <v-btn x-large class="primary white--text mb-8 text-capitalize" @click="validatePassword" :disabled="form.userEmail === '' || form.userPassword === '' || loading" :loading="loading">Register</v-btn>
                    </div>

                    <div class="grey--text lighten-4 text-center">Already have an account? <span class="font-weight-bold secondary--text" style="cursor: pointer" @click="sendComponentRequest('login')">Login</span></div>
                </v-card>
            </v-col>
        </v-row>
        <footer-component />
        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />
    </div>
</template>

<script>
/* eslint-disable */
import { getAuth, updateProfile, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {mapGetters} from "vuex";

export default {
    name: "register-component",
    data() {
        return {
            // Date picker
            activePicker: null,
            date: null,
            dateFormatted: null,
            menu: false,

            inputBackgroundColor: "white",
            organisationsArray: [],
            step: 1,
            valid: true,
            valid1: true,
            show: false,
            show1: false,
            adminRegCode: "",
            alertData: { title: "", message: "", color: "", timeout: "2000", show: false },
            form: {
                id: "",
                userName: "",
                userBirthday: "",
                userEmail: "",
                createdDateTime: "",
                createdUserId: "",
                createdUserName: "",
                createdUserEmail: "",
                modifiedDateTime: "",
                modifiedUserId: "",
                modifiedUserName: "",
                modifiedUserEmail: "",
                privacy: false,
                deleted: false,
                userStatus: "",
                userProfile: "",
                userOrgId: "",
                deleted: "0",
                onboarding: false,
            },
            password: "",
            confirmPassword: "",
            accountCreated: true,
            loading: false,
            privacyDialog: false,
            registerErrorMessage: null,
            userInLocalStorage: false,
            userInLocalStorageId: null,
            show: false,
            rules: [],
        };
    },

    computed: {
        ...mapGetters(['GET_setBranding']),
        // Checks the height of the device
        smalldevice() {
            return window.innerWidth < 680;
        },
    },
    // * METHODS
    methods: {
        //  VALIDATING SECOND WINDOW AND REGISTERING (IF VALID)
        validatePassword() {
            this.rules = {
                nameRules: [(v) => !!v || "Full name is required",
                (v) => /^[^\s]+(\s+[^\s]+)?$/.test(v) || "Name format is invalid"],
                emailRules: [(v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
                (v) => !/\s+/.test(v) || "E-mail must not contain spaces"],
                telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
                departmentRules: [(v) => !!v || "A department is required"],
                organisationRules: [(v) => !!v || "An organisation is required"],
                passwordRules: [(v) => !!v || "Password is required", (v) => v.length >= 6 || "Password must be at least 6 characters",
                (v) => /[A-Z]/.test(v) || "Password must contain at least 1 uppercase",
                (v) => /[a-z]/.test(v) || "Password must contain at least 1 lowercase",
                (v) => /\d/.test(v) || "Password must contain at least 1 number",
                (v) => /[\W_]/.test(v) || "Password must contain at least 1 special character"],
                confirmRules: [(v) => !!v || "Confirm password required"],
                userBirthday: [(v) => !!v || "Date is required"],
            };
            this.$nextTick(() => {
                if (this.$refs.registerUser.validate()) {
                    if (this.password === this.confirmPassword) {
                        this.register();
                    } else {
                        this.alertData = { title: "Account Created", message: "Passwords do not match", color: "error", timeout: 2000, show: true };
                        // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Passwords do not match", show: true });
                    }
                } else {
                    // ! did not pass validation
                    this.alertData = { title: "", message: "Fill out all the fields correctly in order to proceed.", color: "error", timeout: 2000, show: true };
                    // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                }
            });
        },
        // Read all data
        async readOrganisations() {
            try {
                let searchForResult = await this.REDIS_searchFor("org", null, null, null, null, "@deleted: {false}");
                this.organisationsArray = searchForResult.data.documents;
            } catch (error) {
                console.error("Error: 1 ", error);
            }
        },
        //* REGISTER
        async register() {
            var t = this;
            t.loading = true;
            const auth = getAuth();
            t.form.userEmail = t.form.userEmail.toLowerCase();
            // * CREATE USER AUTH
            createUserWithEmailAndPassword(auth, t.form.userEmail, t.password)
                .then(async (user) => {
                    var userId = user.user.uid;
                    // * SET USER DETAILS
                    t.setUserDetails(userId);
                    //* CREATE USER ACCOUNT
                    await t.MIX_firestore_create(t.form, "users");
                    await updateProfile(auth.currentUser, {
                        displayName: t.form.userName,
                    })
                        .then(async () => {
                            const user = auth.currentUser;
                            // * SET AS CURRENT USER
                            await t.MIX_FIREBASE_userAuth(user);
                            t.accountCreated = true;
                            this.alertData = { title: "Account Created", message: "Please verify your account by email", color: "success", timeout: 2000, show: true };

                            //* SEND VERIFICATION EMAIL TO USER
                            sendEmailVerification(auth.currentUser).then(() => {
                                // console.log("Email Verification Sent");
                            });
                        })
                        .catch((error) => {
                            console.error("Error" + error);
                            t.loading = false;
                        });
                })
                .then(() => {
                    // * REDIRECT USER TO HOMEPAGE
                    t.$router.push("/questions/onBoarding").catch((error) => {
                        console.error(error.message);
                    });
                })
                .catch((err) => {
                    t.registerErrorMessage = err;
                    t.accountCreated = false;
                    t.loading = false;
                    this.alertData = { title: "Email used", message: err.message, color: "error", timeout: 2000, show: true };
                    console.error("err: " + err.message);
                    // t.MIX_alertBox({
                    //     color: "error",
                    //     timeout: 2000,
                    //     message: "This email has already been registered",
                    //     show: true,
                    // });
                });
        },

        //* SETTING DETAILS TO USER
        setUserDetails(uid) {
            var t = this;
            t.form.id = uid; // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format("x");
            t.form.createdUserId = uid;
            t.form.createdUserName = t.form.userName;
            t.form.createdUserEmail = t.form.userEmail;
            t.form.modifiedDateTime = t.$moment().format("x");
            t.form.modifiedUserId = uid;
            t.form.modifiedUserName = t.form.userName;
            t.form.modifiedUserEmail = t.form.userEmail;
            t.form.onboarding = t.form.onboarding;
            t.form.userBirthday = this.unixTimestamp(this.dateFormatted);
        },

        //* SEND VERIFICATION EMAIL
        sendVerificationEmail(currUser) {
            var t = this;
            var currentUser = currUser;
            currentUser
                .sendEmailVerification()
                .then(function () {
                    this.alertData = { title: "verification email", message: "An account verification email has been sent to you", color: "warning", timeout: 2000, show: true };
                    // t.MIX_alertBox({
                    //     color: "warning",
                    //     timeout: 2000,
                    //     message:
                    //         "An account verification email has been sent to you.",
                    //     show: true,
                    // });
                })
                .catch(function (error) {
                    // ! verification email error
                    console.error("err: " + error);
                    this.alertData = { title: "Account Created", message: "Error", color: "success", timeout: 2000, show: true };
                    // t.MIX_alertBox({
                    //     color: "error",
                    //     timeout: 2000,
                    //     message: Error,
                    //     show: true,
                    // });
                });
        },
        sendComponentRequest(event) {
            if (event == "register") {
                this.$emit("componentRequest", "register");
            } else if (event == "login") {
                this.$emit("componentRequest", "login");
            } else {
                this.$emit("componentRequest", "reset");
            }
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        // Changes the date to unix
        unixTimestamp(date) {
            let dateParts = date.split("/");
            let year = parseInt(dateParts[2], 10);
            let month = parseInt(dateParts[1], 10) - 1; // month is zero-based
            let day = parseInt(dateParts[0], 10);

            let dateObject = new Date(year, month, day);
            let unixTimestamp = Math.floor(dateObject.getTime() / 1000);
            return unixTimestamp.toString();
        },
    },
            // * BEFORE MOUNT
       beforeMount() {
        let t = this;
        let brandingData = t.GET_setBranding;
        // console.log("brandingData", brandingData);
        if (brandingData.brandingName != 'KindMind' && brandingData.brandingName != undefined) {
            t.MIX_brandingTheme(brandingData);
            t.pageLogo = t.MIX_brandingImg(brandingData);
        }
    },
    //*WATCH
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        date() {
            this.dateFormatted = this.formatDate(this.date);
        },
    },
    //*CREATED
    async created() {
        this.readOrganisations();
    },
};
</script>
