<template>
    <v-overlay class="pa-5" opacity="0.9" v-if="updateExists" style="z-index: 9999999999 !important;">
        <v-card class="rounded-md secondary">
            <v-card-title>
                <span class="headline">Update Available</span>
            </v-card-title>
            <v-card-text>
                <strong>A new version of the app is available. Please update to the latest version.</strong>
            </v-card-text>
            <v-card-actions class="pa-5">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-1" :disabled="loading" epressed @click="cancelUpdate">Ignore</v-btn>
                <v-btn color="white primary--text" :disabled="loading" depressed @click="updateNow">
                    <v-progress-circular  v-if="loading" indeterminate size="20" width="2" color="primary" class="mr-2"></v-progress-circular>
                    
                    Update Now</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>
</template>

<script>
export default {
    name: 'AppUpdate',
    data: () => ({
        loading: false
    }),
    methods: {
        updateNow() {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 5000);
            setTimeout(() => {
                this.refreshApp();
            }, 1000);
        },
        cancelUpdate() {
            // console.log('Ignore Update');
            this.updateExists = false;
            this.loading = false;
        }

    }
}
</script>
