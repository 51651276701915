<template>
    <div style="width: 100% !important">
        <!-- Cards are displayed in the dashboard. Will hold relevant articles -->
        <v-col cols="12" xs="12" md="" v-for="(article, indexOfArticle) in articles" :key="indexOfArticle" class="pt-0">
            <div v-if="dailyContent[indexOfArticle] !== undefined" @click="handleClickedCard(`${article.cardStatus}`, indexOfArticle)" :style="`border:1px solid var(--v-${article.cardStatus}-base) !important; border-radius: 20px; ${setWidth()}`" class="grey lighten-4 px-0 mx-0 white d-flex" :class="$vuetify.breakpoint.xsOnly ? 'rounded-xs' : 'rounded-smAndUp'">
                <div class="cardtagstatus" :class="`${article.cardStatus}`"></div>
                <!-- width:48vw !important; -->
                <div class="d-flex flex-column cardsWidth" style="overflow-y: hidden;">
                    <div class="d-flex align-center" :class="browsersmalldevice ? 'kindmind-font-cap px-2 pt-1' : $vuetify.breakpoint.width < 400 ? `kindmind-font-cap pt-1 px-2` : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'kindmind-font-h7 pt-1 px-2' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'kindmind-font-b1 pt-1 px-4' : 'kindmind-font-h7 pt-1 pb-1 px-4'">{{ dailyContent[indexOfArticle][`${article.cardStatus}` + "Title"] }} <v-icon v-if="article.viewed === 'true'" :class="`ml-1`" :color="`${article.cardStatus}`" :size="$vuetify.breakpoint.width < 600 ? '15' : '22'">icons8-ok</v-icon></div>
                    <v-spacer />
                    <div class="d-flex align-center">
                        <div v-if="article.cardStatus === 'fuel'" :class="browsersmalldevice ? `${article.cardStatus}--text kindmind-font-cap-s pl-2` : $vuetify.breakpoint.width < 400 ? `${article.cardStatus}--text kindmind-font-cap pb-1 pl-2` : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? `${article.cardStatus}--text kindmind-font-cap-bold pb-1 pl-2` : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? `${article.cardStatus}--text kindmind-font-b1-bold pt-t pb-1 pl-4` : `${article.cardStatus}--text kindmind-font-b1 pl-4`"><v-icon :class="`${article.cardStatus}--text`" :size="$vuetify.breakpoint.width < 600 ? '14' : '22'">icons8-clock</v-icon> {{ (dailyContent[indexOfArticle].fuelCookMins) + (dailyContent[indexOfArticle].fuelPrepMins) }} mins</div>
                        <div v-else :class="browsersmalldevice ? `${article.cardStatus}--text kindmind-font-cap-s pl-2` : $vuetify.breakpoint.width < 400 ? `${article.cardStatus}--text kindmind-font-cap pb-1 pl-2` : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? `${article.cardStatus}--text kindmind-font-cap-bold pb-1 pl-2` : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? `${article.cardStatus}--text kindmind-font-b1-bold pt-t pb-1 pl-4` : `${article.cardStatus}--text kindmind-font-b1 pl-4`"><v-icon :class="`${article.cardStatus}--text`" :size="$vuetify.breakpoint.width < 600 ? '14' : '22'">icons8-clock</v-icon> {{ dailyContent[indexOfArticle][`${article.cardStatus}` + "DurationMax"] }} mins</div>
                        <v-spacer />
                        <div :class="browsersmalldevice ? `${article.cardStatus}--text kindmind-font-cap-s pr-4` : $vuetify.breakpoint.width < 400 ? `${article.cardStatus}--text kindmind-font-cap pb-1 pr-4` : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? `${article.cardStatus}--text kindmind-font-cap-bold pb-1 pr-2` : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? `${article.cardStatus}--text kindmind-font-b1-bold pt-1 pb-1 pr-6` : `${article.cardStatus}--text kindmind-font-b1-bold pr-4`">
                            <span class="text-capitalize">{{ article.cardStatus }}</span> | <span v-if="article.cardStatus === 'grow'"> {{ dailyContent[indexOfArticle][`${article.cardStatus}` + "Topic"] }}</span><span v-else> {{ dailyContent[indexOfArticle][`${article.cardStatus}` + "Type"] }}</span>
                        </div>
                    </div>
                </div>
                <v-spacer />
                <div class="d-flex">
                    <v-img v-if="dailyContent[indexOfArticle][`${article.cardStatus}` + 'ThumbnailImageId'] !== ''" :src="MIX_imageURL + ('images/thumbnails/' + dailyContent[indexOfArticle][`${article.cardStatus}` + 'ThumbnailImageId'] + '.jpeg?')" alt="Image from media" :class="$vuetify.breakpoint.width < 650 ? 'imgMobile' : $vuetify.breakpoint.width > 649 && $vuetify.breakpoint.width < 950 ? ' imgTablet' : 'imgDesktop'" />

                    <!--If an image isn't present, render an icon-->
                    <div v-else class="d-flex justify-center align-center" :class="$vuetify.breakpoint.width < 650 ? `${article.cardStatus} imgPlaceholderMobile` : $vuetify.breakpoint.width > 649 && $vuetify.breakpoint.width < 950 ? `${article.cardStatus} imgPlaceholderTablet` : `${article.cardStatus} imgPlaceholderDesktop`">
                        <v-icon color="white" :size="$vuetify.breakpoint.width < 650 ? `62` : $vuetify.breakpoint.width > 649 && $vuetify.breakpoint.width < 950 ? `78` : `84`" class="icons8-picture"> </v-icon>
                    </div>
                </div>
            </div>
        </v-col>
    </div>
</template>

<script>
import MediaPageComponent from "../explore/MediaPage.vue";

export default {
    components: { MediaPageComponent },
    name: "content-cards",
    props: {
        dailyContent: {
            type: Array,
            default: null,
        },
    },
    data: () => ({
        content: {},
        contentId: [],
        contentViewed: false,
        articles: [
            { cardStatus: "pause", viewed: false },
            { cardStatus: "fuel", viewed: false },
            { cardStatus: "move", viewed: false },
            { cardStatus: "grow", viewed: false },
        ], //, ],
    }),
    // * METHODS
    methods: {
        // * Sends data from selected card to the parent view, also send current section
        handleClickedCard(section, index) {
            this.$emit("handleclickedcard", this.dailyContent[index], section);
        },
        // Sets width of margins
        setWidth() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 382:
                    return "height: 11vh !important;";
                case this.$vuetify.breakpoint.width > 381 && this.$vuetify.breakpoint.width < 400:
                    return "height: 10vh !important;";
                case this.$vuetify.breakpoint.width > 399 && this.$vuetify.breakpoint.width < 779:
                    return "height: 10vh !important;";
                case this.$vuetify.breakpoint.width > 779 && this.$vuetify.breakpoint.width < 1000:
                    return "height: 8vh !important;";
                case this.$vuetify.breakpoint.width > 1000:
                    return "height: 12vh !important; width:44vw !important;";
                default:
                    return "";
            }
        },
        isThisviewed() {
            for (let i = 0; i < this.articles.length; i++) {
                let section = this.articles[i].cardStatus;
                let viewed = localStorage.getItem(`${section}-viewed`);
                this.articles[i].viewed = viewed;
            }
        },
    },

    // * COMPUTED
    computed: {
        // Checks the size of viewport
        browsersmalldevice() {
            return window.innerHeight < 528;
        },
    },

    // * CREATED
    async created() {
        this.loading = true;
        this.isThisviewed();
        this.loading = false;
    },
};
</script>

<style scoped>
/* Styling the cards */
.cardsWidth {
    width: 100%;
}
/* Styling the bar on each card */
.cardtagstatus {
    height: 100%;
    min-width: clamp(12px, 4vw, 18px);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
/* Styles the edges of the cards/images */
.rounded-smAndUp {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 19px;
}

.rounded-xs {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 15px;
}
/* Images styles */
.imgMobile {
    max-height: 11vh !important;
    max-width: 24vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    object-fit: scale-down !important;
}

.imgTablet {
    max-height: 12vh !important;
    max-width: 19vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    object-fit: scale-down !important;
}
.imgDesktop {
    max-height: 12vh !important;
    width: 12vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    object-fit: scale-down !important;
}

/* Placeholder image style */

.imgPlaceholderMobile {
    width: 24vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.imgPlaceholderTablet {
    width: 19vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.imgPlaceholderDesktop {
    width: 12vw !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
</style>
