<template>
    <div class="d-flex py-0 my-0" no-gutters>
        <!-- <v-card> -->
                <!-- App bar component implemented throughout app. Burger menu component within this one -->
                <v-app-bar :class="setmargin" class="primary rounded-km d-flex flex-row align-center justify-end px-2 mx-0 mt-3" 
                :style="$vuetify.breakpoint.width < 400 ? 'height: 76px !important;' 
                : $vuetify.breakpoint.width > 400 && $vuetify.breakpoint.width < 700 ? 'height: 86px !important;' 
                : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? 'height: 86px !important;' 
                : $vuetify.breakpoint.width > 999 && $vuetify.breakpoint.width < 1300 ? 'height: 86px !important;' : 'height: 86px !important;'">
                    <div style="position: fixed !important; left:0 !important; width: 100% !important;" class="d-flex">
                        <img :src="pageLogo" alt="Company's Log" class="topNavigation-image" @click="MIX_go('/dashboard')">
                    </div>
                        <!-- SHOUT Overlay -->
                        <v-btn class="white--text px-0 mx-0" color="primary" @click="approvalOverlay = true" small> <v-icon large>icons8-communication</v-icon> </v-btn>

                        <!-- BURGER MENU -->
                        <dropdown-menu-component input-type="burger" @handleSelection="handleClickedOption" :datadown="computedMenuItems" :icon="'icons8-menu'" :colour="'white'" :buttonStyle="'primary'" :iconstyle="'kind-mind-font-h2 large'" :iconSize="'34'" />
                </v-app-bar>

        <!-- SHOUT OVERLAY -->
        <v-overlay :z-index="zIndex" :value="approvalOverlay">
            <v-card opacity="1" class="rounded-km d-flex flex-column align-center justify-space-evenly grey lighten-4 pa-4" style="margin-left:auto !important;margin-right:auto !important;" :style="$vuetify.breakpoint.xsOnly ? 'width: 78%;' : 'width: 78%;'">
                <v-card-text class="black--text text-center ma-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h7'">Do you need someone to talk to? Shout offers free 24/7 support via text messaging to help you cope with any struggles you may be facing. To start getting support, simply click 'accept' and you'll be redirected to your messaging app.</v-card-text>
                <div class="d-flex justify-center py-2" style="width: 100%">
                    <v-btn v-if="android" class="white--text mr-2" color="primary" @click="startShout"><a class="white--text" style="text-decoration: none" href="sms: 05258 ?body= Text the word SHOUT to 05258 ">Accept</a></v-btn>
                    <v-btn v-else class="white--text mr-2" color="primary" @click="startShout"><a class="white--text" style="text-decoration: none" href="sms: 05258 &body= Text the word SHOUT to 05258 ">Accept</a></v-btn>
                    <v-btn class="white--text" color="primary" @click="approvalOverlay = false"> Cancel </v-btn>
                </div>
            </v-card>
        </v-overlay>

    </div>
</template>

<script>
import { version } from "@/../package.json";
import InstallApp from "@/components/InstallApp.vue";
import { mapGetters } from "vuex";

export default {
    name: "app-bar-component",
    components: {
        InstallApp,
    },
    props: {
        barstyle: {
            type: String,
            default: null,
        },
        appbarcolour: {
            type: String,
            default: null,
        },
        backbutton: {
            type: Boolean,
            default: null,
        },
        userdeviceplatform: {
            type: String,
            default: null,
        },
        setmargin: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        // Full Menu item list
        menuitems: [
            {dropdownItem: "Reload App", isEnabled: true },
            {dropdownItem: "Log out", isEnabled: true },
            {dropdownItem: "Install App", isEnabled: false},
            {dropdownItem: "Preferences", isEnabled: false },
            {dropdownItem: `v${version}`, isEnabled: true }
            ],

        // Computed dropdown
        computedMenuItems: [],

        // Install app 
        showInstallApp: false,
        showinstallmessageburgermenu: false,
        android: false,

        // SHOUT overlay
        approvalOverlay: false,

        // Generic
        zIndex: 99999,

        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),

     // * BEFORE MOUNT
       beforeMount() {
        let t = this;
        let brandingData = t.GET_setBranding;
        if (brandingData != undefined) {
        if (brandingData.brandingName != 'KindMind' && brandingData.brandingName != undefined) {
            t.MIX_brandingTheme(brandingData);
            t.pageLogo = t.MIX_brandingImg(brandingData);
        }}
    },

    // * METHODS
    methods: {
        // Sends the parent the information of what it needs to render next
        goexplore() {
            this.$emit("goexplore");
        },
        // Sends the parent the information of what it needs to render next
        handleSignOut() {
            sessionStorage.clear();
            localStorage.clear();
            this.MIX_FIREBASE_logout();
            // this.MIX_go('/');
        },
        // Actions the burger menu
        handleClickedOption(item) {
            switch (this.menuitems[item].dropdownItem) {
                case "Log out":
                    this.handleSignOut();
                    break;
                case "Reload App":
                    window.location.reload();
                    break;
                case "Install App":
                    this.userdeviceplatform = this.userdeviceplatform;
                    this.$emit("InstallAppTrue");
                    break;
                    }
        },
        
        installApp() {
            // iOS user agents
            const t = this;
            if (t.MIX_getFromLocalStorage("hideInstallPrompt") && !navigator.standalone || this.android && !navigator.standalone) {
             t.showinstallmessageburgermenu = true;      
            }    
             if (t.showinstallmessageburgermenu) {
                    t.menuitems[2].isEnabled = true;} 
        },
        /**
         * startShout
         */
        startShout() {
            // this.approvalOverlay = false;
            if (/iPhone/i.test(navigator.userAgent)) {
                // console.log("testiphone" )
            } else if (/Android/i.test(navigator.userAgent)) {
                // console.log("testandroid" );
                this.android = true;
            }
        },
        filteredMenu() {
            for (let i = 0; i < this.menuitems.length; i++) {
                if (this.menuitems[i].isEnabled == true) {
                    this.computedMenuItems.push(this.menuitems[i])
                }
            }
            return this.computedMenuItems
  }
    },
    // * COMPUTED
    computed: {
        computedVersion() {
            // const version = require('../../../package.json').version;
            return version;
        },

        ...mapGetters(['GET_setBranding']),
    },
    // * MOUNTED
    mounted() {},
    // * CREATED
    created() {
        this.installApp();
        this.startShout();
        this.filteredMenu();
    },
};
</script>

<style scoped>
.app-bar-box {
    display: flex !important;
    flex-direction: column !important;
    align-content: space-between !important;
    justify-content: center !important;
}

.topNavigation-image {
    height: clamp(58px, 10vw, 72px) !important;
    padding-left: 16px;
    /* width: clamp(120px, 22vw, 200px) !important; */
    /* background-color: red; */
}
</style>
