<template>
    <v-row>
        <v-col cols="12" class="text-center white--text kindmind-font-b2" style="height:26vh !important;"> Copyright 2022 Kind Mind </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "footer-component",
    };
</script>