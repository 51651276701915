<template>
    <v-overlay class="pa-5" opacity="0.9" v-if="show" style="z-index: 9999999999 !important;">
        <v-card class="rounded-km secondary">
            <v-card-title class="d-flex">
                <span :class="smalldevice ? 'kindmind-title-font-h7-bold' : 'kindmind-title-font-h7-bold'">Add App to your home screen</span>
                <v-spacer /><v-btn icon @click="$emit('close')"><v-icon>icons8-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text
                >The Kind Mind app can be installed and used as a native app on most mobile devices and desktop computers.
                <!-- <span v-if="['android', 'ios'].includes(userDevicePlatform)">The steps below can help show you how.</span> -->
            </v-card-text>

            <div v-if="userdeviceplatform === 'ios'" style="width: 90vw !important" class="px-4">
                <strong>Step 1</strong><br />
                <v-img contain src="@/assets/ios-install-step-1.svg" /><br />
                <strong>Step 2</strong><br />
                <v-img contain src="@/assets/ios-install-step-2.svg" /><br />
                <strong>Step 3</strong><br />
                <v-img contain src="@/assets/ios-install-step-3.svg" /><br />
            </div>

            <div v-else  style="max-width: 90vw !important" class="px-4">
                <strong>Step 1</strong><br />
                <!-- <div>Tap the <strong>Menu</strong> icon</div> -->
                <v-img contain class="rounded-km" src="@/assets/KM-android-installer-step1.jpg" /><br />
                <strong>Step 2</strong><br />
                <!-- <div>Tap the <strong>Install App</strong> option</div> -->
                <v-img contain class="rounded-km" src="@/assets/KM-android-installer-step2.jpg" /><br />
                <strong>Step 3</strong><br />
                <!-- <div class="pb-4">Tap <strong>Install</strong></div> -->
                <v-img contain style="max-width" class="rounded-km" src="@/assets/android-installer-km-step3.png" /><br />
            </div>
            <!-- <app-text v-if="userDevicePlatform === 'desktop'" class="mt-2" size="small"> -->
            <!-- <v-card-text>	
                Fitap can be installed as a desktop app, but as the support between browsers can differ, we recommend
				checking your browser's menu for an <strong>Install</strong> option.
                </v-card-text> -->
        </v-card>
    </v-overlay>
</template>

<script>
export default {
    name: "InstallApp",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        userdeviceplatform: {
            type: String,
            default: null,
        },
    },

    // * COMPUTED
    computed: {
        smalldevice() {
            return window.innerHeight < 600;
        },
    },
};
</script>
