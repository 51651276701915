<template>
    <v-chart class="chart" :option="option" :style="setstyle" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([GridComponent, CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: "BarChart",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        datadown: {
            type: Object,
            default: () => {},
        },
        color: {
            type: String,
        },
        seriestext: {
            type: String,
        },
        setstyle: {
            type: String,
        },
    },
    data: () => ({
        colors: [],
        allPollAnswers: [],
        chartData: [],
    }),
    computed: {
        option() {
            return {
                grid: {
                    left: 10,
                    right: 20,
                    top: 0,
                    bottom: 60,
                },
                xAxis: {
                    type: "value",
                    axisTick: { show: true },
                    min: 0,
                    tickDecimals: 0
                    
                },
                yAxis: {
                    type: "category",
                    axisLabel: { show: false },
                    data: this.chartData.map((x) => x.name),
                    axisTick: { show: false },
                    
                },
                
                series: [
                    {
                        label: {
                            show: true,
                            formatter: "{b}",
                            position: ['5', '30%'],
                            color: "#ffffff",
                        },
                        radius: ["100%"],
                        data: this.chartData.map((x) => x.value),
                        type: "bar",
                        showBackground: true,
                        color: this.color,
                        backgroundStyle: {
                            color: "#c5c5c5",
                        },
                        
                    },
                ],
            };
        },
    },

    // * Methods
    methods: {
        async getAnswers() {
            //get all answers for this question
            let searchForResult = await this.REDIS_searchFor("useranswer", null, null, null, null, `@questionId:{${this.datadown.entityId}} @deleted:{false}`);
            
            //total not neeeded, but could be used to show total answers
            let answerTotal = searchForResult.data.total;
            this.allPollAnswers = searchForResult.data.documents;
            this.sortDataForChart()
        },
        sortDataForChart() {
            let t = this
            let stats = []
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.questionAnswers] = (acc[obj.questionAnswers] || 0) + 1
                return acc
            }, {})
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map((k) => ({
                name: k,
                value: objResult[k]
            }))
            //* Assign key value pairs to graph structure
            arrResult.forEach((stat) => {
                stats.push({
                    name: stat.name,
                    value: stat.value
                })
            })
            t.chartData = stats;
            return t.chartData
        },
},
    mounted() {
        // initialise passes the information to the child component
            this.chartData = this.seriesdata;
    },

      watch: {
    seriesdata: {
      handler: function (newVal) {
        this.chartData = newVal;
      },
      deep: true,
    },
  },

    created() {
        this.colors = [];
        if (this.datadown !== null && this.datadown !== undefined && this.datadown !== "") {
            this.getAnswers();
        };
    },
};
</script>

<style scoped>
.chart {
    width: 100% !important;
    height: 400px !important;
}
</style>

