import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
            customProperties: true,
        },
		themes: {    
            light: {
                primary: "#7FC3D8", //rgb(127,195,216)
                secondary: "#46AAC9", //rgb(70,170,201)
                accent: "#8c9eff", //rgb(140,158,255)
                error: "#b71c1c", //rgb(183,28,28)
                pause: "#77C4B4", //rgb(119,196,180)
                move: "#F7AA6A", //rgb(247,170,106)
                fuel: "#A1AEDA", //rgb(161,174,218)
                grow: "#E7A7B2" //rgb(231,167,178)

            },
        },
    },
});
