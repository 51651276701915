<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" xs="" sm="" md="" lg="8" xl="8" style="width: 100vw" :class="$vuetify.breakpoint.smAndUp ? 'px-10 mt-8' : 'mt-4 px-4'">
            <!-- Alert Box Component -->
            <snackbar-component :alertdata="alertData" />
            <v-form>
                <v-row dense class="d-flex justify-space-between">
                    <v-col cols="1" sm="1" md="" lg="1" class="d-flex justify-start">
                        <v-btn @click="backToSocial" icon large><v-icon class="grey--text">icons8-back-to</v-icon></v-btn>
                    </v-col>
                    <v-col cols="10" sm="10" md="" lg="10">
                        <v-text-field v-model="post.postTitle" outlined text :height="smalldevice ? 35 : 55" hide-details="auto" class="rounded-km mb-8" placeholder="Add a title..." dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="" lg="12" class="black--text mt-n4">
                        <div class="kindmind-font-h7">Post flair</div>
                        <v-btn-toggle v-model="selectedFlair" multiple active-class="secondary white--text" class="d-flex flex-wrap rounded-km">
                            <v-btn v-for="item, index in flairs" :key="index" :value="item" class="primary" x-small>{{ item }}</v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" sm="12" md="" lg="12" class="black--text">
                        <div class="kindmind-font-h7">Post type</div>
                        <v-btn-toggle v-model="selectedPostType" active-class="secondary white--text" class="d-flex flex-wrap rounded-km">
                            <v-btn v-for="item, index in postTypeArr" :key="index" :value="item" class="primary" x-small>{{ item }}</v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" sm="12" md="" lg="12">
                        <vue-editor v-model="post.postText" class="black--text" :editorToolbar="customToolbar" height="100" />
                        <v-btn class="primary mt-2 rounded-km" @click="submitPost()" :disabled="post.postTitle.trim() === '' || post.postText.trim() === ''" block>Submit Post</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" md="12" class="mb-12">
                        <v-divider class="my-2"></v-divider>
                        <v-btn small @click="showFileUpload = !showFileUpload" class="white--text" color="primary kindmind-font-b2-bold mb-4" :loading="loading" >
                            <v-icon class="mx-2 white--text" color="white" size="18">icons8-compact-camera</v-icon>Add Photo
                        </v-btn>
                        <FileUpload
                            v-if="showFileUpload"
                            accept="image/jpeg, image/jpg"
                            label="Select file"
                            buttonClass="primary"
                            progressClass="pa-2"
                            folderLocation="posts"
                            :fileName="MIX_generateId()"
                            :selectedItem="post"
                            :compressionMaxSizeMB="1"
                            :compressionmaxWidthOrHeight="1920"
                            :private="true"
                            v-on:update="uploadImage"
                        />
                        <div v-if="post.postImageId !== ''" class="d-flex justify-start mb-12">
                            <v-img :src="post.postImageId" class="rounded-km" max-width="100%" max-height="100" contain></v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
            <br/>
            <br/>
            <br/>
            <br/>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'new-post-component',
    props: {
        currentUser: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        alertData: { title: '', message: '', color: '', timeout: '2000', show: false },
        customToolbar: [
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
        ],
        defaultPost: {
            postTitle: '',
            postText: '',
            postImageId: '',
            postVideoId: '',
            postAudioId: '',
            postLink: '',
            postLocation: '',
            postStatus: '',
            postOrgId: '',
            postType: '',
            postFlair: [],
            postComments: [],
            publishUserId: '',
            publishOrgId: '',
            createdUserId: '',
            createdOrgId: '',
            createdDate: null,
            modifiedUserId: '',
            modifiedOrgId: '',
            modifiedDate: null,
            deletedUserId: '',
            deletedOrgId: '',
            deletedDate: null,
            deleted: false
        },
        loading: false,
        flairs: ['Nutrition', 'Fitness', 'Family', 'Relationships', 'Work'],
        overlay: false,
        overlayTwo: false,
        post: {
            id: '',
            postTitle: '',
            postText: '',
            postImageId: '',
            postVideoId: '',
            postAudioId: '',
            postLink: '',
            postLocation: '',
            postStatus: '',
            postOrgId: '',
            postType: '',
            postFlair: [],
            postComments: [],
            publishUserId: '',
            publishOrgId: '',
            createdUserId: '',
            createdUserName: '',
            createdOrgId: '',
            createdDate: null,
            modifiedUserId: '',
            modifiedOrgId: '',
            modifiedDate: null,
            deletedUserId: '',
            deletedOrgId: '',
            deletedDate: null,
            deleted: false
        },
        postTypeArr: ['Question', 'Text', 'Link', 'Image', ], //'Video', 'Audio'
        search: '',
        selectedFlair: [],
        selectedPostType: [],
        socialId: 'newPost',
        showFileUpload: false
    }),
    computed: {
        smalldevice() {
            return window.innerHeight < 600
        },
    },
    methods: {
        randomPastelColour() {
            const r = (Math.round(Math.random() * 127) + 127).toString(16)
            const g = (Math.round(Math.random() * 127) + 127).toString(16)
            const b = (Math.round(Math.random() * 127) + 127).toString(16)
            return '#' + r + g + b
        },
        backToSocial() {
            this.$emit('returnToSocial')
        },
        uploadImage(imageUrl) {
            this.post.postImageId = imageUrl
            this.showFileUpload = false
        },
        async submitPost() {
            if (this.selectedFlair.length > 0 && this.selectedPostType.length > 0) {
            try {
                this.post.createdUserId = this.currentUser.id
                this.post.createdUserName = this.currentUser.userName
                this.post.createdUserPhoto = this.currentUser.userProfile
                this.post.createdDate = parseInt(this.MIX_formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss', 'X'))
                this.post.publishUserId = this.currentUser.id
                this.post.publishOrgId = this.currentUser.userOrgId
                this.post.createdOrgId = this.currentUser.userOrgId
                this.post.postOrgId = this.currentUser.userOrgId
                this.post.modifiedOrgId = this.currentUser.userOrgId
                for (let flair of this.selectedFlair) {
                    this.post.postFlair.push(flair)
                }
                this.post.postType = this.selectedPostType

                const redisCreate = await this.REDIS_create('post', this.post)
                if(redisCreate.data !== {}) {
                    this.post = { ...this.defaultPost }
                    this.alertData = { title: 'Post Published!', message: 'Success! your post has been published', color: 'success', timeout: 2000, show: true }
                    this.backToSocial()
                }
            } catch (error) {
                this.alertData = { title: 'Post Save', message: 'Error saving post details', color: 'error', timeout: 2000, show: true }
                console.error('Error: ', error)
            }
            } else {
                 this.alertData = { title: 'Post Save', message: 'Please select a post type and flair to continue.', color: 'warning', timeout: 2000, show: true }
            }
        }
    },
    mounted() {
        this.post = { ...this.defaultPost }
        this.post.id = this.MIX_generateId()
    }
}
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.v-textarea {
    font-size: 20px;
}

.v-textarea__slot::before {
    border-style: none !important;
}

.ql-editor {
    font-size: 20px;
    color: #000;
}

.quillWrapper {
    border: 1px solid #ccc;
}

</style>
