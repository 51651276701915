<template>
    <div v-if="getPreferencesContent" class="allowscrolling fullviewpoint" style="width: 100vw !important; background-color: white !important">
        <!-- Loading circle - renders when loading is true -->
        <v-overlay :value="loading">
            <v-progress-circular :size="100" :width="5" color="white" indeterminate></v-progress-circular>
        </v-overlay>

        <!-- Dashboard View containing insights. Also components content cards and bottom navigation are in this page -->

        <!-- Image Insight Dialogue -->
        <!-- <v-overlay v-if="dialog" class="d-flex justify-center" style="z-index: 99999 !important; background-color: white"> -->
        <!-- Small Viewport Carousel -->
        <v-carousel v-if="dialog && $vuetify.breakpoint.width <= 832" height="100%" hide-delimiter-background show-arrows delimiter-icon="icons8-subtract" id="dashboardInsights-carousel" style="position: fixed; z-index: 99999 !important; background-color: white">
            <!-- Position close icon relative to the carousel -->
            <v-icon @click.stop="handleCloseWindow" class="pa-2 d-flex justify-end primary--text d-block" style="position: absolute; top: 0; right: 0; z-index: 999999 !important; cursor: pointer">icons8-cancel</v-icon>
            <v-carousel-item v-for="(image, imageIndex) in insightGaleryImages" :key="imageIndex">
                <img :src="MIX_imageURL + ('images/portrait/' + image + '.jpeg?')" alt="Image from media" style="height: 100vh; width: 100vw" scale-down />
            </v-carousel-item>
        </v-carousel>

        <!-- Large Viewport Carousel -->
        <v-carousel v-if="dialog && $vuetify.breakpoint.width > 832" height="100%" hide-delimiter-background show-arrows delimiter-icon="icons8-subtract" id="dashboardInsights-carousel" style="z-index: 99999 !important; position: fixed">
            <v-carousel-item v-for="(image, imageIndex) in insightGaleryImages" :key="imageIndex">
                <!-- Container for the image and icon -->
                <div class="background-image" :style="{ backgroundImage: `url(${backgroundImageUrl})` }"></div>
                <div class="d-flex justify-center">
                    <img :src="MIX_imageURL + ('images/portrait/' + image + '.jpeg?')" alt="Image from media" style="width: 600px" />
                    <v-icon @click="dialog = !dialog" class="primary--text pa-2" size="32" style="position: absolute; top: 4; right: 0; z-index: 9999; cursor: pointer">icons8-cancel</v-icon>
                </div>
            </v-carousel-item>
        </v-carousel>
        <!-- </v-overlay> -->

        <!-- HEADER -->
        <app-bar-component app :appbarcolour="'primary'" :backbutton="false" :setmargin="setMargin()" :userdeviceplatform="userdeviceplatform" @InstallAppTrue="InstallAppTrue" />

        <!-- <div>Version: {{ computedVersion }}</div> -->
        <!-- <div>process.env.NODE_ENV: {{ computedNODEENV }}</div>
        <div>process.env.BASE_URL: {{ computedBASEURL }}</div> -->
        <!-- {{ $vuetify.breakpoint.width }} -->
        <!-- {{ $vuetify.breakpoint.height }} -->
        <div v-if="$vuetify.breakpoint.smAndUp">
            <br />
        </div>
        <div class="white pt-1" :class="setPadding()">
            <div class="mx-0 text-capitalize text-start primary--text" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h6' : 'kindmind-title-font-h5'">{{ `${welcomeUser} ${firstName}` }}</div>
            <!-- INSIGHTS -->
            <div :class="$vuetify.breakpoint.mdAndDown ? 'd-flex flex-column align-start mb-6' : 'd-flex flex-column align-center mb-6'">
                <div :class="browsersmalldevice ? 'kindmind-font-cap' : $vuetify.breakpoint.xsOnly ? 'kindmind-font-b2' : 'kindmind-font-h7'">Your Daily Insights</div>
                <v-slide-group multiple show-arrows class="dashboard-slideGroup-arrows" :style="slideGroupSetWidth()">
                    <template v-slot:next>
                        <v-icon size="20">icons8-forward</v-icon>
                    </template>
                    <template v-slot:prev>
                        <v-icon style="width: 0px !important" small></v-icon>
                    </template>
                    <template v-slot:wrapper>
                        <div class="rounded-km" active-class="white rounded-km"></div>
                    </template>
                    <div class="d-flex rounded-km">
                        <!-- Log your feelings card -->
                        <v-card flat @click="$router.push('/today')" class="rounded-km primary ma-0 mr-1" :height="browsersmalldevice ? '100px' : $vuetify.breakpoint.width < 442 ? '106px' : '118px'" width="78">
                            <v-icon large class="d-flex justify-center my-3 primary" style="background-color: primary !important; outline: none; focus: outline">icons8-plus-math</v-icon>

                            <div class="mt-1">
                                <div class="white--text pa-0 text-center" :class="browsersmalldevice ? 'kindmind-font-cap px-1' : 'kindmind-font-b2'" style=" overflow-y: hidden; ellipsis">Log your day</div>
                            </div>
                        </v-card>

                        <!-- Insights cards -->
                        <v-slide-item v-for="(item, itemInsightIndex) in insightContent" :key="itemInsightIndex" active-class="white rounded-km">
                            <v-card flat class="rounded-km primary mr-1" active-class="white rounded-km">
                                <v-img v-if="item.insightThumbnailImageId" @click="openInsight(item)" fit :height="browsersmalldevice ? '100px' : $vuetify.breakpoint.width < 442 ? '106px' : '118px'" width="78" :src="MIX_imageURL + ('images/thumbnails/' + item.insightThumbnailImageId + '.jpeg?')"> </v-img>
                                <v-img v-else @click="$router.push('/today')" fit :height="browsersmalldevice ? '100px' : $vuetify.breakpoint.width < 442 ? '106px' : '118px'" width="78" :src="item.requirementImg"> </v-img>
                            </v-card>
                        </v-slide-item>
                    </div>
                </v-slide-group>
            </div>

            <!-- PWA install app prompt -->
            <v-row v-if="showInstallMessage" class="d-flex align-center justify-center mb-2">
                <v-col cols="12" xs="12" md="8" class="d-flex align-center rounded-lg grey lighten-3 pa-3 grey--text text--darken-2 mb-1">
                    <div class="font-weight-bold grey--text text--darken-1" @click="showInstallApp = true">Add Kind Mind to your Home Screen</div>
                    <v-spacer />
                    <div><v-icon class="primary--text" @click="cancelInstallMessage()">icons8-close</v-icon></div>
                </v-col>
            </v-row>
            <!-- CONTENT CARDS - Desktop View -->
            <v-row v-if="$vuetify.breakpoint.width > 780">
                <v-col cols="12" xs="12" md="" class="py-0">
                    <div :class="browsersmalldevice ? 'kindmind-font-cap' : $vuetify.breakpoint.xsOnly ? `kindmind-font-b2 mx-0` : `kindmind-font-h7 mx-0`" style="width: 100%">Your Daily Activities</div>
                </v-col>
                <content-cards @handleclickedcard="handleclickedcard" class="dashboard-contentDaily-desktop" :dailyContent="dailyContent" />
            </v-row>

            <!-- CONTENT CARDS - Mobie View -->
            <div v-else class="dashboard-contentDaily-mobile">
                <v-row>
                    <v-col cols="12" xs="12" md="" class="py-0">
                        <div :class="browsersmalldevice ? 'kindmind-font-cap' : $vuetify.breakpoint.xsOnly ? `kindmind-font-b2 mx-0` : `kindmind-font-h7 mx-0`" style="width: 100%">Your Daily Activities</div>
                    </v-col>
                    <content-cards @handleclickedcard="handleclickedcard" :dailyContent="dailyContent" />
                </v-row>
            </div>
        </div>

        <!-- Daily Question Overlay -->
        <v-overlay z-index="9999" :value="dailyQuestion" v-if="dailyQuestion">
            <v-row class="d-flex align-center justify-center" :class="browsersmalldevice ? 'pa-0' : 'pa-2'">
                <v-col cols="12" xs="8" md="12" class="d-flex align-center justify-center">
                    <v-card class="elevation-0 rounded-xl white grey lighten-4 pa-2" :style="browsersmalldevice ? 'max-height: 96vh !important; overflow-y:scroll !important; padding: 0 !important;' : 'max-height: 92% !important;'">
                        <v-icon @click="dailyQuestion = !dailyQuestion" class="pt-2 primary--text d-flex justify-end px-2" style="width: 100%">icons8-cancel</v-icon>
                        <div class="primary--text text-center ma-2">
                            <div :class="smalldevice ? 'kindmind-font-b1 pa-2' : browsersmalldevice ? 'kindmind-font-cap pa-0' : 'kindmind-font-h7 px-3'">Help us tailor your Kind Mind experience. Answer your daily question</div>
                            <div class="grey--text" :class="smalldevice ? 'kindmind-font-b1-bold px-2' : browsersmalldevice ? 'kindmind-font-cap-s pa-0' : 'kindmind-font-h7-bold px-2'">{{ questions.questionName }}</div>
                        </div>
                        <!--Answers-->
                        <div class="my-5">
                            <v-form>
                                <v-row class="d-flex align-center justify-center">
                                    <!-- Ranking Questions -->
                                    <v-col cols="12" xs="12" md="8" v-if="rankorder == true" class="d-flex flex-column align-center">
                                        <drop-list :items="parseAnswers" @reorder="$event.apply(parseAnswers)" @insert="reOrderAnswer">
                                            <template v-slot:item="{ item }">
                                                <drag :key="item.text">
                                                    <v-col cols="12" xs="12" md="6">
                                                        <v-btn :value="item.text" :height="smalldevice ? 52 : 69" :width="smalldevice ? 280 : browsersmalldevice ? 120 : 340" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'" class="rounded-km white--text text-capitalize d-flex justify-start" color="primary">
                                                            <v-icon class="white--text mr-4" large>icons8-drag-reorder</v-icon>
                                                            <span>{{ item.text }}</span>
                                                            <v-icon large :class="smalldevice ? 'px-4' : 'px-8'">{{ item.icon }}</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </drag>
                                            </template>
                                            <template v-slot:feedback="{ data }">
                                                <div :key="data">{{ data }}</div>
                                            </template>
                                        </drop-list>
                                    </v-col>
                                    <!-- Sliding questions -->
                                    <v-col v-else-if="sliding" cols="12" xs="12" class="px-5" no-gutters>
                                        <div style="width: 100%" class="d-flex flex-column">
                                            <v-slider v-model="myuseranswers" track-color="primary" color="secondary" thumb-label dense :max="scaleEndScore" :min="scaleStartScore" @touchmove.prevent>
                                                <template v-slot:prepend>
                                                    <div class="secondary--text">{{ scaleStartLabel }}</div>
                                                </template>
                                                <template v-slot:append>
                                                    <div class="secondary--text">{{ scaleEndLabel }}</div>
                                                </template>
                                            </v-slider>
                                        </div>
                                    </v-col>
                                    <!-- Single and multiple answer questions-->
                                    <v-col v-else cols="12" xs="12" md="8" no-gutters>
                                        <v-btn-toggle :multiple="multiple" v-model="myuseranswers" @click="isActive == true" active-class="animate__animated animate__pulse animate__repeat-3 secondary white--text" class="grey lighten-4 px-2 d-flex flex-column align-center">
                                            <v-btn block v-for="(dailyanswer, dailyanswerindex) in parseAnswers" :key="dailyanswerindex" :value="dailyanswer" :style="browsersmalldevice ? 'max-width: 94vw !important' : 'max-width: 98vw !important'" :height="smalldevice ? 52 : browsersmalldevice ? 32 : 69" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'" class="my-1 rounded-km white--text text-capitalize d-flex justify-start" color="primary">
                                                <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-row align-center justify-start flex-wrap' : 'd-flex flex-row align-center'">
                                                    <div class="white--text text-capitalize d-flex justify-start flex-wrap" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h7' : browsersmalldevice ? 'kindmind-font-cap' : 'kindmind-font-h6'">
                                                        {{ dailyanswer.text }}
                                                    </div>
                                                    <v-icon large :class="smalldevice ? 'px-4' : 'px-8'">{{ dailyanswer.icon }}</v-icon>
                                                </div>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="8" class="px-6" no-gutters :class="browsersmalldevice ? 'pa-0' : ''">
                                        <v-btn depressed :height="smalldevice ? 55 : browsersmalldevice ? 32 : 69" text :class="browsersmalldevice ? 'kindmind-font-cap' : 'kindmind-font-b1'" class="rounded-km secondary white--text text-capitalize" block style="color: white !important" @click="sendAnswers, (dailyQuestion = false)">Done</v-btn>
                                        <!--  -->
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-overlay>

        <!-- BOTTOM MARGIN -->
        <div :style="$vuetify.breakpoint.width < 400 ? 'padding-bottom: 160px !important; opacity: 0 !important;' : 'padding-bottom: 160px !important;'"></div>

        <!-- NAV BAR -->
        <div>
            <bottom-navigation :setpadding="setPadding()" v-if="!dialog" />
        </div>

        <InstallApp :show="showInstallApp" v-on:close="showInstallApp = false" :userdeviceplatform="userdeviceplatform" />

        <!-- BOTTOM BLUR -->
        <div class="footerbanner"></div>
    </div>
</template>

<script>
import ContentCards from "@/views/dashboard/ContentCards.vue";
import InstallApp from "@/components/InstallApp.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import DropdownMenuComponent from "@/components/DropdownMenuComponent.vue";
import BarChart from "@/components/echarts/BarChart.vue";
import { Drag, DropList } from "vue-easy-dnd";
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
export default {
    name: "dashboard",
    components: {
        BottomNavigation,
        ContentCards,
        InstallApp,
        DropdownMenuComponent,
        Drag,
        DropList,
        BarChart,
        Carousel,
        Slide,
    },
    // * DATA
    data: () => ({
        loading: "",
        version: "",

        // variables for Sliding Scale questions
        scaleStartScore: 0,
        scaleEndScore: 0,
        scaleStartLabel: "",
        scaleEndLabel: "",
        sliderRange: [
            {
                label: "",
            },
            {
                label: "",
            },
        ],

        // Opening question and install dialogs
        dialog: false,
        showInstallApp: false,
        showInstallMessage: false,
        dailyQuestion: false,

        currentUser: {},
        firstName: {},
        informationtoopen: {},
        sectionformedia: "",
        android: false,

        // Insights data
        backgroundImageUrl: "",
        insightGaleryImages: {},
        insightContent: [],
        defaultinsightContent: [
            { requirementName: "default_insight_1", requirementImg: require("@/assets/default_insight_img/default_insights_1.jpg") },
            { requirementName: "default_insight_2", requirementImg: require("@/assets/default_insight_img/default_insights_2.jpg") },
            { requirementName: "default_insight_3", requirementImg: require("@/assets/default_insight_img/default_insights_3.jpg") },
        ],

        // Types of questions for daily questions
        multiple: false,
        rankorder: false,
        single: false,
        sliding: false,

        dailyContent: [],
        parseAnswers: [],
        userdeviceplatform: "",
        questions: [],

        // Types of sections for the cards
        articles: [{ cardStatus: "pause" }, { cardStatus: "fuel" }, { cardStatus: "move" }, { cardStatus: "grow" }], //,

        // Default user answers
        useranswer: { questionId: "", questionSection: "", questionAnswers: [], createdUserId: "", createdOrgId: "", questionAnswersValue: null, createdDate: null, modifiedUserId: "", modifiedOrgId: "", modifiedDate: null, deletedUserId: "", deletedOrgId: "", deletedDate: null, deleted: false },
        defaultUseranswer: {
            questionId: "",
            questionSection: "",
            questionInsights: "",
            questionAnswers: [],
            createdUserId: "",
            createdOrgId: "",
            createdDate: null,
            modifiedUserId: "",
            modifiedOrgId: "",
            modifiedDate: null,
            deletedUserId: "",
            deletedOrgId: "",
            deletedDate: null,
            deleted: false,
        },
        myuseranswers: [],

        // Default user preferences
        searchQuery: "",
    }),
    //*METHODS
    methods: {
        handleCloseWindow() {
            console.log("close");
            this.dialog = false;
        },
        //gets answers from redis for the polls once they've been answered
        async getAnswers(questionId) {
            let t = this;
            let searchQuery = `@questionId:{${questionId}} @deleted:{false}`;
            let contentResult = await this.REDIS_searchFor("useranswer", null, null, null, null, searchQuery);
            t.allPollAnswers = contentResult.data.documents;
            this.sortDataForChart();
        },
        // sorts the data for the chart in preparation for the user answers to the charts
        sortDataForChart() {
            let t = this;
            let stats = [];
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.questionAnswers] = (acc[obj.questionAnswers] || 0) + 1;
                return acc;
            }, {});
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map((k) => ({
                name: k,
                value: objResult[k],
            }));
            //* Assign key value pairs to graph structure
            arrResult.forEach((stat) => {
                stats.push({
                    name: stat.name,
                    value: stat.value,
                });
            });
            return stats;
        },
        // Computed Search Query for dashboard content
        async computedSearchQuery(section) {
            // Get user's preferences
            let userSearchQuery = `@deleted:{false} @createdUserId:{${this.GET_FIREBASE_userAuth.uid}} @questionSection:{${section}}`;
            let contentResult = await this.REDIS_searchFor("userpreference", null, null, "createdDate", "ASC", userSearchQuery);
            let userContentFilter = contentResult.data.documents;

            // Slices the array to only get the most recent preferences
            const preferencesToKeep = {
                pause: 2,
                move: 2,
                fuel: 1,
                grow: 1,
            };
            const numPreferencesToKeep = preferencesToKeep[section];
            if (numPreferencesToKeep) {
                userContentFilter = userContentFilter.slice(-numPreferencesToKeep);
            }

            // Loop through user's preferences
            for (let i = 0; i < userContentFilter.length; i++) {
                // PAUSE
                if (userContentFilter[i].questionSection === "pause") {
                    // PAUSE - Time of Day
                    if (userContentFilter[i].preferenceField === "Time of Day") {
                        this.searchQuery += ` @pauseTime:{${userContentFilter[i].preferenceAnswer}}`;
                    }

                    // PAUSE - Duration - Converts duration to a number braket that redis con work with
                    if (userContentFilter[i].preferenceField == "Duration") {
                        if (userContentFilter[i].preferenceAnswer[0] === "0 - 10 minutes") {
                            this.searchQuery += ` @pauseDurationMax:[0 10]`;
                        } else if (userContentFilter[i].preferenceAnswer[0] === "10 - 20 minutes") {
                            this.searchQuery += ` @pauseDurationMax:[10 20]`;
                        } else if (userContentFilter[i].preferenceAnswer[0] === "20 - 30 minutes") {
                            this.searchQuery += ` @pauseDurationMax:[20 30]`;
                        }
                    }
                }

                // MOVE
                if (section === "move") {
                    // MOVE - Favourite Activity
                    if (userContentFilter[i].preferenceField === "Move Type") {
                        this.searchQuery += ` @moveType:{${userContentFilter[i].preferenceAnswer[0]}}`;
                    }

                    // MOVE - Duration
                    if (userContentFilter[i].preferenceField == "Duration") {
                        if (userContentFilter[i].preferenceAnswer[0] === "0 - 10 Minutes") {
                            this.searchQuery += ` @moveDurationMax:[0 10]`;
                        } else if (userContentFilter[i].preferenceAnswer[0] === "10 - 20 Minutes") {
                            this.searchQuery += ` @moveDurationMax:[10 20]`;
                        } else if (userContentFilter[i].preferenceAnswer[0] === "20 - 30 Minutes") {
                            this.searchQuery += ` @moveDurationMax:[20 30]`;
                        } else if (userContentFilter[i].preferenceAnswer[0] === "+30 Minutes") {
                            this.searchQuery += ` @moveDurationMax:[30 120]`;
                        }
                    }
                }

                // FUEL - Favourite Features
                if (section === "fuel") {
                    const preferenceField = userContentFilter[i].preferenceField;
                    const preferenceAnswer = userContentFilter[i].preferenceAnswer[0];
                    if (preferenceField === "Fuel Features") {
                        this.searchQuery += `@fuelFeatures:{${preferenceAnswer}}`;
                    }
                }

                // Grow
                if (section === "grow") {
                    const preferenceField = userContentFilter[i].preferenceField;
                    const preferenceAnswer = userContentFilter[i].preferenceAnswer[0];
                    // GROW - Favourite Activity
                    if (preferenceField === "Grow Focus") {
                        this.searchQuery += ` @growTopic:{${preferenceAnswer}}`;
                    }
                }
            }

            return this.searchQuery;
        },
        // listens from app bar and opens the Install overlay when requested
        InstallAppTrue() {
            this.showInstallApp = true;
        },
        /**
         *  Content Cards: Pulls content from redis searching by the collections that have been set in the local storage. Parameter: section which filters the content fetched depending on the section
         * */
        async readContenId() {
            for (let article in this.articles) {
                let collection = this.articles[article].cardStatus;
                let entityId = localStorage.getItem(collection);
                let contentResult = await this.REDIS_read(collection, entityId);
                this.dailyContent.push(contentResult.data);
            }
        },
        // Closes install message in dashboard and sets localStorage to cancel so the message doesn't show again
        cancelInstallMessage() {
            this.showInstallMessage = !this.showInstallMessage;
            localStorage.setItem("hideInstallPrompt", true);
        },
        // Maked the drag and drop function
        reOrderAnswer(event) {
            this.parseAnswers.splice(event.index, 0, event.data);
        },
        // Sets padding depending on viewport width
        setPadding() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 382:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 381 && this.$vuetify.breakpoint.width < 400:
                    return "px-5";
                case this.$vuetify.breakpoint.width > 399 && this.$vuetify.breakpoint.width < 700:
                    return "px-6";
                case this.$vuetify.breakpoint.width > 700 && this.$vuetify.breakpoint.width < 1000:
                    return "px-16";
                case this.$vuetify.breakpoint.width > 1000:
                    return "px-9";
                default:
                    return "";
            }
        },
        // Sets margin depending on viewport width
        setMargin() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 382:
                    return "mx-5";
                case this.$vuetify.breakpoint.width > 381 && this.$vuetify.breakpoint.width < 400:
                    return "mx-5";
                case this.$vuetify.breakpoint.width > 399 && this.$vuetify.breakpoint.width < 700:
                    return "mx-6";
                case this.$vuetify.breakpoint.width > 700 && this.$vuetify.breakpoint.width < 1000:
                    return "mx-16";
                case this.$vuetify.breakpoint.width > 1000:
                    return "mx-6";
                default:
                    return "";
            }
        },
        // Retrives userName and cuts it so it only returns the first name
        async firstNamefunc() {
            let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currentUser = currUserResult.data;
            let userName = this.currentUser.userName;
            let indexOfSpace = userName.indexOf(" ");
            if (indexOfSpace === -1) {
                this.firstName = userName;
            } else {
                this.firstName = userName.substring(0, indexOfSpace);
            }
        },
        /**
         *  Pulls content from redis for the Insights. Paremeter: section which filters the content fetched depending on the section
         * */
        async getContent(section) {
            let filteredContentTopic = [];
            let filteredContentFeeling = [];
            let filteredContentSleep = [];
            let filteredContentEnergy = [];
            let answerTopic = [];
            let answerFeeling = [];
            let insightsFinalContent = [];
            let answerSleep = "";
            let answerEnergy = "";
            let insightContent = [];

            // Gets content from redis for all the insights
            let contentResult = await this.REDIS_searchFor(`${section}`, null, null, null, `${section}Title`, "@deleted:{false}");
            insightContent = contentResult.data.documents;

            // Gets the latest answers for the Insight questions
            let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
            let insightAnswersDate = localStorage.getItem("insightAnswers");

            let searchQuery = `@createdUserId:{${this.GET_FIREBASE_userAuth.uid}} @questionSection:{Insights} @deleted:{false}`;
            let answersResult = await this.REDIS_searchFor("useranswer", null, null, "createdDate", "ASC", searchQuery);
            let insightsAnswers = answersResult.data.documents;

            // Checks if the answers are from today and if there are any answers if not it shows an empty string
            if (insightAnswersDate != currentDate || insightsAnswers.length === 0) {
                this.insightContent = this.defaultinsightContent;
                return;
            }

            // Filters the answers to get the information for filtering the content
            for (let i = 0; i < insightsAnswers.length; i++) {
                if (insightsAnswers[i].questionDaily === "Topic") {
                    answerTopic = insightsAnswers[i].questionAnswers;
                }
                if (insightsAnswers[i].questionDaily === "Feeling") {
                    answerFeeling = insightsAnswers[i].questionAnswers;
                }
                if (insightsAnswers[i].questionDaily === "Sleep") {
                    answerSleep = insightsAnswers[i].questionAnswers;
                }
                if (insightsAnswers[i].questionDaily === "Energy") {
                    answerEnergy = insightsAnswers[i].questionAnswers;
                }
            }

            // Filters the content based on the answers
            filteredContentTopic = insightContent.filter((item) => answerTopic.some((topic) => item.insightTopics.includes(topic)));
            if (filteredContentTopic.length > 3) {
                filteredContentFeeling = filteredContentTopic.filter((item) => answerFeeling.some((feeling) => item.insightFeelings.includes(feeling)));
                if (filteredContentFeeling.length > 3) {
                    filteredContentSleep = filteredContentFeeling.filter((item) => item.insightSleep.includes(answerSleep[0]));
                    if (filteredContentSleep.length > 3) {
                        filteredContentEnergy = filteredContentSleep.filter((item) => item.insightEnergy.includes(answerEnergy[0]));
                    }
                }
            }

            switch (true) {
                // checks that we have enough content to show for TOPICS
                case filteredContentTopic.length >= 3 && filteredContentFeeling.length < 3:
                    insightsFinalContent = filteredContentTopic;
                    break;
                // checks that we have enough content to show for FEELINGS
                case filteredContentFeeling.length >= 3 && filteredContentSleep.length < 3:
                    insightsFinalContent = filteredContentFeeling;
                    break;
                // checks that we have enough content to show for SLEEP
                case filteredContentSleep.length >= 3 && filteredContentEnergy.length < 3:
                    insightsFinalContent = filteredContentSleep;
                    break;
                // checks that we have enough content to show for ENERGY
                case filteredContentEnergy.length >= 3:
                    insightsFinalContent = filteredContentEnergy;
                    break;
                default:
                    insightsFinalContent = [];
                    break;
            }

            // Final content to show randomized and limited to 3
            insightContent = [];
            let availableIndices = [...Array(insightsFinalContent.length).keys()];
            for (let i = 0; i < 3; i++) {
                let randomIndex = Math.floor(Math.random() * availableIndices.length);
                let selectedIndex = availableIndices[randomIndex];
                insightContent.push(insightsFinalContent[selectedIndex]);
                availableIndices.splice(randomIndex, 1);
            }

            // Sets the content in local storage to be used in the insights page
            let insightsArray = ["insight1", "insight2", "insight3"];
            let newId = localStorage.getItem("showNewInsights");
            if (newId === "true") {
                for (let i = 0; i < insightsArray.length; i++) {
                    let insightsArr = insightsArray[i];
                    localStorage.setItem(`${insightsArr}-id`, insightContent[i].entityId);
                }
            }

            localStorage.setItem("showNewInsights", false);
            this.readInsightId();
        },
        /**
         *  Insights: Pulls content from redis seqarching by the collections that have been set in the local storage. Parameter: section which filters the content fetched depending on the section
         * */
        async readInsightId() {
            let insightsArray = ["insight1-id", "insight2-id", "insight3-id"];
            this.insightContent = [];

            for (let i = 0; i < insightsArray.length; i++) {
                let entityId = localStorage.getItem(`${insightsArray[i]}`);
                let contentResult = await this.REDIS_read("insight", entityId);
                this.insightContent.push(contentResult.data);
            }
        },
        // Opens Media view and sends the information from the selected card
        handleclickedcard(information, section) {
            localStorage.setItem(`${section}-viewed`, "true");
            this.informationtoopen = information;
            this.sectionformedia = section;
            this.MIX_go({ name: "media-page", params: { data: information, section: section, id: `${information.entityId}` } });
        },
        // Opens the dialogue with the insights in it
        async openInsight(item) {
            if (item.insightPortraitImageId.length > 0) {
                this.insightGaleryImages = {};

                this.insightGaleryImages = item.insightPortraitImageId;

                // Gets Large Viewport Insights background
                let backgroundId = this.insightGaleryImages[0];
                this.backgroundImageUrl = `${this.MIX_imageURL + "images/portrait/" + backgroundId + ".jpeg?"}`;
                this.dialog = !this.dialog;
            }
        },

        /* Pulls the questions from redis
         * params: section => this defines which of the questions are being called
         * returns:  All the questions from that section and the total number of questions
         */
        async getQuestions() {
            // checks the date so the pop up opens once per day
            let sections = ["pause", "fuel", "move", "grow"];

            // let currentDate = new Date();
            let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
            let localStorageDate = localStorage.getItem("date");
            // let localStorageFirstLogin = localStorage.getItem("firstLogin");

            // if (currentDate == localStorageDate || localStorageFirstLogin === "true") {
            if (currentDate == localStorageDate) {
                return (this.dailyQuestion = false);
            }
            // Set the viewed items to false
            for (let i = 0; i < sections.length; i++) {
                let section = sections[i];
                localStorage.setItem(`${section}-viewed`, "false");
                // localStorage.setItem("firstLogin", false);
            }
            this.dailyQuestion = true;
            this.questions = [];

            // Searches for questions in the database & replace spaces in the search query with \\
            let questionsResult = await this.REDIS_searchFor("question", null, null, "questionOrder", "ASC", `@questionSection:{Daily} @deleted:{false} @enabled:{true}`);
            this.questions = questionsResult.data.documents;
            let questionTotal = questionsResult.data.total;
            let randomNumber = Math.floor(Math.random() * questionTotal);
            this.questions = this.questions[randomNumber];
            this.parseAnswers = JSON.parse(this.questions.questionAnswers);
            if (this.questions.questionAnswerType == "Multiple Choice") {
                this.multiple = true;
            } else if (this.questions.questionAnswerType == "Rank Order") {
                this.rankorder = true;
            } else if (this.questions.questionAnswerType == "Single Choice") {
                this.single = true;
            } else if (this.questions.questionAnswerType == "Sliding Scale") {
                this.sliding = true;
                this.scaleStartScore = this.parseAnswers.scaleStart[0].score;
                this.scaleEndScore = this.parseAnswers.scaleEnd[0].score;
                this.scaleStartLabel = this.parseAnswers.scaleStart[0].label;
                this.scaleEndLabel = this.parseAnswers.scaleEnd[0].label;
            }
        },
        // Sends answers to redis and clears useranswernew Date()
        async sendAnswers() {
            if (this.sliding == true) {
                this.useranswer.questionAnswers.push(this.myuseranswers);
                this.useranswer.questionAnswersValue = this.myuseranswers;
            }
            if (this.myuseranswers !== undefined && this.myuseranswers !== [] && this.myuseranswers.text !== undefined) {
                if (this.rankorder == true) {
                    this.parseAnswers = JSON.stringify(this.parseAnswers);
                    this.myuseranswers.push(this.parseAnswers);
                    this.useranswer.questionAnswers = this.myuseranswers;
                } else if (this.single == true) {
                    this.useranswer.questionAnswers.push(this.myuseranswers.text);
                    this.useranswer.questionAnswersValue = this.myuseranswers.score;
                } else {
                    this.useranswer.questionAnswers.push(this.myuseranswers.text);
                    this.useranswer.questionAnswersValue = this.myuseranswers.score;
                }
            }
            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));

            this.useranswer.questionId = this.questions.entityId;
            this.useranswer.questionSection = this.questions.questionSection.replace(/\s/g, "\\ ").toLowerCase();
            this.useranswer.createdUserId = this.currentUser.createdUserId;

            this.useranswer.createdOrgId = this.currentUser.userOrgId;
            this.useranswer.createdDate = currentDateTime;
            this.useranswer.questionCore = this.questions.questionCore;
            this.useranswer.questionSubTopic = this.questions.questionSubTopic;
            await this.REDIS_create("useranswer", this.useranswer);
            this.useranswer = { ...this.defaultUseranswer };
        },
        // Sets width of the insights slide group
        slideGroupSetWidth() {
            switch (true) {
                case this.$vuetify.breakpoint.width < 700:
                    return "width: 100% !important; margin-left: auto !important; margin-right: auto !important;";
                case this.$vuetify.breakpoint.width > 700 && this.$vuetify.breakpoint.width < 1000:
                    return "width: 100% !important; margin-left: auto !important; margin-right: auto !important;";
                case this.$vuetify.breakpoint.width > 1000:
                    return "margin-left: auto !important; margin-right: auto !important;";
                default:
                    return "";
            }
        },
        /**
         *  Pulls content from redis and makes it random. Sets local Storage with today's date and IDs
         * */
        async getPreferencesContent() {
            // let currentDate = new Date();
            let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
            let localStorageDate = localStorage.getItem("date");

            // Checks if the date is the same as the one in local storage
            if (currentDate != localStorageDate) {
                for (let i = 0; i < this.articles.length; i++) {
                    this.searchQuery = `@deleted:{false} @enabled:{true}`;
                    let section = this.articles[i].cardStatus;

                    // Calls the function that computes the search query
                    await this.computedSearchQuery(section);
                    this.searchQuery += ` @${section}Status:{Live}`;
                    let contentResult = await this.REDIS_searchFor(`${section}`, null, null, null, `${section}Title`, `${this.searchQuery}`);
                    this.content = contentResult.data.documents;
                    if (this.content.length == 0) {
                        let contentResult = await this.REDIS_searchFor(`${section}`, null, null, null, `${section}Title`, `@deleted:{false} @enabled:{true} @${section}Status:{Live}`);
                        this.content = contentResult.data.documents;
                        // console.log(this.content);
                        if (this.content.length == 0) {
                            localStorage.setItem(`${section}`, "No Content");
                        } else {
                            let randomNumber = Math.floor(Math.random() * this.content.length);
                            this.content = contentResult.data.documents[randomNumber];
                            localStorage.setItem(`${section}`, this.content.entityId);
                        }
                    } else {
                        let randomNumber = Math.floor(Math.random() * this.content.length);
                        this.content = contentResult.data.documents[randomNumber];
                        localStorage.setItem(`${section}`, this.content.entityId);
                    }
                    // Gets today's date and saves it in the localStorage
                    // localStorage.setItem("date", currentDate);
                }
            }
            this.readContenId();
            return;
        },
    },
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        // Checks the size of viewport
        smalldevice() {
            return window.innerWidth < 700;
        },
        browsersmalldevice() {
            return window.innerHeight < 528;
        },

        //Checks for the version
        computedVersion() {
            const version = require("../../../package.json").version;
            return version;
        },
        computedBASEURL() {
            return process.env.BASE_URL;
        },
        computedNODEENV() {
            return process.env.NODE_ENV;
        },
        // Formats the text to say what time of the day it is
        welcomeUser() {
            let currentTime = new Date().toJSON().slice(11, 13);
            switch (true) {
                case currentTime < 12:
                    return "Good Morning";
                case currentTime >= 12 && currentTime < 18:
                    return "Good Afternoon";
                case currentTime >= 18:
                    return "Good Evening";
                default:
                    return "";
            }
        },
    },
    // * MOUNTED
    mounted() {
        // Gets today's date and saves it in the localStorage
        let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
        // let currentDate = new Date();
        localStorage.setItem("date", currentDate);

        // let cancelInstallMessage = localStorage.getItem("Install Message");
        const t = this;
        t.userdeviceplatform = "";

        // iOS user agents
        const iOSUserAgents = ["iPhone", "iPad", "iPod"];

        // Android
        if (navigator.userAgent.includes("Android")) {
            // console.log("This is an Android device");
            t.userdeviceplatform = "android";
        }

        // iOS
        else if (iOSUserAgents.some((userAgent) => navigator.userAgent.includes(userAgent))) {
            // console.log("This is an iOS device");
            t.userdeviceplatform = "ios";
        }

        // Web
        else if (navigator.userAgent.includes("Windows") || navigator.userAgent.includes("Macintosh")) {
            t.userdeviceplatform = "desktop";
        } else {
            // console.log("This is not an Android, iOS, or desktop device");
        }
        // Detect if the user is using an Apple device and has opened the app from the home page
        // Check if the device is an iOS device and if it's not in standalone mode
        // (standalone mode is when the app is added to the home screen)
        if (!t.MIX_getFromLocalStorage("hideInstallPrompt")) {
            if (iOSUserAgents.some((userAgent) => navigator.userAgent.includes(userAgent)) && !navigator.standalone) {
                // Set the showInstallMessage property to true
                // This will display a message prompting the user to add the app to their home screen
                t.showInstallMessage = true;
            } else if (t.userdeviceplatform.includes("Android") && !navigator.standalone) {
                // Set the showInstallMessage property to true
                // This will display a message prompting the user to add the app to their home screen
                t.showInstallMessage = true;
            }
        }
    },

    // * CREATED
    async created() {
        this.loading = true;
        this.firstNamefunc();
        this.getContent("insight");
        this.getQuestions();
        this.getPreferencesContent();

        this.loading = false;
    },
};
</script>

<style>
#dashboard-slideGroup-arrows > .v-slide-group__next,
.v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-top: 22px;
}

#dashboard-slideGroup-arrows > .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 0px;
}

#dashboard-slideGroup-arrows > .v-slide-group__next {
    flex: 0 1 auto;
    min-width: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-bottom: 38px;
}

#large-viewport-carousel-image > .v-carousel .v-window-item {
    max-width: auto !important;
    max-height: 100% !important;
    text-decoration: none;
    opacity: 0.6;
}

#large-viewport-carousel-image {
    max-width: auto !important;
    max-height: 100vh !important;
    background-size: contain !important;
}

#dashboardInsights-carousel.v-carousel > .v-carousel__controls {
    position: absolute !important;
    top: 8vh !important;
    /* background-color: #000; */
}

#dashboardInsights-carousel .v-carousel__controls .v-carousel__controls__item {
    height: 42px;
    width: 38px;
    margin-top: 8vh;
    margin: 22px 4px;
    border-radius: 50%;
    color: white;
}

.insights-lviewports-backgroundImg {
    background-size: cover;
    background-position: center center;
    height: 100vh;
    z-index: -1 !important;
    width: 100vw;
    filter: blur(4px) !important;
    /* -webkit-filter: blur(8px);  */
}
</style>

<style scoped>
.background-image {
    /* background-image: url('@/assets/explore_img/grow.png'); */
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: blur(4px) !important;
}

#dashboard-slideGroup-arrows,
::before,
:focus {
    background-color: white !important;
    outline: none !important;
}
.v-icon::before {
    background-color: transparent !important;
    outline: none !important;
}
</style>
<style lang="scss">
* {
    box-shadow: none !important;
}

.allowscrolling {
    overflow-x: hidden;
    overflow-y: auto !important;
}

html::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.dashboard-contentDaily-desktop {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-content: space-between !important;
}

.rounded-km-top {
    border-radius: 20px 20px 0px 0px !important;
}

.dashboard-contentDaily-mobile {
    display: flex;
    flex-direction: column;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100% !important;
    position: absolute !important;
    height: 116px !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
