<template>
    <div class="fullviewpoint allowscrolling primary">
        <!-- Loading circle - renders when loading is true -->
        <v-overlay :value="loading">
            <v-progress-circular :size="100" :width="5" color="white" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- Today page for users to load their feelings daily. Their answers will be used to filter the content they see in the daily insights -->
        <div class="d-flex align-end justify-end px-6 pt-6">
            <v-icon v-show="!loading" @click="$router.push('/dashboard')" class="white--text">icons8-cancel</v-icon>
        </div>
        <span :class="$vuetify.breakpoint.xsOnly ? 'sidescroll' : 'sidescroll-desktop'"></span>
        <div class="font-weight-light text-center white--text mb-4" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h5-5 pb-5' : 'kindmind-title-font-h3-4 pb-4'">Log your day</div>
        <!-- Questions -->
        <v-row class="d-flex" style="min-height: 80%">
            <v-col cols="12" xs="12" v-for="question in questions" :key="question.entityId" style="position: static; z-index: auto !important" class="p4-0 my-0 ml-4">
                <div :value="question.questionName" class="text-start white--text" :class="$vuetify.breakpoint.height < 690 ? 'kindmind-font-h7' : $vuetify.breakpoint.height > 660 && $vuetify.breakpoint.height < 900 ? 'kindmind-font-h6' : 'kindmind-font-h5'">{{ question.questionName }}</div>

                <!-- ANSWER BUTTONS -->

                <!-- Multiple Choice -->
                <v-layout v-if="question.questionAnswerType == 'Multiple Choice'" row class="overflow-x-auto mr-8 mb-4 mt-2">
                    <div class="d-flex" v-for="answer in JSON.parse(question.questionAnswers)" :key="answer.text" active-class="primary rounded-km">
                        <v-btn @click="toggleAnswer(question.entityId, answer.text)" :color="isSelected(question.entityId, answer.text) ? 'secondary' : 'white'" class="mx-2 mt-2 rounded-km" :class="$vuetify.breakpoint.xsOnly ? 'mobileView' : 'desktopView'">
                          <div class="d-flex flex-column">
                            <v-icon :color="isSelected(question.entityId, answer.text) ? 'white' : 'secondary'" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h3' : 'kindmind-font-h2'">{{ answer.icon }}</v-icon>
                            <span class="text-capitalize text-center pa-0 ma-0" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-cap' : 'kindmind-font-b2'">{{ answer.text }}</span>
                       </div> </v-btn>
                    </div>
                </v-layout>

                <!-- Single Choice -->
                <v-layout v-else row class="overflow-x-auto mr-8 mb-4 mt-2">
                    <div class="d-flex flex-row" v-for="(answer, answerIndex) in JSON.parse(question.questionAnswers)" :key="answerIndex" active-class="rounded-km">
                        <v-btn @click="updateAnswers(question.entityId, answer.text)" :color="isSelected(question.entityId, answer.text) ? 'secondary' : 'white'" class="mx-2 mt-2 rounded-km d-flex flex-column align-center justify-center" :class="$vuetify.breakpoint.xsOnly ? 'mobileView' : 'desktopView'">
                           <div class="d-flex flex-column">
                            <v-icon :color="isSelected(question.entityId, answer.text) ? 'white' : 'secondary'" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h3' : 'kindmind-font-h2'">{{ answer.icon }}</v-icon>
                            <v-card-text class="text-capitalize text-center pa-0 ma-0" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-cap' : 'kindmind-font-b2'">{{ answer.text }}</v-card-text>
                        </div>
                        </v-btn>
                    </div>
                </v-layout>
            </v-col>
        </v-row>
        <!-- BOTTOM NAVIGATION -->
        <div block class="bottomNavStyle pb-6 d-flex align-center" :class="$vuetify.breakpoint.xsOnly ? 'pt-4' : ''" style="width:100% !important;">
            <div class="d-flex" style="width:100% !important;">
            <!-- <v-btn v-show="!loading" @click="$router.push('/dashboard')" :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'white--text kindmind-font-h7-bold'" text class="white--text text-capitalize font-weight-medium"><v-icon class="mr-2">icons8-back-to</v-icon>Back </v-btn> -->
            <v-spacer />
            <v-btn v-show="!loading" text :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'white--text kindmind-font-h7-bold'" class="white--text text-capitalize font-weight-medium" @click="handleAnswers">Submit <v-icon class="ml-2">icons8-next-page</v-icon></v-btn>
        </div>
        </div>
        <br />
        <br />
        <div style="padding-bottom: 128px;"></div>

        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />

        <!-- Success screen when answers are sent -->
        <v-overlay :value="overlay" class="d-flex justify-center" opacity="1" color="primary">
            <div :class="$vuetify.breakpoint.xsOnly ? 'white--text kindmind-title-font-h5-bold' : 'white--text kindmind-title-font-h3-bold'">Thank you for your answers</div>
        </v-overlay>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "today-screen",
    // * DATA
    data: () => ({
        questions: [],
        selectedAnswers: {},
        currentUserData: [],
        defaultUseranswer: {
            questionId: "",
            questionSection: "",
            questionInsights: "",
            questionAnswers: [],
            createdUserId: "",
            createdOrgId: "",
            questionDaily: "", // * Added
            createdDate: "",
            modifiedUserId: "",
            modifiedOrgId: "",
            modifiedDate: "",
            deletedUserId: "",
            deletedOrgId: "",
            deletedDate: "",
            deleted: false,
        },
        useranswer: { questionId: "", questionDaily: "", questionSection: "", questionInsights: "", questionAnswers: [], createdUserId: "", createdOrgId: "", createdDate: "", modifiedUserId: "", modifiedOrgId: "", modifiedDate: "", deletedUserId: "", deletedOrgId: "", deletedDate: '', deleted: false },
        loading: "",
        overlay: false,
        multiple: false,
        answerCounter: 0,
        myanswers: [],
        alertData: { title: "", message: "", color: "", timeout: "2000", show: false },
    }),
    // * METHODS
    methods: {
        toggleAnswer(questionId, answerId) {
            // Toggle answer selection status
            if (!this.selectedAnswers[questionId]) {
                this.$set(this.selectedAnswers, questionId, []);
            }
            const selectedAnswers = this.selectedAnswers[questionId];
            const index = selectedAnswers.indexOf(answerId);

            if (index === -1) {
                selectedAnswers.push(answerId);
            } else {
                selectedAnswers.splice(index, 1);
            }
        },
        // Checks if the answer is selected
        isSelected(questionId, answerId) {
            // Check if answer is selected for a given question
            if (!this.selectedAnswers[questionId]) {
                return false;
            }
            return this.selectedAnswers[questionId].indexOf(answerId) !== -1;
  
        },
        // Links the chosen answer to the question it's linked to for Single answer questions
        updateAnswers(questionId, answerId) {
            // Toggle answer selection status
            if (!this.selectedAnswers[questionId]) {
                this.$set(this.selectedAnswers, questionId, []);
            } 
                
            const selectedAnswers = this.selectedAnswers[questionId];
            const index = selectedAnswers.indexOf(answerId);

            if (index === -1 && selectedAnswers.length < 1 ) {

                selectedAnswers.push(answerId);
            } else {
                this.selectedAnswers[questionId] = [];
                this.selectedAnswers[questionId].push(answerId);
            }
        },
        // handleAnswers copies the needed information from questions and the user answer and calls sendAnswers
        async handleAnswers() {
            let keys= Object.keys(this.selectedAnswers);
            // console.log("keys", keys);
            if (keys.length > 0) {
            let id = ""
            for (let i = 0; i < keys.length; i++) {
                // Get Current User Data
                // Get Current Date Time
                let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                this.useranswer.questionId = keys[i];
                this.useranswer.questionSection = "Insights";

                // Loops through the selected answers and pushes them to the useranswer object
                let answer = this.selectedAnswers[keys[i]];
                this.useranswer.questionAnswers = [];
                 for (let j = 0; j < answer.length; j++) {
                this.useranswer.questionAnswers.push(answer[j]);
                 }
            
                // Type of Insight Question
                id = this.questions.filter((question) => keys[i] == question.entityId);
                this.useranswer.questionDaily = id[0].questionInsights;
                
                // Standard User Data
                    this.useranswer.createdUserId = this.currentUserData.createdUserId;
                    this.useranswer.createdOrgId = this.currentUserData.userOrgId;
                    this.useranswer.createdDate = currentDateTime,
                    this.useranswer.modifiedUserId = this.currentUserData.modifiedUserId;
                    this.useranswer.modifiedOrgId = this.currentUserData.userOrgId;
                    this.useranswer.modifiedDate = currentDateTime;
                    this.overlay = true;
                    await this.sendAnswers()
                    this.useranswer.questionAnswers = [];
            }
            } else {
                // console.log("No answers selected")
                this.alertData = { title: "Error", message: "No answers selected", color: "warning", timeout: 2000, show: true };
            }
        },
        // Sends answers to redis and clears useranswer
        async sendAnswers() {
             // Gets today's date and saves it in the localStorage for today's questions
            await this.REDIS_create("useranswer", this.useranswer);
            this.useranswer = { ...this.defaultUseranswer };
            let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
            localStorage.setItem("insightAnswers", currentDate);
            localStorage.setItem("showNewInsights", true);
            
        },
    },
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        typeOfQuestion() {
            for (let i = 0; i <= this.questions.length; i++) {
                if (this.questions[i].questionAnswerType == "Multiple Choice") {
                    this.multiple = true;
                }
            }
        },
    },
    // * WATCH
    watch: {
        // This sets the timer for the successful answer sent overlay and redirects users to their dashboards
        overlay(val) {
            val &&
                setTimeout(() => {
                    this.MIX_go("/dashboard");
                    // this.overlay = false;
                }, 1000);
        },
    },
    // * CREATED
    async created() {
        this.loading = true;
        // Searches for questions in redis with the given filters
        let questionsResult = await this.REDIS_searchFor("question", null, null, "questionOrder", "ASC", "@questionSection:{Insights} @deleted:{false} @enabled:{true}");
        this.questions = questionsResult.data.documents;
        // Fetches current user information
        let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
        this.currentUserData = currUserResult.data;
        this.loading = false;
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.answersStyle {
    /*  display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    margin-right: 6vw;
    overflow: auto;*/
    display: flex !important;
    overflow: auto !important;
    /* gap: 3vw !important; */
    gap: clamp(10px, 3vw, 20px) !important;
    margin-right: 6vw;
}

.answersStyle-desktop {
    display: flex !important;
    overflow: auto !important;
    gap: clamp(10px, 3vw, 20px) !important;
    margin-right: 3vw;
}

html::-webkit-scrollbar {
    width: 0;
    height: 0;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sidescroll {
    z-index: 1 !important;
    right: 0px !important;
    width: 7vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 100vh !important;
    background: -moz-linear-gradient(left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.sidescroll-desktop {
    z-index: 1 !important;
    right: 0px !important;
    width: 8vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 100vh !important;
    background: -moz-linear-gradient(left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, var(--v-primary-base) 30%, rgba(127, 195, 216, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.mobileView {
    /* height: 100% !important;
    width: 100% !important; */
    height: clamp(104px, 16vh, 98px) !important;
    width: clamp(104px, 16vw, 132px) !important;
}

.desktopView {
    /* height: 100% !important;
    width: 100% !important; */
    height: clamp(30px, 22vh, 120px) !important;
    width: clamp(30px, 22vw, 120px) !important;
}

.rounded-km {
    border-radius: 20px 20px 20px 20px !important;
}
.bottomNavStyle {
    z-index: 99 !important;
    /* width: 100% !important; */
    position: relative !important;
    bottom: 0 !important;
}
</style>
