<template>
    <div>
        <page-loading v-if="loading" />
        <div v-if="sectionFilters && !computedInfoIsLoading()">
            <!--  NEW CAROUSEL 2.0 -->
            <v-row :max-width="$vuetify.breakpoint.width" v-for="(section, sectionIndex) in inPageSections" :key="sectionIndex">
                <v-col cols="12" xs="12" v-if="section.content.length > 0">
                    <div :class="$vuetify.breakpoint.smAndDown ? `kindmind-DMSans-font-h5 pt-1 mb-4` : `kindmind-DMSans-font-h4 mb-6`">{{ section.type }}</div>
                    <v-layout row class="overflow-x-auto overflow-y-hidden">
                        <!-- If there are no items in the carousel, render a message -->
                        <!-- <div v-if="section.content.length == 0" class="d-flex align-center justify-center">
                            <div class="grey--text kindmind-font-h6 mx-3">This category is empty for now</div>
                        </div> -->

                        <!-- If there are items in the carousel, render them -->
                        <div class="d-flex flex-row" v-for="(item, itemIndex) in section.content" :key="itemIndex">
                            <v-card flat class="mx-3 d-flex flex-column align-center" @click="redirectToContent(itemIndex, section.content)" :height="202" width="148">
                                <!-- Render the image -->
                                <img v-if="item[`${chosenSection}` + 'ThumbnailImageId'] !== ''" :src="MIX_imageURL + ('images/thumbnails/' + item[`${chosenSection}` + 'ThumbnailImageId'] + '.jpeg?')" alt="Image from media" class="rounded-km start" style="width: 100%; min-height: 144px !important; height: 144px !important;" contain />

                                <!--If an image isn't present, render an icon-->
                                <div v-else style="width: 100%; min-height: 144px !important; height: 144px !important;" :color="`${chosenSection}`" :class="`${chosenSection} rounded-km d-flex align-center justify-center`"><v-icon color="white" size="82" class="icons8-picture"> </v-icon></div>

                                <!-- Card Title -->
                                <v-card-subtitle class="py-0" :class="$vuetify.breakpoint.smAndUp ? 'kindmind-font-b2 text-center' : 'text-center kindmind-font-cap'">{{ item[`${chosenSection}` + "Title"] }}</v-card-subtitle>
                                <!-- :style="$vuetify.breakpoint.smOnly ? 'width: 16vw !important; height: 8vh !important;' : $vuetify.breakpoint.xsOnly ? 'width: 24vw !important; height: 6vh !important;' : $vuetify.breakpoint.mdAndUp ? 'width: 8vw !important; height: 12vh !important;' : 'height: 28vh !important;'"  -->
                            </v-card>
                        </div>
                    </v-layout>
                </v-col>
            </v-row>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly">
            <br />
        </div>
    </div>
</template>

<script>
import pageLoading from "./pageLoading.vue";
export default {
    components: { pageLoading },
    name: "activity-buttons-component",
    props: {
        inPageSections: {
            type: Array,
            default: () => [],
        },
        datadown: {
            type: Array,
            default: () => [],
        },
        chosenSection: {
            type: String,
            default: null,
        },
    },

    // * DATA
    data: () => ({
        content: [],

        // loading
        loading: true,
        isFilterDataLoaded: false,
        images: [],
    }),

    // * METHODS
    methods: {
        // initialise passes the information to the child component
        initialise() {
            this.content = this.datadown;
        },
        //
        redirectToContent(itemIndex, section) {
            let content = section[itemIndex];
            this.$emit("selectedcontent", content, this.chosenSection);
        },
        /**
         * Computed Is Page Loading
         *
         * Return a boolean for the page loading spinner to denote if all data has been loaded.
         *
         * @returns {boolean} if data has been loaded or not
         */
        computedInfoIsLoading() {
            const t = this;
            t.loading = !t.isFilterDataLoaded;
            return t.loading;
        },
    },

    // * COMPUTED
    computed: {
        // Computed filters per section
        sectionFilters() {
            switch (this.chosenSection) {
                case "fuel":
                    // for each section in the inPageSections array, filter the content array to only include items that have the same type as the section
                    for (const section of this.inPageSections) {
                        section.content = this.content.filter((item) => item.fuelFeatures.includes(section.type));
                        // shuffle the array
                        for (let i = section.content.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [section.content[i], section.content[j]] = [section.content[j], section.content[i]];
                        }
                        // slice the array to only include 5 items
                        section.content = section.content.slice(0, 5);
                    }
                    break;
                case "pause":
                    // for each section in the inPageSections array, filter the content array to only include items that have the same type as the section
                    for (const section of this.inPageSections) {
                        section.content = this.content.filter((item) => item.pauseType.includes(section.type));
                        // shuffle the array
                        for (let i = section.content.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [section.content[i], section.content[j]] = [section.content[j], section.content[i]];
                        }
                        // slice the array to only include 5 items
                        section.content = section.content.slice(0, 5);
                    }
                    break;
                case "move":
                    // for each section in the inPageSections array, filter the content array to only include items that have the same type as the section
                    for (const section of this.inPageSections) {
                        section.content = this.content.filter((item) => item.moveType.includes(section.type));
                        // shuffle the array
                        for (let i = section.content.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [section.content[i], section.content[j]] = [section.content[j], section.content[i]];
                        }
                        // slice the array to only include 5 items
                        section.content = section.content.slice(0, 5);
                    }
                    break;
                case "grow":
                    // for each section in the inPageSections array, filter the content array to only include items that have the same type as the section
                    for (const section of this.inPageSections) {
                        section.content = this.content.filter((item) => item.growTopic.includes(section.type));
                        // shuffle the array
                        for (let i = section.content.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [section.content[i], section.content[j]] = [section.content[j], section.content[i]];
                        }
                        // slice the array to only include 5 items
                        section.content = section.content.slice(0, 5);
                    }
                    break;
                default:
                    // for (let i = 0; i < this.inPageSections.length; i++) {
                    //     this.inPageSections[i].content.push(this.content.slice(10));
                    // }
                    break;
            }
            this.isFilterDataLoaded = true;
            return true;
        },
    },

    // * CREATED
    async created() {
        this.initialise();
    },
};
</script>

<style>
/* Style applied to the slide group for ActivityButtonsComponent to remove previous arrow and spacing. It also changes size of next arrow
 */
#feed-slideGroup-arrows > .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 0px;
}

#feed-slideGroup-arrows > .v-slide-group__next {
    flex: 0 1 auto;
    min-width: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    padding-bottom: 52px;
}

</style>

<style scoped>
* {
    box-shadow: none !important;
}
</style>
