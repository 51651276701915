<template>
    <div class="primary allowscrolling overflow-y-auto overflow-x-hidden" style="height: 100vh !important">
        <!--Logo-->
        <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" md="8" class="mt-12 py-4 d-flex align-center justify-center">
                <!-- <img width="300" alt="Kind Mind logo" src="@/assets/logo.png" /> -->
                <img :src="pageLogo" alt="Company's Log" width="240" :class="$vuetify.breakpoint.smAndUp ? 'pb-10 pt-10 mt-4' : 'pt-8'" />
            </v-col>
        </v-row>
        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />
        <v-row class="d-flex align-center justify-center mt-4">
            <v-col cols="12" xs="12" md="" class="d-flex align-center justify-center">
                <v-card class="pa-5 mx-5 elevation-0 rounded-xl white">
                    <div class="primary--text text-center">
                        <div :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4-bold'">Reset Password</div>
                        <div class="grey--text" :class="smalldevice ? 'font-weight-thin kindmind-font-h6' : 'kindmind-font-h6'">Please enter your email address to request a password reset</div>
                    </div>

                    <!--Form-->
                    <div class="my-5">
                        <v-form ref="form" lazy-validation>
                            <v-row class="d-flex align-center justify-center mx-2">
                                <v-col cols="12" xs="12" md="8" class="">
                                    <v-text-field v-model="form.userEmail" solo flat background-color="grey lighten-3" hide-details="auto" label="Email" type="email" class="rounded-km" :rules="emailRules" required> </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <v-row class="d-flex justify-center text-center mt-2 px-5">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn block x-large class="white--text mb-8 elevation-0 rounded-km secondary white--text" @click="reset()" :disabled="form.userEmail === '' || loading" :loading="loading">Reset </v-btn>
                        </v-col>
                    </v-row>

                    <div class="kindmind-font-h7 grey--text text-center pr-4">Already have an account? <span class="kindmind-font-h7 secondary--text" style="cursor: pointer" @click="$router.push('/auth')">Login</span></div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export default {
    name: "ResetPassword",
    // * DATA
    data: () => ({
        // * ALERT
        alertData: { title: "", message: "", color: "", timeout: "2000", show: false },

        // form
        inputBackgroundColor: "white",
        email: "",
        rules: [],
        showDone: false,
        form: {
            email: "",
        },
        loading: false,
        emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+/.test(v) || "Email must be valid"],
        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),

    // * COMPUTED
    computed: {
        // Checks the size of viewport
        smalldevice() {
            return window.innerWidth < 680;
        },
    },

    // * METHODS
    methods: {
        reset() {
            if (this.$refs.form.validate()) {
                var t = this;
                t.loading = true;

                const auth = getAuth();

                sendPasswordResetEmail(auth, t.form.userEmail)
                    .then(function () {
                        // Email sent.
                        t.loading = true;

                        // Wrap the code that sets alertData and calls $nextTick() in a Promise
                        new Promise((resolve, reject) => {
                            t.alertData = { title: "Success", message: "A link to reset your password has been sent to your email address. Check your inbox.", color: "green", timeout: 2000, show: true };

                            // Call $nextTick() inside the Promise
                            t.$nextTick(() => {
                                resolve();
                            });
                        }).then(() => {
                            // The code inside this then() block will only run after $nextTick() has finished
                            t.loading = false;
                            // Use setTimeout() to delay the call to $router.push()
                            setTimeout(() => {
                                t.$router.push("/auth");
                            }, t.alertData.timeout);
                        });
                    })
                    .catch(function () {
                        console.error("err: " + error);
                        t.alertData = { title: "Error", message: "There was an error.", color: "error", timeout: 2000, show: true };
                        // t.MIX_alertBox({ color: "error", timeout: 2000, message: "There was an error", show: true });
                    })
                    .catch((error) => {
                        // Handle any errors that might occur when setting alertData or calling $nextTick()
                        console.error("Error occurred while setting alert data or calling nextTick():", error);
                    });
            }
        },
        resetPassword: function () {
            let t = this;

            t.email = t.email.toLowerCase();

            t.rules = {
                email: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid", (v) => !/\s+/.test(v) || "E-mail must not contain spaces"],
            };

            t.$nextTick(() => {
                if (t.$refs.email.validate()) {
                    t.$firebase.auth
                        .sendPasswordResetEmail(t.email)
                        .then(function () {
                            t.showDone = true;
                            t.alertData = { title: "Success", message: "A link to reset your password has been sent to your email address. Check your inbox.", color: "success", timeout: 2000, show: true };
                            // t.MIX_alertBox({ color: 'green', timeout: 2000, message: 'A link to reset your password has been sent to your email address. Check your inbox', show: true })
                        })
                        .catch(function (error) {
                            console.error("err: " + error);
                            t.alertData = { title: "Error", message: "There was an error.", color: "error", timeout: 2000, show: true };
                            // t.MIX_alertBox({ color: 'error', timeout: 2000, message: error, show: true })
                        });
                } else {
                    t.alertData = { title: "Warning", message: "Fill out your email address correctly in order to proceed.", color: "warning", timeout: 2000, show: true };
                    // t.MIX_alertBox({ color: 'warning', timeout: 2000, message: 'Fill out your email address correctly in order to proceed.', show: true })
                }
            });
        },
    },
};
</script>
