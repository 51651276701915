<template>
    <div class="grey lighten-4 allowscrolling">
        <!-- Induction questions for users. Onboarding. Child element of Questions.vue -->
        <div class="grey lighten-4 primary--text">
            <v-row class="lighten-5 px-5" dense>
                <v-col cols="12">
                    <div class="font-weight-light text-center" :class="smalldevice ? 'kindmind-title-font-h5' : 'kindmind-title-font-h4 mt-8'">Question {{ question.questionOrder }}</div>
                </v-col>
                <v-col cols="12">
                    <div class="mt-2 text-center black--text" :class="smalldevice ? 'kindmind-font-b1' : 'kindmind-font-h6'">
                        {{ question.questionName }}
                    </div>
                </v-col>
            </v-row>
            <!-- COMPONENT -->
            <v-row dense :class="smalldevice ? 'py-2 px-2' : 'pt-4 px-5'" class="d-flex justify-center">
                <!-- Ranking Questions -->
                <v-col v-if="rankorder" cols="12" xs="12" sm="12" class="mt-0 pt-0 d-flex flex-column align-center justify-center">
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center' : 'kindmind-font-h7 font-weight-thin text-center mx-3'">Please drag and drop items.</div>

                    <drop-list :items="parseAnswers" @reorder="$event.apply(parseAnswers)" @insert="reOrderAnswer">
                        <template v-slot:item="{ item }">
                            <drag :key="item.text">
                                <v-col cols="12" xs="12" md="6">
                                    <v-btn style="max-width: 84vw !important" :value="item.text" depressed :height="smalldevice ? 45 : 60" class="rounded-km primary d-flex justify-start flex-wrap" :style="`${borderRadius}`" block :class="smalldevice ? 'my-1' : 'my-2'">
                                        <v-icon class="mr-2 kindmind-font-h4 white--text" large>icons8-drag-reorder</v-icon>
                                        <span class="kindmind-font-h6 text-capitalize d-flex justify-center flex-wrap">{{ item.text }}</span>
                                        <v-icon class="kindmind-font-icons ml-8 mr-3 white--text d-flex justify-center">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </v-col>
                            </drag>
                        </template>
                        <template v-slot:feedback="{ data }">
                            <div :key="data">{{ data }}</div>
                        </template>
                    </drop-list>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>

                <!-- Sliding questions -->
                <v-col v-else-if="sliding" cols="12" xs="12" md="6" lg="4">
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center grey--text text--darken-1' : 'kindmind-font-h7 text-center mx-3 grey--text text--darken-1 '">Please slide the bar to respond</div>
                    <!-- <div class="mx-5" style="white-space: nowrap !important;">
                      
                        <VueSlideBar id="vueslider-style" :labelStyles="labelStyles" v-model="myanswers" :range="sliderRange" :processStyle="sliderCustomzie.processStyle" :tooltipStyles="sliderCustomzie.tooltipStyles" :max="scaleEndScore" :min="scaleStartScore">
                        </VueSlideBar>
                    </div> -->
                    <v-slider v-model="myanswers" color="primary" track-color="grey" always-dirty :max="scaleEndScore" thumb-label :min="scaleStartScore">
                        <template v-slot:prepend>
                            <div>
                                {{ sliderRange[0].label }}
                            </div>
                        </template>

                        <template v-slot:append>
                            <div>
                                {{ sliderRange[1].label }}
                            </div>
                        </template>
                    </v-slider>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>

                <!-- Single and multiple answer questions -->
                <v-col v-else cols="12" xs="12" md="6" lg="4" class="px-5" no-gutter>
                    <div :class="$vuetify.breakpoint.width < 600 ? 'kindmind-font-b1 text-center' : 'kindmind-font-h7 text-center mx-3'">{{ multiple ? "Please choose one or more answers to continue" : "" }}</div>
                    <v-btn-toggle v-model="myanswers" :multiple="multiple" active-class="animate__animated animate__pulse animate__repeat-3 secondary white--text" class="d-flex align-center flex-column grey lighten-4 mb-6">
                        <v-btn style="max-width: 98vw !important" @click="updateAnswers(answerIndex)" v-for="(answer, answerIndex) in JSON.parse(question.questionAnswers)" :key="answerIndex" :value="answer" depressed :height="smalldevice ? 45 : 60" class="rounded-km primary text--white d-flex justify-center" :style="`${borderRadius}`" block :class="smalldevice ? 'my-1 ' : 'my-2'">
                            <div style="width: 100%" :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-row align-center justify-start flex-wrap' : 'd-flex flex-row align-center'">
                                <!-- <v-icon large class="kindmind-font-icons ml-8 mr-3 white--text d-flex justify-center">{{ answer.icon }}</v-icon> -->
                                <div class="white--text text-capitalize d-flex justify-start flex-wrap" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-h7' : 'kindmind-font-h6'">
                                    {{ answer.text }}
                                </div>
                            </div>
                        </v-btn>
                    </v-btn-toggle>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
import VueSlideBar from "vue-slide-bar";

export default {
    name: "questions-component",
    components: {
        Drag,
        DropList,
        VueSlideBar,
    },
    props: {
        datadown: {
            type: Object,
            default: () => {},
        },
        activeQuestionCount: {
            type: Number,
        },
    },
    // * DATA
    data: () => ({
        questionId: "onBoarding",

        // variables for Sliding Scale questions
        scaleStartScore: 0,
        scaleEndScore: 0,
        scaleStartLabel: "",
        scaleEndLabel: "",
        test1: 0,
        // sliderCustomzie: {
        //     processStyle: {
        //         backgroundColor: "var(--v-primary-base)",
        //     },
        //     tooltipStyles: {
        //         backgroundColor: "var(--v-primary-base)",
        //         borderColor: "var(--v-primary-base)",
        //     },
        // },
        // labelStyles: {
        //     margin: "0 8px 0 8px",
        //     // paddingLeft: "60px",
        // },
        sliderRange: [
            {
                label: "",
            },
            {
                label: "",
            },
        ],

        // Type of question
        multiple: false,
        rankorder: false,
        sliding: false,

        // Questions and answers data
        parseAnswers: [],
        question: {},
        useranswer: {},
        useranswerDefault: { questionId: "", questionSection: "", questionInsights: "", questionCore: "", questionAnswers: [], createdUserId: "", createdOrgId: "", questionAnswersValue: null, createdDate: null, modifiedUserId: "", modifiedOrgId: "", modifiedDate: null, deletedUserId: "", deletedOrgId: "", deletedDate: null, deleted: false },
        useranswers: [],
        myanswers: [],
    }),

    // * COMPUTED
    computed: {
        borderRadius() {
            if (this.smalldevice) {
                return "border-radius: 15px !important";
            } else {
                return "border-radius: 20px !important";
            }
        },
        // Check the height of the device
        smalldevice() {
            return window.innerHeight < 680;
        },
    },
    // * METHODS
    methods: {
        // Links the chosen answer to the matching question
        updateAnswers(answerIndex) {
            this.$set(this.useranswers, this.activeQuestionCount, answerIndex);
        },
        //initialise passes question the information from datadown
        initialise() {
            this.question = { ...this.datadown };

            this.useranswer = { ...this.useranswerDefault };
            this.myanswers = [];

            // Checks if it's a multiple answer question
            if (this.question.questionAnswerType == "Multiple Choice") {
                this.multiple = true;
                this.useranswer = { ...this.useranswerDefault };
                this.myanswers = [];
            } else if (this.question.questionAnswerType == "Rank Order") {
                this.rankorder = true;
                this.useranswer = { ...this.useranswerDefault };
                this.myanswers = [];
            } else if (this.question.questionAnswerType == "Sliding Scale") {
                let scaleStart = JSON.parse(this.question.questionAnswers);
                this.scaleStartScore = scaleStart.scaleStart[0].score;
                this.scaleEndScore = scaleStart.scaleEnd[0].score;
                this.scaleStartLabel = scaleStart.scaleStart[0].label;
                this.scaleEndLabel = scaleStart.scaleEnd[0].label;
                this.sliderRange[0].label = this.scaleStartLabel;
                this.sliderRange[1].label = this.scaleEndLabel;
                this.sliding = true;
                this.useranswer = { ...this.useranswerDefault };
                this.myanswers = [];
            }
        },
        // Parses the answers to be used in the drag and drop
        computeAnswers() {
            if (this.question.questionAnswerType == "Rank Order") {
                this.parseAnswers = JSON.parse(this.question.questionAnswers);
            }
        },
        // Maked the drag and drop function
        reOrderAnswer(event) {
            this.parseAnswers.splice(event.index, 0, event.data);
        },
    },
    // * WATCH
    watch: {
        // datadown is watching changes for new questions coming from the parent
        datadown: {
            handler() {
                this.initialise();
            },
            deep: true,
        },
        // question is watching changes for the questions coming from the child
        question: {
            handler() {
                this.$emit("update", this.question);
            },
            deep: true,
        },
        // useranswer is watching changes for the answers coming from the child
        myanswers: {
            handler() {
                this.useranswer.questionId = this.question.entityId;
                this.useranswer.questionSection = this.question.questionSection;
                this.useranswer.questionCore = this.question.questionCore;
                if (this.multiple == true) {
                    this.useranswer.questionAnswers = this.myanswers;
                } else {
                    this.useranswer.questionAnswers.push(this.myanswers);
                }
                this.$emit("updateanswer", { ...this.useranswer });
            },
            deep: true,
        },
        parseAnswers: {
            handler() {
                this.useranswer.questionId = this.question.entityId;
                this.useranswer.questionSection = this.question.questionSection;
                // if (this.rankorder == true) {
                let answer = [];

                for (let i = 0; i < this.parseAnswers.length; i++) {
                    answer.push(this.parseAnswers[i].text);
                }
                this.useranswer.questionAnswers = answer;
                // }

                this.$emit("updateanswer", { ...this.useranswer });
            },
            deep: true,
        },
    },
    // * CREATED
    created() {
        this.initialise();
        this.computeAnswers();
    },
};
</script>

<style>
#vueslider-style .vue-slide-bar-separate-text {
    white-space: normal !important;
    width: 80px !important;
    display: flex !important;
    /* align-items: start !important; */
    justify-content: start !important;
    /* flex-wrap: wrap !important; */
    padding-right: 8px !important;
    padding-left: 8px !important;
}
</style>
<style scoped>
.allowscrolling {
    overflow-y: scroll;
}
</style>
