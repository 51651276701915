-+<template>
    <span class="allowscrolling overflow-y-auto overflow-x-hidden" style="height: 100% !important; background-color: rgba(255, 255, 255) !important; ">
        
        <app-bar-component app :appbarcolour="'primary'" :setmargin="'mx-4'"/>
        <!-- <app-bar-component :appbarcolour="'primary'" :setmargin="'mx-4'" :class="$vuetify.breakpoint.innerWidth < 400 ? 'mb-1' :$vuetify.breakpoint.innerWidth < 700 ? 'mb-4' : 'mb-12'"/> -->

        <!-- This view holds the explore section buttons component and media component and switches through them  -->
        <!-- Components -->
        <!-- <component :is="component" @newComponentRequest="sectionComponent" v-on:goexplore="goexplore" :chosenSection="exploreId" :key="exploreId" class="mb-4"></component> -->
       <explore-buttons-component class="mb-4" v-on:newComponentRequest="sectionComponent"/>

        <!-- BOTTOM MARGIN -->
        <div :style="$vuetify.breakpoint.width < 400 ? 'margin-bottom: 200px !important;': ''"></div>
        
        <!-- BOTTOM NAVIGATION -->
        <!-- <bottom-navigation @reloadexplore="goexplore" :setpadding="'px-4'"/> -->
        <bottom-navigation :setpadding="'px-4'"/>
        <div class="footerbanner"></div>
    </span>
</template>

<script>
import ExploreButtonsComponent from './ExploreButtonsComponent.vue';
export default {
    components: { ExploreButtonsComponent },
    name: "explore-screen",
    // * DATA
    data: () => ({
        filter: false,
    }),
    // * METHODS
    methods: {
        // Takes an argument from child component. Pushes the route, component and style passed as a parameter
         sectionComponent(section) {
  +          // this.exploreId = section;
            // this.$router.push(`/explore/${section}`);
            // this.filter = !this.filter;
            // this.$router.push(`/exploreSections`);
            this.MIX_go({ name: 'sections-screen', params: { pagesection: section }});
        },
        // Called by child component BottomNavigation - switches component back to section buttons
        // goexplore() {
        //     this.component = "explore-buttons-component";
        // },
    },
    // * CREATED
    created() {
        this.loading = true;
        this.loading = false;
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.footerbanner {
    z-index: 99 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    height: clamp(100px, 14vw, 240px) !important;
    background: -moz-linear-gradient(top, rgb(255 255, 255) 30%, rgba(255 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255 255, 255) 30% (255 255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255 255, 255) 30%, rgba(255 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
