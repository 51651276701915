<!-- Common Page Loader Component-->

<template>

   <v-overlay color="white" opacity="0.5" :value="isPageLoading">

       <v-progress-circular color="primary" indeterminate size="96"/>

   </v-overlay>
   
</template>

<script>
export default {

   name: "pageLoading",

   props: ['isPageLoading']

}
</script>