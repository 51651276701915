<template>
    <div class="primary allowscrolling overflow-y-auto overflow-x-hidden" style="height: 100vh !important">
        <!--Logo-->
        <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" md="8" class="mt-12 py-4 d-flex align-center justify-center">
                <!-- <img width="300" alt="Kind Mind logo" src="@/assets/logo.png" /> -->
                <img :src="pageLogo" alt="Company's Log" width="240" :class="$vuetify.breakpoint.smAndUp ? 'pb-10 pt-10 mt-4' : 'pt-8'" />
            </v-col>
        </v-row>
        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />
        <v-row class="d-flex align-center justify-center mt-4">
            <v-col cols="12" xs="12" md="" class="d-flex align-center justify-center">
                <v-card v-if="!newPasswordSet" class="pa-5 mx-5 elevation-0 rounded-xl white">
                    <div class="primary--text text-center">
                        <div :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4-bold'">Reset your password</div>
                        <div class="black--text" :class="smalldevice ? 'kindmind-font-h6' : 'kindmind-font-h6'">
                            for <span>{{ accountEmail }}</span>
                        </div>
                    </div>

                    <!--Form-->
                    <div class="my-5">
                        <v-form ref="form" lazy-validation>
                            <v-row class="d-flex align-center justify-center mx-2">
                                <v-col cols="12" xs="6" md="8" class="my-0 py-0">
                                    <div :class="smalldevice ? 'kindmind-font-cap' : 'kindmind-font-b2'" class="pb-2 text-center">Please choose a password that hasn't been used before.</div>
                                    <!-- <div :class="smalldevice ? 'kindmind-font-cap-bold' : 'kindmind-font-b2-bold'">Password must have a minimum of 8 characters, including one uppercase character, one numeric character, and one symbol character.</div> -->
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="form.password" label="Password" ref="passwordField" :rules="rules.passwordRules" autocomplete="off" required @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-lock' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"></v-text-field>
                                </v-col>

                                <v-col cols="12" xs="6" md="8" class="my-0 py-0">
                                    <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" class="primary--text rounded-km" background-color="grey lighten-3" v-model="form.confirmPassword" label="Confirm Password" :append-icon="show ? 'mdi-lock' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" :rules="rules.confirmRules" autocomplete="off" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <v-row class="d-flex justify-center text-center mt-2 px-5">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn block x-large class="white--text mb-8 elevation-0 rounded-km secondary white--text" @click="validatePassword" :disabled="form.password === '' || form.confirmPassword === '' || loading" :loading="loading">Save </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-else class="pa-5 mx-5 elevation-0 rounded-xl white">
                    <div class="primary--text text-center">
                        <div :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4-bold'">Reset your password</div>
                        <div class="black--text my-5 text-center" :class="smalldevice ? 'font-weight-thin kindmind-font-h6' : 'kindmind-font-h6'">
                            New password for <span>{{ accountEmail }}</span> successfully set. Please go back to the login page to login with your new password.
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { getAuth, updatePassword, verifyPasswordResetCode, confirmPasswordReset, fetchSignInMethodsForEmail } from "firebase/auth";
import "firebase/auth";
export default {
    name: "createNewPassword",
    // * DATA
    data: () => ({
        // * ALERT
        alertData: { title: "", message: "", color: "", timeout: "2000", show: false },

        // view password
        show: false,
        show1: false,

        // form
        form: {
            password: "",
            confirmPassword: "",
        },
        rules: [],

        // loading
        loading: false,
        newPasswordSet: false,

        // account id
        code: "",
        accountEmail: "",
        passwordCheck: false,

        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),

    // * COMPUTED
    computed: {
        // Checks the size of viewport
        smalldevice() {
            return window.innerWidth < 680;
        },
    },

    // * METHODS
    methods: {
        //  VALIDATING SECOND WINDOW AND REGISTERING (IF VALID)
        async validatePassword() {
            this.rules = {
                passwordRules: [(v) => !!v || "Password is required", (v) => v.length >= 6 || "Password must be at least 6 characters", (v) => /[A-Z]/.test(v) || "Password must contain at least 1 uppercase", (v) => /[a-z]/.test(v) || "Password must contain at least 1 lowercase", (v) => /\d/.test(v) || "Password must contain at least 1 number", (v) => /[\W_]/.test(v) || "Password must contain at least 1 special character"],
                confirmRules: [(v) => !!v || "Confirm password required"],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    if (this.form.password === this.form.confirmPassword) {
                                this.resetPassword();
                            } else {
                                this.alertData = { title: "Account Created", message: "Passwords do not match", color: "error", timeout: 2000, show: true };
                                // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Passwords do not match", show: true });
                            }
                        } else {
                            // ! did not pass validation
                            this.alertData = { title: "", message: "Fill out all the fields correctly in order to proceed.", color: "error", timeout: 2000, show: true };
                            // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
                        }
            });
        },
        async resetPassword() {
            var t = this;
            const auth = getAuth();
            confirmPasswordReset(auth, t.code, t.form.password)
                .then(async (data) => {
                    // Success
                    t.alertData = { title: "Account found", message: "Your password has been changed. Please go back to the application.", color: "green", timeout: 2000, show: true };
                    t.newPasswordSet = true;
                })
                .catch((err) => {
                    // Invalid code
                    t.alertData = { title: "Account not found", message: "Account not found", color: "error", timeout: 2000, show: true };
                    console.error("error", err);
                });
        },
    },

    // * CREATED
    created() {
        // * VALIDATING SECOND WINDOW AND REGISTERING (IF VALID)

        this.code = this.$route.query.oobCode;
        // Verify the password reset code with Firebase Authentication
        const auth = getAuth();
        verifyPasswordResetCode(auth, this.code)
            .then((email) => {
                // console.log("Password reset code is valid for user:", email);
                this.accountEmail = email;
            })
            .catch((error) => {
                console.error("Password reset code is invalid:", error);
                // Handle the error
            });
    },
};
</script>
