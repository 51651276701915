<template>
    <v-row class="d-flex align-start justify-center mb-16" :class="$vuetify.breakpoint.xsOnly ? ' mt-5' : ' my-8'" style="width: 100% !important">
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ma-0 pa-0">
             <app-text v-if="datadown == ''" class="grey--text" style=" justify-content: center;" :category="$vuetify.breakpoint.width <= 700 ? 'KM-h6' : 'KM-h5'">You have no available polls at the moment.</app-text>
            <v-card v-else v-for="(poll, pollIndex) in datadown" :key="pollIndex" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-center pb-0">
                    <v-col cols="8" class="d-flex pa-0">
                        <!-- User Image -->
                        <v-avatar v-if="poll.createdUserPhoto" class="primary mr-2 rounded-lg">
                            <v-img :src="poll.createdUserPhoto" cover></v-img>
                        </v-avatar>
                        <!--If an image isn't present, render an icon-->
                        <v-avatar v-else class="primary mr-2 rounded-lg">
                            <v-icon class="icons8-user white--text" :size="$vuetify.breakpoint.xsOnly ? 38 : 42"> </v-icon>
                        </v-avatar>
                        <div class="d-flex flex-column">
                            <div class="grey--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndDown ? '' : 'kindmind-font-b2'">{{ poll.createdUserName }}</div>
                            <div class="grey--text text-left" :class="smalldevice ? 'kindmind-font-b2' : 'kindmind-font-b2'">Author</div>
                        </div>
                    </v-col>
                </v-col>
                <div class="my-5">
                    <v-row dense class="d-flex flex-column px-4">
                        <v-col cols="12" xs="12" xl="8" class="d-flex flex-column align-center justify-center">
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold" :class="smalldevice ? ' kindmind-font-b1' : 'kindmind-font-h7'" style="width: 100%">{{ poll.questionName }}</div>
                        </v-col>
                        <v-row class="d-flex justify-center" no-gutters>
                            <v-col cols="6" class="d-flex flex-wrap mt-2 flex-grow-1 flex-shrink-0 justify-space-around" style="min-width: 100px; max-width: 70%;">
                                <div v-for="(answer, answerIndex) in JSON.parse(poll.questionAnswers)" :key="answerIndex" :label="`${answer.text}`" :value="answer.text" @click="handleAnswers(pollIndex, poll.entityId, answer.text)">
                                    <v-btn :disabled="poll.alreadyRepliedTo || pollResults" class="primary ma-1" block>{{ answer.text }}</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <div v-if="pollResults && currentPollIndex === pollIndex">
                            <bar-chart :seriesdata="sortDataForChart()" color="#7FC3D8" seriestext="Poll Results"/>
                        </div>
                       <div v-else-if="poll.alreadyRepliedTo">
                            <bar-chart :datadown="poll" color="#7FC3D8" seriestext="Poll Results"/>
                        </div>
                    </v-row>
                </div>
            </v-card>
                <br/>
                <br/>
                <br/>
                <br/>
        </v-col>
  
        <div class="footerbanner"></div>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import BarChart from '@/components/echarts/BarChart.vue'

export default {
    name: 'polls-component',
    components: {
        BarChart
    },
    props: {
        datadown: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        // shows the questions
        allPollAnswers: [],
        content: [],
        currentUser: {},
        currentPollIndex: null,
        defaultUseranswer: {
            questionId: '',
            questionSection: '',
            questionInsights: '',
            questionAnswers: [],
            createdUserId: '',
            createdOrgId: '',
            createdDate: '',
            modifiedUserId: '',
            modifiedOrgId: '',
            modifiedDate: '',
            deletedUserId: '',
            deletedOrgId: '',
            deletedDate: '',
            deleted: false
        },
        pollAnswer: '',
        pollResults: false,
        socialId: 'polls',
        useranswer: {
            questionId: '',
            questionSection: '',
            questionInsights: '',
            questionAnswers: [],
            createdUserId: '',
            createdOrgId: '',
            createdDate: '',
            modifiedUserId: '',
            modifiedOrgId: '',
            modifiedDate: '',
            deletedUserId: '',
            deletedOrgId: '',
            deletedDate: '',
            deleted: false
        },
    }),
    computed: {
        smalldevice() {
            return window.innerHeight < 600
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth'
        })
    },
    methods: {
        async readCurrUser() {
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users')
            this.currentUser = userDataResult.data;
        },

        // initializes the content
        initialize() {
            this.content = { ...this.datadown }
        },

        // prepares the answer so that it can be sent to the database
        async handleAnswers(index, questionId, answer) {
            this.currentPollIndex = index;
            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss', 'X'))
            this.useranswer.questionId = questionId
            this.useranswer.questionSection = 'Poll'
            this.useranswer.questionAnswers = [answer]
            this.useranswer.createdUserId = this.currentUser.createdUserId
            this.useranswer.createdOrgId = this.currentUser.userOrgId
            this.useranswer.createdDate = currentDateTime
            await this.sendAnswers()
            this.getAnswers(questionId, index)
            this.useranswer.questionAnswers = []
        },
        async sendAnswers() {
            await this.REDIS_create('useranswer', this.useranswer)
            this.useranswer = { ...this.defaultUseranswer }
        },

        // gets the answers from the database
        async getAnswers(questionId, index) {
            let t = this
            this.currentPollIndex = index;
            t.pollResults = true
            let searchQuery = `@questionId:{${questionId}} @deleted:{false}`
            let contentResult = await this.REDIS_searchFor('useranswer', null, null, null, null, searchQuery)
            t.allPollAnswers = contentResult.data.documents
            this.sortDataForChart()
        },
        sortDataForChart() {
            let t = this
            let stats = []            
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.questionAnswers] = (acc[obj.questionAnswers] || 0) + 1
                return acc
            }, {})
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map((k) => ({
                name: k,
                value: objResult[k]
            }))
            //* Assign key value pairs to graph structure
            arrResult.forEach((stat) => {
                stats.push({
                    name: stat.name,
                    value: stat.value
                })
            })
            return stats
        },
    },
    created() {
        this.readCurrUser();
        this.initialize();
    }
}
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.imageStyle {
    height: 8vh;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    height: 20vh !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
