<template>
    <div v-if="readCreators && readCurrUser && !loading && contentCreator" class="allowscrolling fullviewpoint white pa-0 ma-0 d-flex flex-column" style="width: 100vw !important; overflow-x: hidden !important">
        <!-- Loading circle - renders when loading is true -->
        <v-overlay :value="loading">
            <v-progress-circular :size="100" :width="5" color="white" indeterminate></v-progress-circular>
        </v-overlay>

        <!-- The selected content from Sections Feed is displayed through this view - video, audio, text-->

        <!-- TOP NAV -->
        <app-bar-component app :setmargin="'mx-6'" @goexplore="goexplore" :backbutton="true" />

        <div v-if="$vuetify.breakpoint.smAndUp">
            <br />
        </div>

        <!-- HEADER and back Button-->
        <div class="d-flex flex-row align-center justify-start mx-6 my-2">
            <v-btn icon @click="goexplore" :class="`${chosenSection}--text `"> <v-icon large>icons8-back-to</v-icon> </v-btn>
        </div>
        <v-row no-gutters class="justify-center">
            <v-col cols="12" xs="12" md="8" class="text-center">
                <!-- Video Media -->
                <video v-if="selectedmedia[`${chosenSection}` + 'MediaType'] == 'Video'" class="px-6" style="width: 100% !important" controls controlsList="nodownload" playsinline preload="metadata">
                    <source :src="MIX_videoURL + ('video/' + `${selectedmedia[`${chosenSection}` + 'VideoId']}` + '.mp4#t=0.003')" type="video/mp4" />
                    Your browser does not support HTML video.
                </video>
                <!-- Images -->
                <div class="mx-6">
                    <img v-if="selectedmedia[`${chosenSection}` + 'MediaType'] !== 'Video' && selectedmedia[`${chosenSection}` + 'LandscapeImageId'] !== ''" :src="MIX_imageURL + ('images/landscape/' + selectedmedia[`${chosenSection}` + 'LandscapeImageId'] + '.jpeg?')" alt="Image from media" :class="selectedmedia[`${chosenSection}` + 'MediaType'] !== 'Audio' ? 'rounded-km' : 'rounded-km-top'" style="width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'max-height:56vh;' : $vuetify.breakpoint.smOnly ? 'max-height: 38vh;' : 'max-height: 28vh;'" cover />

                    <!--If an image isn't present, render an icon-->
                    <div v-else-if="selectedmedia[`${chosenSection}` + 'MediaType'] !== 'Video' && selectedmedia[`${chosenSection}` + 'LandscapeImageId'] === ''" :class="`${chosenSection} rounded-km`">
                        <v-icon color="white" class="icons8-picture" :style="$vuetify.breakpoint.mdAndUp ? 'height:50vh;width: 100% !important;' : 'width: 100% !important; height: 20vh;'" :size="$vuetify.breakpoint.mdAndUp ? `148` : '120'"> </v-icon>
                    </div>

                    <!-- Audio Media -->
                    <div style="">
                        <!-- <vue-player v-if="selectedmedia[`${chosenSection}` + 'MediaType'] == 'Audio'" audio :overlayBlur="overlayBlur" theater :overlayColor="`${chosenSection}dd`" :color="`var(--v-${chosenSection}-base)`" :sources="audioSources" style="width: 100% " /> -->
                        <audio text class="grey lighten-3" id="audio_player" v-if="selectedmedia[`${chosenSection}` + 'MediaType'] == 'Audio'" controls controlsList="nodownload" :src="MIX_audioURL + 'audio/' + selectedmedia[`${chosenSection}` + 'AudioId'] + '.mp3?'" style="width: 100%;"></audio>
                        <!-- {{audioSources}}  -->
                        <!-- this.audioSources["audio/mp3"] = `${this.MIX_audioURL + "audio/" + audioId + ".mp3?"}`; -->
                        <!-- <audio text class="grey lighten-3" id="audio_player" v-if="selectedmedia[`${chosenSection}` + 'MediaType'] == 'Audio'" controls controlsList="nodownload" :src="audioSources" style="width: 100% !important"></audio> -->
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- Media title and description. If section is fuel then recipe -->
        <v-row class="mx-6" :class="$vuetify.breakpoint.mdAndUp ? 'd-flex flex-row justify-center align-center py-6' : 'my-2'">
            <v-col cols="12" xs="12" md="8" class="d-flex flex-column pa-0">
                <!-- TITLE AND FAV ICON -->
                <div class="d-flex align-start pa-0">
                    <div :class="$vuetify.breakpoint.xsOnly ? `${chosenSection}--text kindmind-font-h5 py-0` : `${chosenSection}--text kindmind-font-h4 py-0`">{{ selectedmedia[`${chosenSection}` + "Title"] }}</div>
                    <v-spacer />
                    <v-btn small icon plain :ripple="false" depressed @click="addFavouriteContent">
                        <v-icon style="display: fixed !important; top: 0 !important; width: auto" :class="isfavourite ? 'red--text' : ''" depressed class="iconStyle pa-0 ma-0 pt-2">{{ isfavourite ? "icons8-favorite" : "icons8-favorite-2" }}</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>

                <!-- INFO BUBBLES FOR ALL-->
                <!-- <v-col cols="12" xs="12" md="8" :class="chosenSection == 'fuel' ? 'd-flex justify-space-between mx-0 px-0 my-2' : 'mx-0 px-0 mt-2'">
                    <v-btn v-if="chosenSection == 'fuel'" text :width="$vuetify.breakpoint.width < 380 ? '29vw' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '29vw' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '29vw' : '14vw'" :height="$vuetify.breakpoint.width < 380 ? '4vh' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '4vh' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '4vh' : ''" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-clock</v-icon>{{ selectedmedia[`${chosenSection}` + "CookMins"] + selectedmedia[`${chosenSection}` + "PrepMins"] }}</v-btn>
                    <v-btn v-else text :width="$vuetify.breakpoint.width < 380 ? '30vw' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '30vw' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '29vw' : '14vw'" :height="$vuetify.breakpoint.width < 380 ? '4vh' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '4vh' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '4vh' : ''" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text mr-2"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-clock</v-icon> {{ selectedmedia[`${chosenSection}` + "DurationMin"] }} - {{ selectedmedia[`${chosenSection}` + "DurationMax"] }}</v-btn>
                    <v-btn v-if="chosenSection == 'fuel'" text :width="$vuetify.breakpoint.width < 380 ? '29vw' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '29vw' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '29vw' : '14vw'" :height="$vuetify.breakpoint.width < 380 ? '4vh' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '4vh' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '4vh' : ''" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-group</v-icon>{{ selectedmedia[`${chosenSection}` + "Serves"] }}</v-btn>
                    <v-btn v-else text :width="$vuetify.breakpoint.width < 380 ? '30vw' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '30vw' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '29vw' : '14vw'" :height="$vuetify.breakpoint.width < 380 ? '4vh' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '4vh' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '4vh' : ''" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon small class="pr-2">mdi-puzzle</v-icon>{{ selectedmedia[`${chosenSection}` + "Difficulty"] }}</v-btn>
                    <v-btn v-if="chosenSection == 'fuel'" text :width="$vuetify.breakpoint.width < 380 ? '28vw' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '29vw' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '29vw' : '14vw'" :height="$vuetify.breakpoint.width < 380 ? '4vh' : $vuetify.breakpoint.width > 389 && $vuetify.breakpoint.width < 700 ? '4vh' : $vuetify.breakpoint.width > 700 && $vuetify.breakpoint.width < 1000 ? '4vh' : ''" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"> <v-icon class="pr-1" :size="$vuetify.breakpoint.width < 660 ? '16' : '24'">icons8-calories</v-icon> {{ selectedmedia[`${chosenSection}` + "Kcal"] }}</v-btn>
                </v-col> -->

                <v-col cols="12" xs="12" md="12" :class="chosenSection == 'fuel' ? 'd-flex justify-center mx-0 px-0 my-2' : 'mx-0 px-0 mt-2'">
                    <div :style="chosenSection == 'fuel' ? 'display:flex; justify-content:space-between;width:100%;' :chosenSection == 'move' ? 'display:flex; justify-content:space-between;width:60%;' : ''">
                        <v-btn v-if="chosenSection == 'fuel'" text style="flex: 1; margin-right: 3px" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-clock</v-icon>{{ selectedmedia[`${chosenSection}` + "CookMins"] + selectedmedia[`${chosenSection}` + "PrepMins"] }}</v-btn>
                        <v-btn v-else text style="flex: 2; margin: 0 3px" :min-width="$vuetify.breakpoint.width > 1000 ? '22vw' : '34vw'" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text mr-2"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-clock</v-icon> {{ selectedmedia[`${chosenSection}` + "DurationMin"] }} - {{ selectedmedia[`${chosenSection}` + "DurationMax"] }}</v-btn>
                        <v-btn v-if="chosenSection == 'fuel'" text style="flex: 1; margin: 0 1px" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon class="pr-2" :size="$vuetify.breakpoint.width < 660 ? '16' : '22'">icons8-group</v-icon>{{ selectedmedia[`${chosenSection}` + "Serves"] }}</v-btn>
                        <v-btn v-if="chosenSection == 'move'" text style="flex: 2; margin: 0 3px" :min-width="$vuetify.breakpoint.width > 1000 ? '22vw' : '34vw'" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"><v-icon small class="pr-2">mdi-puzzle</v-icon>{{ selectedmedia[`${chosenSection}` + "Difficulty"].split(',')[0].trim() }}</v-btn>
                        <v-btn v-if="chosenSection == 'fuel'" style="flex: 1; margin-left: 3px" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-cap` : `${chosenSection} kindmind-font-h7`" class="rounded-km text-capitalize white--text"> <v-icon class="pr-1" :size="$vuetify.breakpoint.width < 660 ? '16' : '24'">icons8-calories</v-icon> {{ selectedmedia[`${chosenSection}` + "Kcal"] }}</v-btn>
                    </div>
                </v-col>
            </v-col>

            <!-- Fuel Recipe Information -->
            <v-col v-if="chosenSection == 'fuel'" cols="12" xs="12" md="8" class="pt-0 mx-0 px-0">
                <v-stepper v-model="e1">
                    <!-- Steps Content -->
                    <v-stepper-items>
                        <v-stepper-content step="1" class="pa-0" style="width: 100% !important">
                            <!-- STEP 1 - Fuel description -->
                            <div v-if="selectedmedia.fuelDescription" v-html="selectedmedia.fuelDescription" style="width: 100%" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6'"></div>
                            <div v-else style="width: 100%" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6'">To see the {{ selectedmedia[`${chosenSection}` + "Title"] }} Recipe, click "Next"</div>

                            <div class="d-flex">
                                <v-spacer />
                                <v-btn @click="e1 = 2" text class="fuel--text kindmind-font-b1 text-capitalize d-flex justify-end px-0">Next<v-icon size="20" class="pl-1">icons8-next-page</v-icon></v-btn>
                            </div>
                        </v-stepper-content>

                        <!-- STEP 2 - Fuel Data -->
                        <v-stepper-content step="2" class="pa-0" style="width: 100% !important">
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Serves:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelServes }}</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Kcal per portion:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelKcal }}</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Proteins:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelProtein }}</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Carbs:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelCarbs }}</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Fats:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelFats }}</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Preparation time:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelPrepMins }} minutes</div>
                            </div>
                            <div class="d-flex my-2" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Cooking time:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'">{{ selectedmedia.fuelCookMins }} minutes</div>
                            </div>

                            <!-- Step 2 Buttons -->
                            <div class="d-flex align-space-between px-0">
                                <v-btn @click="e1 = 1" text class="fuel--text kindmind-font-b1 text-capitalize px-0"><v-icon size="20" class="pr-1">icons8-back-to</v-icon>Back</v-btn>
                                <v-spacer />
                                <v-btn @click="e1 = 3" text class="fuel--text kindmind-font-b1 text-capitalize px-0">Next<v-icon size="20" class="pl-1">icons8-next-page</v-icon></v-btn>
                            </div>
                        </v-stepper-content>

                        <!-- STEP 3 - Fuel Ingredients -->
                        <v-stepper-content step="3" class="pa-0" style="width: 100% !important">
                            <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4 mb-2'">
                                Ingredients:
                                <ul :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6'" v-html="selectedmedia.fuelIngredients"></ul>
                            </div>
                            <div class="d-flex align-space-between">
                                <v-btn @click="e1 = 2" text class="fuel--text kindmind-font-b1 text-capitalize d-flex justify-end px-0"><v-icon size="20" class="pr-1">icons8-back-to</v-icon>Back</v-btn>
                                <v-spacer />
                                <v-btn @click="e1 = 4" text class="fuel--text kindmind-font-b1 text-capitalize d-flex justify-end px-0">Next<v-icon size="20" class="pl-1">icons8-next-page</v-icon></v-btn>
                            </div>
                        </v-stepper-content>

                        <!-- STEP 4 - Fuel Method -->
                        <v-stepper-content step="4" class="pa-0" style="width: 100% !important">
                            <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold' : 'kindmind-font-h6-bold mr-4'">
                                Method:
                                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1 pl-2' : 'kindmind-font-h6 pl-2'" v-html="selectedmedia.fuelMethod"></div>
                            </div>
                            <div class="d-flex align-space-between">
                                <v-btn @click="e1 = 3" text class="fuel--text kindmind-font-b1 text-capitalize d-flex justify-end px-0"><v-icon size="20" class="pr-1">icons8-back-to</v-icon>Back</v-btn>
                                <v-spacer />
                                <v-btn @click="e1 = 1" text class="fuel--text kindmind-font-b1 text-capitalize d-flex justify-end px-0">Next<v-icon size="20" class="pl-1">icons8-next-page</v-icon></v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>

                    <!-- Step header -->
                    <v-stepper-header class="pa-0 ma-0 fuel--text">
                        <v-stepper-step :complete="e1 > 1" step="1" class="fuel--text" color="fuel"> </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2" color="fuel"> </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 3" step="3" color="fuel"> </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 4" step="4" color="fuel"> </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
            </v-col>

            <!-- Pause, Move, Grow descriptions -->
            <v-col v-else cols="12" xs="12" md="8" class="pa-0">
                <div style="width: 100%; cursor: pointer" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6'">
                    {{ formattedBody }}
                    <div v-if="!shortText" @click="showingFullText = !showingFullText" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection}--text kindmind-font-b1` : `${chosenSection}--text kindmind-font-h6`">
                        {{ `Read ${showingFullText ? "Less" : "More"} ` }}
                    </div>
                </div>
            </v-col>

            <!-- Grow button -->
            <!-- <v-col v-if="chosenSection == 'grow'" cols="12" xs="12" sm="12">
                <v-btn v-if="!isregistered" @click="registerScorm" class="mr-3 white--text rounded-km d-flex align-center justify-center text-capitalize pa-6" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-b1` : `${chosenSection} kindmind-font-h7`" style="margin-left: auto; margin-right: auto">Register Course</v-btn>
                <v-btn v-if="isregistered" :href="launchLink" class="white--text rounded-km d-flex align-center justify-center text-capitalize pa-6" :class="$vuetify.breakpoint.xsOnly ? `${chosenSection} kindmind-font-b1` : `${chosenSection} kindmind-font-h7`" :width="$vuetify.breakpoint.width < 600 ? '100%' : '40%'" style="margin-left: auto; margin-right: auto">Launch Course</v-btn>
            </v-col> -->
        </v-row>

        <!-- Content Creator profile -->
        <v-row v-if="contentCreator.length !== 0" dense class="d-flex justify-center grey lighten-5 mx-6" no-gutters :class="$vuetify.breakpoint.xsOnly ? 'my-8' : 'my-12'">
            <v-col cols="12" xs="12" md="8" class="d-flex align-center">
                <!-- CC Image -->
                <v-col cols="3" xs="3" md="2" lg="2" xl="2" class="px-0 mx-0">
                    <v-avatar v-if="contentCreator.userProfile" :size="$vuetify.breakpoint.xsOnly ? '84' : '136'" :class="$vuetify.breakpoint.xsOnly ? 'ml-2' : 'ml-4'">
                        <img class="rounded-km pr-2" :src="contentCreator.userProfile" cover alt="Content creator profile picture" />
                    </v-avatar>
                    <!--If an image isn't present, render an icon-->
                    <v-avatar v-else :class="`${chosenSection} rounded-circle`" :size="$vuetify.breakpoint.xsOnly ? '84' : '136'"> <v-icon color="white" class="icons8-user" :size="$vuetify.breakpoint.xsOnly ? 62 : 92"> </v-icon></v-avatar>
                </v-col>

                <!-- CC Data -->
                <v-col cols="5" xs="5" sm="2" md="2" lg="2" xl="2" :class="$vuetify.breakpoint.xsOnly ? 'ml-0' : 'ml-8'">
                    <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b2' : 'kindmind-font-h6'">{{ contentCreator.userName }}</div>
                    <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b2' : 'kindmind-font-h6'">{{ contentCreator.userLevel }}</div>
                </v-col>

                <v-spacer></v-spacer>

                <!--View More button -->
                <v-col cols="4" xs="4" md="3" lg="3" xl="3" class="d-flex justify-end px-0" :class="$vuetify.breakpoint.xsOnly ? 'mr-2' : 'mr-4'">
                    <v-btn @click="creatorProfileLaunch('creator', selectedmedia.creatorUserId)" depressed :class="`${chosenSection} text-capitalize`">View More</v-btn>
                </v-col>
            </v-col>
        </v-row>

        <!-- BOTTOM BLUR -->
        <div class="footerbanner"></div>

        <!-- BOTTOM SPACING -->
        <div style="margin-bottom: 156px"></div>

        <!-- NAV BAR -->
        <bottom-navigation :setpadding="'px-6'" />
    </div>
</template>

<script>
import UserInformationComponent from "../userProfile/UserInformationComponent.vue";
import UserProfileScreen from "../userProfile/UserProfileScreen.vue";
import AppBarComponent from "@/components/AppBarComponent.vue";
import { mapGetters } from "vuex";
import VuePlayer from "@iomariani/vue-player";

export default {
    components: { UserProfileScreen, UserInformationComponent, AppBarComponent, VuePlayer },
    name: "media-page",
    // * DATA
    data: () => ({
        // Video and Audio player attributes
        theater: true,
        overlayBlur: false,
        exclusive: false,
        autoFullscreen: false,
        seekerColor: "#2f96fd",
        audioSources: {
            "audio/mp3": "",
        },
        videoSources: {
            "video/mp4": "",
        },

        // Fuel Stepper
        e1: 1,

        // Random
        exploreId: "",
        shortText: false,
        author: false,
        component: "",
        visible: false,
        loading: "",
        filter: false,
        selectedmedia: {},
        chosenSection: "",
        component: "sections-feed-component",
        coverImage: "",
        showingFullText: false,
        contentCreator: [],

        //grow
        isregistered: false,
        form: {
            courseId: "",
            learnerId: "",
            registrationId: "",
            learnerFirstName: "",
            learnerLastName: "",
            learnerEmail: "",
        },
        launchLink: null,
        regID: null,

        // Favourites and views
        isfavourite: false,
        viewed: false,
        favContent: {},

        // Stats information
        stat: {
            statType: "",
            statRelatedId: "",
            statRelatedType: "",
            statRelatedSection: "",
            createdUserId: "",
            createdOrgId: "",
            createdDate: "",
            enabled: true,
            modifiedDate: null,
            modifiedOrgId: null,
            modifiedUserId: null,
            statRating: null,
            statRelatedTime: null,
            deleted: false,
            deletedDate: "",
            deletedOrgId: "",
            deletedUserId: "",
        },
        defaultStat: {
            statType: "",
            statRelatedId: "",
            statRelatedType: "",
            statRelatedSection: "",
            createdUserId: "",
            createdOrgId: "",
            createdDate: "",
            enabled: true,
            modifiedDate: null,
            modifiedOrgId: null,
            modifiedUserId: null,
            statRating: null,
            statRelatedTime: null,
            deleted: false,
            deletedDate: "",
            deletedOrgId: "",
            deletedUserId: "",
        },
        //currUserdata
        currentUser: {},
    }),
    // * METHODS
    methods: {
        // Reads user information
        async readCurrUser() {
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currentUser = userDataResult.data;
            // console.log("this.currentUser", this.currentUser)
            this.getFavouriteContent();
            this.getViewedContent();
        },
        // Sends the parent the information of what it needs to render next
        creatorProfileLaunch(type, value) {
            this.exploreId = "profile";
            this.MIX_go({ name: "profile-screen", params: { data: value, section: type, userId: "creator/" + `${this.contentCreator.id}` } });
        },
        goexplore() {
            // if (this.fromExplore == true) {
            // this.MIX_go({ name: "explore-screen", params: { data: this.component, section: this.chosenSection } });
            this.MIX_go({ name: "sections-screen", params: { pagesection: this.chosenSection } });
            // } else {
            //     this.MIX_go({ name: "dashboard-screen" });
            // }
        },
        // Save item as favourite
        async addFavouriteContent() {
            if (this.isfavourite == false) {
                try {
                    this.isfavourite = true;
                    // Get Current User Data
                    // Get Current Date Time
                    let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                    this.stat.statType = "heart";
                    this.stat.statRelatedId = this.exploreId;
                    this.stat.statRelatedType = this.selectedmedia[`${this.chosenSection}Type`];
                    this.stat.statRelatedSection = this.chosenSection;
                    this.stat.createdUserId = this.currentUser.id;
                    this.stat.createdOrgId = this.currentUser.userOrgId;
                    this.stat.createdDate = currentDateTime;
                    this.stat.modifiedDate = currentDateTime;
                    this.stat.modifiedOrgId = this.currentUser.userOrgId;
                    this.stat.modifiedUserId = this.currentUser.id;
                    this.stat.statRelatedTime = currentDateTime;
                    // Create Stat
                    await this.REDIS_create("stat", this.stat);
                    this.stat = { ...this.defaultStat };
                } catch (error) {
                    console.error("Error: ", error);
                }
            } else {
                this.isfavourite = false;
                // Get Current User Data
                // Get Current Date Time
                let searchQuery = `@createdUserId:{${this.currentUser.id}} @statRelatedId:{${this.exploreId}} @statType:{heart} @deleted:{false}`;
                let contentResult = await this.REDIS_searchFor("stat", null, null, null, null, searchQuery);
                let favourites = contentResult.data.documents;
                let entityId = favourites[0].entityId;
                let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                this.REDIS_delete("stat", entityId, { deleted: true, deletedUserId: this.currentUser.id, deletedOrgId: this.currentUser.userOrgId, deletedDate: currentDateTime, modifiedUserId: this.currentUser.id, modifiedDate: currentDateTime, modifiedOrgId: this.currentUser.userOrgId, statRelatedType: this.selectedmedia[`${this.chosenSection}Type`] });
            }
        },
        // Save item as viewed
        async addViewedContent() {
            if (this.viewed == false) {
                try {
                    this.viewed = true;
                    // Get Current User Data
                    // Get Current Date Time
                    let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                    this.stat.statType = "view";
                    this.stat.statRelatedType = this.selectedmedia[`${this.chosenSection}Type`];
                    this.stat.statRelatedId = this.exploreId;
                    this.stat.statRelatedSection = this.chosenSection;
                    this.stat.createdUserId = this.currentUser.id;
                    this.stat.createdOrgId = this.currentUser.userOrgId;
                    this.stat.createdDate = currentDateTime;
                    this.stat.modifiedDate = currentDateTime;
                    this.stat.modifiedOrgId = this.currentUser.userOrgId;
                    this.stat.modifiedUserId = this.currentUser.id;
                    this.stat.statRelatedTime = currentDateTime;
                    // Create Stat
                    await this.REDIS_create("stat", this.stat);
                    this.stat = { ...this.defaultStat };
                } catch (error) {
                    console.error("Error: ", error);
                }
            }
        },
        // Gets information on favourite content from redis
        async getFavouriteContent() {
            let searchQuery = `@createdUserId:{${this.currentUser.id}} @statType:{heart} @deleted:{false}`;
            let contentResult = await this.REDIS_searchFor("stat", null, null, null, null, searchQuery);
            let favourites = contentResult.data.documents;
            for (let i = 0; i < favourites.length; i++) {
                if (favourites[i].statRelatedId == this.exploreId) {
                    this.isfavourite = true;
                    this.favContent = favourites[i];
                }
            }
        },
        // Gets information on viewed content from redis
        async getViewedContent() {
            let searchQuery = `@createdUserId:{${this.currentUser.id}} @statType:{view} @deleted:{false}`;
            let contentResult = await this.REDIS_searchFor("stat", null, null, null, null, searchQuery);
            let views = contentResult.data.documents;
            if (views.length == 0) {
                this.addViewedContent();
            } else {
                for (let i = 0; i < views.length; i++) {
                    if (views[i].statRelatedId == this.exploreId) {
                        this.viewed = true;
                    }
                }
                this.addViewedContent();
            }
        },

        //generates the scorm course URL
        // async previewScorm() {
        //     try {
        //         const response = await this.$axios.get(process.env.VUE_APP_API_HOST + "scorm/launch?registrationId=" + this.regID);
        //         if (response.status === 200) {
        //             // console.log(response.data.data?.launchLink);
        //             this.launchLink = response.data.data?.launchLink;
        //             // this.MIX_alertBox({ color: "green", timeout: 2000, message: "Course Generated", show: true, });
        //         }
        //     } catch (error) {
        //         // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Course Preview Error", show: true, });
        //         console.error(error);
        //     }
        // },

        // //gets the registration ID for the scorm course
        // async getRegisterId() {
        //     if (this.chosenSection === "grow") {
        //         // console.log("scormID: " + this.selectedmedia?.growScormId, "userID: " + this.currentUser?.id)
        //         this.$axios
        //             .get(process.env.VUE_APP_API_HOST + "scorm/registrations?courseId=" + this.selectedmedia?.growScormId + "&learnerId=" + this.currentUser?.id)
        //             .then((response) => {
        //                 // console.log(response.data);
        //                 // this.isregistered = true;
        //                 if (response.data.data[0]?.id !== undefined) {
        //                     this.regID = response.data.data[0]?.id;
        //                     this.isregistered = true;
        //                 }
        //                 this.previewScorm();
        //             })
        //             .catch((error) => {
        //                 console.error(error);
        //             });
        //     }
        // },

        //registers the user for the scorm course
        // async registerScorm() {
        //     this.form.registrationId = this.MIX_generateId();
        //     this.form.courseId = this.selectedmedia?.growScormId;
        //     this.form.learnerId = this.currentUser?.id;
        //     this.form.learnerFirstName = this.currentUser?.userName;
        //     this.form.learnerEmail = this.currentUser?.userEmail;
        //     if (this.form.learnerLastName === "") {
        //         this.form.learnerLastName = "N/A";
        //     }
        //     try {
        //         const response = await this.$axios.post(process.env.VUE_APP_API_HOST + "scorm/registrations", this.form);
        //         if (response.status === 200) {
        //             // this.isregistered = true;
        //             this.getRegisterId();
        //             // this.MIX_alertBox({ color: "green", timeout: 2000, message: "Course Registration Successful", show: true, });
        //         }
        //     } catch (error) {
        //         // this.MIX_alertBox({ color: "error", timeout: 2000, message: "Error registering", show: true, });
        //         console.error(error);
        //     }
        // },
    },
    // Media needs a section and content param. If these are not provided this redirects users to the Explore page
    beforeRouteEnter(to, from, next) {
        if (!to.params.section) {
            next("/explore");
        } else {
            next();
        }
    },
    // * COMPUTED
    computed: {
        // Formats the text to show only part unless user clicks Read More
        formattedBody() {
            let text = this.selectedmedia[`${this.chosenSection}Description`];
            if (this.showingFullText) {
                // this.shortText = true;
                return text;
            }
            if (text.length < 88) {
                this.shortText = true;
                return text;
            }
             if (text.length >= 88 && this.contentCreator.length == 0) {
                    return `${text.slice(0, 120).trim()}...`;
                } 
            if (text.length >= 88 && this.contentCreator.length !== 0) {
                    return `${text.slice(0, 80).trim()}...`;
                } 
        },

        async readCreators() {
            // let findcontentCreator = await this.MIX_firestore_readManyWhereCondition("users", "id", "==", `TLyfBMkvnqVRbsimBhAQMRf9TTe2`);
            let findcontentCreator = await this.MIX_firestore_readManyWhereCondition("users", "id", "==", `${this.selectedmedia.creatorUserId}`);

            if (findcontentCreator[0] != undefined || findcontentCreator[0] != null) {
                this.contentCreator = findcontentCreator[0];
            } else {
                this.contentCreator = [];
            }
            return this.contentCreator;
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    // * MOUNTED
    mounted() {
        // Gets the video id from the selected media
        let videoId = this.selectedmedia[`${this.chosenSection}VideoId`];
        let audioId = this.selectedmedia[`${this.chosenSection}AudioId`];

        this.videoSources["video/mp4"] = `${this.MIX_videoURL + "video/" + videoId + ".mp4#t=0.003"}`;
        this.audioSources["audio/mp3"] = `${this.MIX_audioURL + "audio/" + audioId + ".mp3?"}`;
    },
    // * CREATED
    async created() {
        this.loading = true;
        this.selectedmedia = this.$route.params.data;
        this.exploreId = this.$route.params.id;
        this.chosenSection = this.$route.params.section;
        await this.readCurrUser();
        // await this.getRegisterId();
        this.loading = false;
    },
};
</script>
<style lang="scss">
// Audio/video player styles
@import "@iomariani/vue-player/src/scss/vue-player.scss";
.player-controls {
    min-height: 20px;
    padding: 5px 15px 5px 5px;
    background: linear-gradient(to top, #3a3a3a, #505050);
    color: white;
    border-radius: 0%;
}
</style>

<style scoped>
.v-toolbar__content,
.v-toolbar__extension {
    padding: 0;
}

#favButton .v-ripple__container {
    display: none;
}
</style>
<style scoped>
* {
    box-shadow: none !important;
    /* overflow-x: hidden; */
    /* width: 100; */
}

.white-background {
    background-color: white !important;
}

img {
    /* height: 40vh !important; */
    width: 62vw !important;
    display: flex;
    align-content: center !important;
    justify-content: center;
}

.v-icon.iconStyle {
    display: flex !important;
    justify-content: end !important;
    /* background-color: white !important; */
    box-shadow: none !important;
}

/* Audio blur */
.audiobanner {
    z-index: 999 !important;
    bottom: 0px;
    height: 12vh !important;
    width: 100vw !important;
    position: absolute !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); 
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); 
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); 
}

/* Bottom blur */
.footerbanner {
    z-index: 999 !important;
    bottom: 0px;
    height: 18vh !important;
    width: 100vw !important;
    position: absolute !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
