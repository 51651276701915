<template>
    <div v-if="getContent" class="px-4 allowscrolling" style="height: 100vh !important">
        <!-- Menu with all the content to choose from -->
        <!-- HEADER -->
        <app-bar-component app :appbarcolour="'primary'" :setmargin="''" />

        <!-- Buttons for filtering -->

        <!-- PAUSE -->
        <div v-if="section == 'pause'" class="d-flex py-6 overflow-x-auto overflow-y-hidden" id="makeMeWhite">
            <!-- Duration Filter -->
            <v-select v-model="filters.duration" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView-pause' : 'feed-filterbutton-desktopView-pause'" :items="duration" @click:clear="clearFilters('duration', '')" class="rounded-pill mr-2" label="Duration" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-clock </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Time of day Filter -->
            <!-- <v-select v-model="filters.timeOfday" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView-pause' : 'feed-filterbutton-desktopView-pause'" :items="timeOfday" @click:clear="clearFilters('timeOfday', '')" class="rounded-pill" label="Time of Day" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-sun </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select> -->
        </div>

        <!-- MOVE -->
        <div v-if="section == 'move'" class="d-flex align-start py-6 overflow-x-auto overflow-y-hidden" px-4 id="makeMeWhite">
            <!-- Duration -->
            <v-select v-model="filters.duration" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="duration" @click:clear="clearFilters('duration', '')" class="rounded-pill" label="Duration" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-clock </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Difficulty -->
            <v-select v-model="filters.difficulty" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="difficulty" @click:clear="clearFilters('difficulty', '')" class="rounded-pill" label="Difficulty" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-walking </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Focus -->
            <v-select v-model="filters.focus" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView mr-6' : 'feed-filterbutton-desktopView mr-6'" :items="focus" @click:clear="clearFilters('focus', '')" class="rounded-pill" label="Focus" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-body</v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <!-- <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div> -->
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
        </div>

        <!-- FUEL -->
        <div v-else-if="section == 'fuel'" id="makeMeWhite" class="d-flex align-start py-6 overflow-x-auto overflow-y-hidden" style="width: 100vw !important">
            <!-- Duration -->
            <v-select v-model="filters.duration" dense flat solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="duration" @click:clear="clearFilters('duration', '')" class="rounded-pill new_font" label="Duration" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-clock </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`" size="16"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2 white--text" :style="`color:var(--v-${section}-base) !important; font-size: 14px;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div></div>
                        <div class="kindmind-font-b2 white--text" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Calories -->
            <v-select v-model="filters.calories" dense flat solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="calories" @click:clear="clearFilters('calories', '')" class="rounded-pill new_font" label="Calories" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-calories </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`" size="16"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2 white--text" :style="`color:var(--v-${section}-base) !important; font-size: 14px;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div></div>
                        <div class="ml-2 kindmind-font-b2 white--text" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Servings -->
            <v-select v-model="filters.servings" dense flat solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="servings" @click:clear="clearFilters('servings', '')" class="rounded-pill new_font" label="Servings" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-group </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important; letter-spacing: 0 !important;`" size="16"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2 white--text" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div></div>
                        <div class="ml-2 kindmind-font-b2 white--text" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
            <!-- Gluten Free -->
            <!-- <div class="rounded-pill " :class="$vuetify.breakpoint.xsOnly ? `feed-filterbutton-mobileView ${section}` : `feed-filterbutton-desktopView ${section}`"> -->
            <!-- <v-checkbox v-model="filters.glutenFree" :label="`Gluten Free`" ></v-checkbox> -->
            <!-- </div> -->
            <v-btn :class="$vuetify.breakpoint.xsOnly ? `feed-filterbutton-mobileView ${section}` : `feed-filterbutton-desktopView ${section}`" style="height: 100% !important; letter-spacing: 0 !important" filter filled class="rounded-pill py-3 white--text text-capitalize new_font" @click="filters.glutenFree = !filters.glutenFree">
                Gluten Free
                <v-icon class="ml-2" size="16">{{ filters.glutenFree ? "icons8-no-gluten" : "icons8-gluten" }}</v-icon>
            </v-btn>
            <!-- Nut Free -->
            <v-btn :class="$vuetify.breakpoint.xsOnly ? `feed-filterbutton-mobileView ${section}` : `feed-filterbutton-desktopView ${section}`" style="height: 100% !important; letter-spacing: 0 !important" filter filled class="rounded-pill py-3 white--text text-capitalize new_font" @click="filters.nutFree = !filters.nutFree">
                Nut Free
                <v-icon class="ml-2" size="16">{{ filters.nutFree ? "icons8-no-peanut" : "icons8-peanuts" }}</v-icon>
            </v-btn>
            <!-- Lactose Free -->
            <v-btn :class="$vuetify.breakpoint.xsOnly ? `feed-filterbutton-mobileView ${section}` : `feed-filterbutton-desktopView ${section}`" style="height: 100% !important; letter-spacing: 0 !important" filter filled class="rounded-pill py-3 white--text text-capitalize new_font mr-6" @click="filters.lactoseFree = !filters.lactoseFree">
                Lactose Free
                <v-icon class="ml-2" size="16">{{ filters.lactoseFree ? "icons8-non-lactose-food" : "icons8-milk-carton-2" }}</v-icon>
            </v-btn>
        </div>

        <!-- GROW -->
        <div v-if="section == 'grow'" class="d-flex align-start py-6" id="makeMeWhite" style="width:48vw !important;">
        <!-- Duration Filter -->
        <v-select v-model="filters.duration" flat dense solo filled :background-color="section" label-color="white"  :items="duration" @click:clear="clearFilters('duration', '')" class="rounded-pill" label="Duration" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-clock </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2 " style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select>
        <!-- Difficulty Filter -->
        <!-- <v-select v-model="filters.difficulty" flat dense solo filled :background-color="section" label-color="white" :class="$vuetify.breakpoint.xsOnly ? 'feed-filterbutton-mobileView' : 'feed-filterbutton-desktopView'" :items="difficulty" @click:clear="clearFilters('difficulty', '')" class="rounded-pill" label="Difficulty" color="white" :menu-props="{ top: false, offsetY: true }" :attach="false" clearable>
                <template v-slot:append>
                    <v-icon size="20" class="pr-1"> icons8-walking </v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon :style="`color:var(--v-${section}-base) !important;`"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" :style="`color:var(--v-${section}-base) !important;`">{{ item.text }}</div>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <div>
                            <v-icon style="color: white"> {{ item.icon }}</v-icon>
                        </div>
                        <div class="ml-2" style="color: white">{{ item.text }}</div>
                    </div>
                </template>
            </v-select> -->
        </div>

        <!-- Carousel Component -->
        <activity-buttons-component :key="compkey" :datadown="content" :chosenSection="section" :inPageSections="sectionsFeed" @selectedcontent="openSelectedMedia" />

        <!-- BOTTOM NAV -->
        <div  class="footerbanner"></div>
        <div style="margin-bottom: 128px"></div>
        <bottom-navigation :setpadding="'px-4'" />
    </div>
</template>

<script>
import ActivityButtonsComponent from "@/components/ActivityButtonsComponent.vue";

export default {
    components: { ActivityButtonsComponent },
    name: "sections-screen",
    // * DATA
    data: () => ({
        section: "",
        compkey: 0,
        content: [],

        // Filters and options for the filters
        duration: [
            { text: "0 - 10", icon: "icons8-clock" },
            { text: "10 - 20", icon: "icons8-clock" },
            { text: "20 - 30", icon: "icons8-clock" },
            { text: "30+", icon: "icons8-clock" },
        ],
        selectedDuration: ["0 10", "10 20", "20 30", "30 300"],
        timeOfday: [
            { text: "Morning", icon: "icons8-sun" },
            { text: "Afternoon", icon: "icons8-partly-cloudy-day" },
            { text: "Night", icon: "icons8-moon-symbol" },
        ],
        difficulty: [
            { text: "Beginner", icon: "" },
            { text: "Intermediate", icon: "" },
            { text: "Advanced", icon: "" },
        ],
        focus: [
            { text: "Neck", icon: "icons8-body" },
            { text: "Shoulder", icon: "icons8-body" },
            { text: "Back", icon: "icons8-body" },
            { text: "Core", icon: "icons8-body" },
            { text: "Chest", icon: "icons8-body" },
            { text: "Lower Back", icon: "icons8-body" },
            { text: "Hips", icon: "icons8-body" },
            { text: "Knees", icon: "icons8-body" },
            { text: "Foot & Ankle", icon: "icons8-body" },
        ],
        servings: [
            { text: "2 - 4", icon: "icons8-group" },
            { text: "4 - 8", icon: "icons8-group" },
            { text: "8+", icon: "icons8-group" },
        ],
        selectedServings: ["2 4", "4 8", "8 100"],
        calories: [
            { text: "Less than 500", icon: "icons8-calories" },
            { text: "Less than 800", icon: "icons8-calories" },
            { text: "Less than 1000", icon: "icons8-calories" },
        ],
        selectedCalories: ["1 500", "501 800", "801 1000"],
        filters: {
            searchQuery: "",
            duration: "",
            timeOfday: "",
            difficulty: "",
            servings: "",
            calories: "",
            focus: "",
            glutenFree: false,
            nutFree: false,
            lactoseFree: false,
        },
    }),
    computed: {
        /**
         *  Pulls content from redis. Paremeter: section which filters the content fetched depending on the section
         * */
        async getContent() {
            try {
                let section = this.section;
                // console.log("computedSearchQuery", this.computedSearchQuery);
                let contentResult = await this.REDIS_searchFor(`${section}`, null, null, null, `${section}Title`, `${this.computedSearchQuery}`);
                // console.log("contentResult", contentResult.data.documents);
                this.compkey++;
                return (this.content = contentResult.data.documents);
            } catch (error) {
                console.error("Error: ", error);
            }
        },
        // Sends the filters per category to the child component
        sectionsFeed() {
            let sectionsFeed = [];
            switch (this.section) {
                case "fuel":
                    sectionsFeed = [
                        { type: "Low Carb", content: [] },
                        { type: "High Protein", content: [] },
                        { type: "Vegetarian", content: [] },
                        { type: "Quick", content: [] },
                        { type: "Meal Prep Friendly", content: [] },
                        // { type: "Gluten Free", content: [] },
                        // { type: "Contains Nuts", content: [] },
                        // { type: "Dairy Free", content: [] },
                    ];
                    break;
                case "move":
                    sectionsFeed = [
                        { type: "Workout", content: [] },
                        { type: "Cardio", content: [] },
                        { type: "Mobility", content: [] },
                        { type: "Yoga", content: [] },
                    ];
                    break;
                case "pause":
                    sectionsFeed = [
                        // { type: "Meditation", content: [] },
                        { type: "Wake", content: [] },
                        { type: "Focus", content: [] },
                        { type: "Self-Care", content: [] },
                        { type: "Relax", content: [] },
                        { type: "Sleep", content: [] },
                    ];
                    break;
                case "grow":
                    sectionsFeed = [
                        // { type: "Communication", content: [] },
                        { type: "Employability", content: [] },
                        // { type: "Personal Skills", content: [] },
                        // { type: "Emotional Intelligence", content: [] },
                        { type: "Health", content: [] },
                        // { type: "Remote Working", content: [] },
                        // { type: "Productivity", content: [] },
                        // { type: "Stress Reduction", content: [] },
                        { type: "Finances", content: [] },
                    ];
                    break;
            }
            return sectionsFeed;
        },

        // Computed Search Query
        computedSearchQuery() {
            let searchQuery = `@deleted:{false} @enabled:{true} @${this.section}Status:{Live}`;
            // MOVE & GROW - Additional Filters - Difficulty
            if (this.filters.difficulty !== "" && this.filters.difficulty !== null && this.filters.difficulty !== undefined) {
                // replace spaces in the search query with \\
                switch (this.section) {
                    case "pause":
                        searchQuery += ` @pauseDifficulty:{${this.filters.difficulty.replace(/\s/g, " ")}}`;
                        break;
                    case "move":
                        searchQuery += ` @moveDifficulty:{${this.filters.difficulty.replace(/\s/g, " ")}}`;
                        break;
                    case "fuel":
                        searchQuery += ` @fuelDifficulty:{${this.filters.difficulty.replace(/\s/g, " ")}}`;
                        break;
                    case "grow":
                        searchQuery += ` @growDifficulty:{${this.filters.difficulty.replace(/\s/g, " ")}}`;
                        break;
                    default:
                        searchQuery = `@deleted:{false}`;
                        break;
                }
            }

            // MOVE - Additional Filters - Focus
            if (this.filters.focus !== "" && this.filters.focus !== null && this.filters.focus !== undefined) {
                // replace spaces in the search query with \\
                searchQuery += ` @moveTags:{${this.filters.focus.replace(/\s/g, " ")}}`;
            }

            // PAUSE - Additional Filters - Servings
            if (this.filters.timeOfday !== "" && this.filters.timeOfday !== null && this.filters.timeOfday !== undefined) {
                // replace spaces in the search query with \\
                searchQuery += ` @pauseTime:{${this.filters.timeOfday.replace(/\s/g, " ")}}`;
            }

            //Additional Filters - Duration
            let selectedDuration = "";
            switch (this.filters.duration) {
                case "0 - 10":
                    selectedDuration = this.selectedDuration[0];
                    break;
                case "10 - 20":
                    selectedDuration = this.selectedDuration[1];
                    break;
                case "20 - 30":
                    selectedDuration = this.selectedDuration[2];
                    break;
                case "30+":
                    selectedDuration = this.selectedDuration[3];
                    break;
                default:
                    selectedDuration = "";
                    break;
            }

            if (selectedDuration !== "" && selectedDuration !== null && selectedDuration !== undefined) {
                // replace spaces in the search query with \\
                switch (this.section) {
                    case "pause":
                        searchQuery += ` @pauseDurationMin:[${selectedDuration.replace(/\s/g, " ")}]`;
                        break;
                    case "move":
                        searchQuery += ` @moveDurationMin:[${selectedDuration.replace(/\s/g, " ")}]`;
                        break;
                    case "fuel":
                        searchQuery += ` @fuelPrepMins:[${selectedDuration.replace(/\s/g, " ")}]`;
                        break;
                    case "grow":
                        searchQuery += ` @growDurationMin:[${selectedDuration.replace(/\s/g, " ")}]`;
                        break;
                    default:
                        searchQuery = `@deleted:{false}`;
                        break;
                }
            }
            // FUEL - Additional Filters - Calories
            let selectedCalories = "";
            switch (this.filters.calories) {
                case "Less than 500":
                    selectedCalories = this.selectedCalories[0];
                    break;
                case "Less than 800":
                    selectedCalories = this.selectedCalories[1];
                    break;
                case "Less than 1000":
                    selectedCalories = this.selectedCalories[2];
                    break;
                default:
                    selectedCalories = "";
                    break;
            }

            if (selectedCalories !== "" && selectedCalories !== null && selectedCalories !== undefined) {
                // replace spaces in the search query with \\
                searchQuery += ` @fuelKcal:[${selectedCalories.replace(/\s/g, " ")}]`;
            }

            // FUEL - Additional Filters - Servings
            let selectedServings = "";
            switch (this.filters.servings) {
                case "2 - 4":
                    selectedServings = this.selectedServings[0];
                    break;
                case "4 - 8":
                    selectedServings = this.selectedServings[1];
                    break;
                case "8 - 100":
                    selectedServings = this.selectedServings[2];
                    break;
                default:
                    selectedServings = "";
                    break;
            }
            if (selectedServings !== "" && selectedServings !== null && selectedServings !== undefined) {
                // replace spaces in the search query with \\
                searchQuery += ` @fuelServes:[${selectedServings.replace(/\s/g, " ")}]`;
            }

            // FUEL - Additional Filters - Servings
            if (this.filters.glutenFree !== "" && this.filters.glutenFree !== null && this.filters.glutenFree !== undefined && this.filters.glutenFree === true) {
                // replace spaces in the search query with \\
                searchQuery += ` @fuelFeatures:{Gluten Free}`;
            }
            if (this.filters.lactoseFree !== "" && this.filters.lactoseFree !== null && this.filters.lactoseFree !== undefined && this.filters.lactoseFree === true) {
                // replace spaces in the search query with \\
                searchQuery += ` @fuelFeatures:{Dairy Free}`;
            }
            if (this.filters.nutFree !== "" && this.filters.nutFree !== null && this.filters.nutFree !== undefined && this.filters.nutFree === true) {
                // replace spaces in the search query with \\
                searchQuery += ` -@fuelFeatures:{Contains Nuts}`;
            }

            return searchQuery;
        },
    },

    // * METHODS
    methods: {
        // Receives the information passed from ActivityButtonsComponent and converts it into an object so it can be passed down to the media view
        openSelectedMedia(value, section) {
            this.MIX_go({ name: "media-page", params: { data: value, section: section, explore: this.fromExplore, id: `${value.entityId}` } });
        },
        // Clear Filter
        clearFilters(filter, value) {
            let t = this;
            this.backgroundColorSet = "";
            setTimeout(() => {
                t.filters[filter] = value;
                t.getContent();
            }, 1);
        },
    },

    // * CREATED
    async created() {
        this.section = this.$route.params.pagesection;
        // console.log("this.section", this.section);
    },
};
</script>

<style>
/*
  These are the selectors controlling the selected item
  in v-autocomplete and v-select
*/

.v-app-bar-title__content {
    width: 100% !important;
}
.v-app-bar-title__placeholder {
    padding-top: 10px !important;
    line-height: 40px !important;
    font-size: 14px;
}

.v-select .white--text {
    color: white !important;
}
#makeMeWhite .v-label,
.v-btn .new_font {
    color: white !important;
    font-size: 15px;
    font-family: Helvetica !important;
    font-weight: 50;
}
#makeMeWhite .v-select {
    font-size: 11px !important;
}
#makeMeWhite .v-icon {
    color: white !important;
}
</style>

<style scoped>
* {
    box-shadow: none !important;
}

/* 
Style for top filters single or multiple depending on how many filters there are */

.feed-filterbutton-mobileView-pause {
    height: 4vh !important;
    /* min-width: 28vw !important; */
    max-width: clamp(138px, 22vw, 328px) !important;
}

.feed-filterbutton-desktopView-pause {
    height: 4vh !important;
    min-width: 30vw !important;
    max-width: 30vw !important;
    /* max-width: clamp(228px, 28vw, 288px) !important; */
    /* width: clamp(28px, 8vw, 88px) !important; */
}

.feed-filterbutton-mobileView {
    height: 4vh !important;
    min-height: 4vh !important;
    min-width: 32vw;
    margin-right: 4px;
}

.feed-filterbutton-desktopView {
    height: 4vh !important;
    margin-right: 8px;
}

/* bottom blur */
.footerbanner {
    z-index: 999 !important;
    bottom: 0px;
    height: 18vh !important;
    width: 100vw !important;
    position: absolute !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
