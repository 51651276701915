import { mapActions, mapGetters } from "vuex";

const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_API_HOST,
    }),
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: "GET_firebase_userAuth",
        }),
    },
    methods: {
        ...mapActions({
            ACT_alertBox: "ACT_alertBox",
        //     ACT_puDialog: 'ACT_puDialog'
        }),
        MIX_go(path) {
            this.$router.push(path).catch((err) => {
                // console.log("Route error: " + err);
            });
        },
        MIX_alertBox: function (payload) {
			this.ACT_alertBox(payload);
		},
        MIX_goExternal(path) {
            window.open(path, '_blank').focus();
        },

        // Gets current user info
        MIX_getUser() {
            this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
        },
        // Branding Mixins
        MIX_brandingTheme(chosenBranding){
            let t = this;
            t.$vuetify.theme.themes.light.primary = chosenBranding.brandingPrimaryColour;
            t.$vuetify.theme.themes.light.secondary = chosenBranding.brandingSecondaryColour;
        },
        MIX_brandingImg(chosenBranding) {
            return this.MIX_imageURL + ('images/landscape/' + chosenBranding.brandingLogo + '.jpeg');

        },
        
        MIX_formatDateTime: function (date, formatIn, formatOut) {
            if (date !== "" && date !== null && date !== undefined && date != 0) {
                return this.$moment(date, formatIn).format(formatOut);
            } else {
                return this.$moment().format(formatOut);
            }
        },
        MIX_addFormatDateTime: function (data, formatIn, formatOut, timeValue, timeUnit) {
            if (data !== "" && data !== null && data !== undefined && data != 0) {
                return this.$moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
            } else {
                return this.$moment().add(timeValue, timeUnit).format(formatOut);
            }
        },
        MIX_dayTimeFormat(date, shortLong){
            let dt = Date.parse(date);  
            let formatedDate ="";
            if( shortLong === "short"){
                formatedDate = this.$moment(dt).format('DD MMM YY');
            }else if(shortLong === 'long'){
                formatedDate = this.$moment(dt).format('dddd Do MMMM \'YY');
            }
           return formatedDate
        },
        MIX_fromNow: function (date, formatIn, ago) {
            return this.$moment(date, formatIn).fromNow(ago);
        },
        MIX_smalldevice: function () {
            return this.window.innerHeight < 600;
        },
        /* Add To Local Storage
        * Add the new value to local storage by key name.
        *
        * @param key - the name to store the value against
        * @param value - the value to store against the key
        */
        MIX_addToLocalStorage(key, value) {
            localStorage.setItem(key, JSON.stringify(value))
          },
          /* Get From Local Storage
            * Get a value from local storage by its key name.
            * @param key - the key name to retrieve the value for
            * @returns {any} - the value */
         MIX_getFromLocalStorage(key) {
           return JSON.parse(localStorage.getItem(key))
         },
         MIX_generateId() {
			let generatedId = "";
			const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
			for (let i = 0; i < 20; i++) {
				generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return generatedId;
		},
    }
}


export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
