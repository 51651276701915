<template>
<!-- :class="requirementColor" -->
    <div  :size="sizeavatar" :class="requirementColor" :height="heightavatar" class="roundlogo" style="margin-top: -58px !important; width: 168px; height: 142px;">
        <div style="z-index:99 !important;" class="d-flex justify-center" :class="padding">
        <img :src="pageLogo" alt="Company's Log" width="70%" >

        </div>
        
    </div>

</template>

<script>
export default {
    name: "logo-initial-questions",
    props: ["requirementColor", "padding", "sizeavatar", "widthavatar", "heightavatar"],
    data: () => ({
        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),
};
</script>

<style scoped>

.roundlogo {
    border-top-left-radius:60% !important;
    border-top-right-radius:60% !important;
}
</style>
