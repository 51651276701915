<template>
    <v-snackbar top transition="true"  v-model="alertData.show" :color="alertData.color" :timeout="alertData.timeout">
        {{ alertData.message }}
    </v-snackbar>
</template>

<script>
export default {
    name: 'snackbar-component',
    props: {
        alertbox: {
            type: Boolean,
            default: false,
        },
        alertdata: {
            type: Object,
            default: () => {
                return {            
                    title: "",
                    message: "",
                    color: "",
                    timeout: "2000",
                    show: false
                };
            },
        },
    },
    data: () => ({
        timeout: "2000",
        alertData: {
            title: "",
            message: "",
            color: "",
            timeout: "2000",
            show: false,
        },
    }),
    watch: {
        alertdata: {
            handler() {
                this.alertData = { ...this.alertdata };
            }, deep: true
        },
    },
    created() {
        this.alertData = this.alertdata;
    },
};
</script>

<style>
* {
    box-shadow: none !important;
}
</style>
