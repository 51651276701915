<template>
    <div id="app" class="ma-0 pa-0">
        <v-app class="ma-0 pa-0">
            <!-- <v-btn @click="updateexists = true">Fake Update</v-btn> -->

            <v-main style="min-height: 100vh !important; width: 100vw !important">
                <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in"> <router-view /></transition>
                
                <!-- Polls Popup -->
                    <polls-popup-component v-if="pollsAvailable && GET_FIREBASE_userAuth && !$route.meta.hidePoll && !loading" :popupVisible="pollsAvailable" :pollsdata="pollsData" :currentUser="currentUser" @closePollPopUp="closePopup" class="poll-popup"/>
        
                <!-- <router-view :smalldevice="smalldevice" /></transition> -->
            </v-main>

            <!-- App Update Component -->
            <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                <appupdate-component />
            </transition>
        </v-app>
    </div>
</template>

<script>
import PollsPopupComponent from "./components/PollsPopupComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: { PollsPopupComponent },
    // * COMPONENTS
    data: () => ({
        content: [],

        // Polls data
        loading: false,
        pollsData: [],
        pollsAvailable: false,
        e1: 0,
        pollAnswer: "",
        currentPollIndex: 0,

        // Polls results
        pollResults: false,
        currentPollResult: null,
        allPollAnswers: [],
    }),
    // * COMPUTED
    computed: {
        smalldevice() {
            return window.innerHeight < 600;
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            GET_currentUser: "GET_currentUser",
        }),
    },

    //MOUNTED
    mounted() {
        if (window.safari) {
            history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                history.go(1);
            };
            // window.onbeforeunload = function() {
            //   return "Please use the arrows in the application to move from one page to another.";
            // }
        }
    },

    // * METHODS
    methods: {
        closePopup() {
            this.pollsAvailable = false;
            this.getTodayPolls();
        },
        // Retrives userName and cuts it so it only returns the first name
        async getUserInfo() {
            this.loading = true;
            let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currentUser = currUserResult.data;
            // console.log("Current User: ", this.currentUser.onboarding);

            this.getTodayPolls();
        },
        // Polls information
        async getTodayPolls() {
            this.pollsData = [];
            this.content = [];
            // let userLogged = localStorage.getItem('userLogged');

            // if (userLogged == 'true') {
            // Gets the current date and time
            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));

            // Gets the polls from the database
            let searchQuery = `@deleted:{false} @questionSection:{Poll} @enabled:{true}`;
            let contentResult = await this.REDIS_searchFor(`question`, null, null, null, null, searchQuery);
            this.content = contentResult.data.documents;

            // Checks user organisation and filters the polls to only show the ones that are for the user's organisation and department
            //if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                let usersMatchesOrg = this.content.filter((content) => content.questionOrgRecipient == this.currentUser.userOrgId);
                let usersMatchesDepartment = usersMatchesOrg.filter((content) => content.questionDepartment == this.currentUser.userDepartment || content.questionDepartment == "N/A" || content.questionDepartment == "Other" || content.questionDepartment == "");
                this.content = usersMatchesDepartment;

           // }

            // Checks if the polls are within the date range
            for (let i = 0; i < this.content.length; i++) {
                let unixStartDate = this.MIX_formatDateTime(this.content[i].questionFrom, "YYYY-MM-DDTHH:mm:ss.SSSZ", "X");
                let unixEndDate = this.MIX_formatDateTime(this.content[i].questionTo, "YYYY-MM-DDTHH:mm:ss.SSSZ", "X");
                if (unixStartDate <= currentDateTime && unixEndDate >= currentDateTime) {
                    this.pollsData.push(this.content[i]);
                }
            // }

            // decides whether the user has already replied to the poll or not
            for (let poll of this.pollsData) {
                let searchQuery = `@createdUserId:{${this.currentUser.id}} @deleted:{false} @questionId:{${poll.entityId}}`;
                let contentResult = await this.REDIS_searchFor("useranswer", null, null, null, null, searchQuery);
                let pollAnswer = contentResult.data.documents;
                // console.log("Poll Answer: ", pollAnswer)
                poll.alreadyRepliedTo = pollAnswer && pollAnswer.length > 0;
            }
            // Create a new list of polls where alreadyRepliedTo is false
            const unrepliedPolls = this.pollsData.filter((poll) => !poll.alreadyRepliedTo);
            this.pollsData = [];
            // sorts data - newest first
            unrepliedPolls.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
            this.pollsData = unrepliedPolls;
            // console.log("Polls Data 2: ", this.pollsData);
            
            if (this.pollsData.length > 0) {
                this.pollsAvailable = true;
            }
            }
            this.loading = false;
        },
    },

    watch: {
        // Watch for changes to the route object
    '$route'(to, from) {
      // Your logic here
    //   console.log('Route has changed!', to, from);
    if (this.GET_FIREBASE_userAuth) {
         this.getUserInfo();
    }
    }
    },
 
    // * CREATED
    created() {
        //console.log('API URL: ', process.env.VUE_APP_API_HOST);
        if (this.GET_FIREBASE_userAuth) {
            this.getUserInfo();
        }
    },
};
</script>

<style lang="scss">
// @media screen and (min-aspect-ratio: 13/9) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     height: 100%;
//     width: 100%;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }

// }
// @media screen and (min-width: 1292px) {
//   html {
//     transform: none;
//     transform-origin: none;
//     width: 100%;
//     overflow-x: none;
//     position: relative;
//   }

// }

.poll-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

html,
body {
    overscroll-behavior-x: none !important;
}

@font-face {
    font-family: "DMSans";
    src: url("./assets/fonts/dm-sans-cufonfonts/DMSans-Regular.ttf");
}

@font-face {
    font-family: "DMSansBold";
    src: url("./assets/fonts/dm-sans-cufonfonts/DMSans-Bold.ttf");
}

@font-face {
    font-family: "P22Mackinac";
    src: url("./assets/fonts/P22 Mackinac/P22Mackinac-Medium_6.otf");
}

@font-face {
    font-family: "P22Mackinac-Bold";
    src: url("./assets/fonts/P22 Mackinac/P22Mackinac-Bold_23.otf");
}

@font-face {
    font-family: "HelveticaNeue";
    src: url("@/assets/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf");
}

// @font-face {
//     font-family: 'HelveticaNeueMed';
//     src: url('@/assets/fonts/Helvetica-Neue-Font/Helvetica Neue W01 66 Medium It.ttf');
// }
@font-face {
    font-family: "HelveticaNeueBold";
    src: url("@/assets/fonts/Helvetica-Neue-Font/Helvetica Neu Bold.ttf");
}
// Global font overrides do not touch!
// DM Sans
.kindmind-DMSans-font-h4-bold {
    font-family: "DMSansBold", Helvetica, Arial, sans-serif !important;
    font-size: 38px !important;
    letter-spacing: 0.1em;
}
.kindmind-DMSans-font-h5-bold {
    font-family: "DMSansBold", Helvetica, Arial, sans-serif !important;
    font-size: 24px !important;
    letter-spacing: 0.1em;
}
.kindmind-DMSans-font-h6-bold {
    font-family: "DMSansBold", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
    letter-spacing: 0.1em;
}
// Helvetica
.kindmind-font-h1-helv {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    font-weight: 900 !important;
    letter-spacing: 0.1em;
    color: black !important;
}
.kindmind-font-h1-helv-bd {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    letter-spacing: 0.1em;
}
// * Body font
.kindmind-font-bold-h1 {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 32px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h1 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 96px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h2 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 60px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h3 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 48px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h4 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 34px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h4-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 34px !important;
}

.kindmind-font-h5-5 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 26px !important;
    letter-spacing: 0.1em;
    // font-weight: 900 !important;
    // color: black !important;
}
.kindmind-font-h5-5-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 26px !important;
    letter-spacing: 0.1em;
    // font-weight: 900 !important;
    // color: black !important;
}
.kindmind-font-h5 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 24px !important;
    // font-weight: lighter !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h5-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 24px !important;
    // font-weight: lighter !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}
.kindmind-font-h5-line {
    font-family: "HelveticaNeue=", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: 0.1em;
    // font-weight: 900 !important;
    color: black !important;
}
.kindmind-font-h6 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
    font-weight: 900 !important;
    // color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h6-5 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 22px !important;
    font-weight: 900 !important;
    // color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h6-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h6-thin {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
    // font-weight: lighter !important;
    // font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h7 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    font-weight: 900 !important;
    letter-spacing: 0.1em;
    color: black !important;
}

.kindmind-font-h7-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-h7-thin {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 18px !important;
    // font-weight: lighter !important;
    //     font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}
.kindmind-font-icons {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 34px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-b1 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-b1-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-b2 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    letter-spacing: 0.1em;
    color: black !important;
}

.kindmind-font-cap {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    letter-spacing: 0.1em;
    color: black !important;
}

.kindmind-font-cap-line {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 12px !important;
    line-height: 12px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-cap-line-s {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 10px !important;
    line-height: 12px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-cap-5 {
    font-family: "HelveticaNeue", Helvetica, Arial, sans-serif !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    color: black !important;
    letter-spacing: 0.1em;
}

.kindmind-font-cap-bold {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 12px !important;
    letter-spacing: 0.1em;
}

.kindmind-font-cap-s {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 10px !important;
    letter-spacing: 0.1em;
}
.kindmind-font-xs {
    font-family: "HelveticaNeueBold", Helvetica, Arial, sans-serif !important;
    font-size: 8px !important;
    letter-spacing: 0.1em;
}
// * Title font
.kindmind-title-font-h7-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
}

.kindmind-title-font-h6-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
}

.kindmind-title-font-h6 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 20px !important;
}

.kindmind-title-font-h5-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 24px !important;
}

.kindmind-title-font-h5 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 24px !important;
}

.kindmind-title-font-h5-5 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 30px !important;
}

.kindmind-title-font-h4-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 34px !important;
}

.kindmind-title-font-h4 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 34px !important;
}

.kindmind-title-font-h3-4 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 42px !important;
}

.kindmind-title-font-h3 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 48px !important;
}

.kindmind-title-font-h3-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 48px !important;
}
.kindmind-title-font-h2 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 60px !important;
}

.kindmind-title-font-h1 {
    font-family: "P22Mackinac", Helvetica, Arial, sans-serif !important;
    font-size: 96px !important;
}

.kindmind-title-font-h1-bold {
    font-family: "P22Mackinac-Bold", Helvetica, Arial, sans-serif !important;
    font-size: 32px !important;
}

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
.bottom {
    position: absolute;
    bottom: 0;
}
.bottomlogo {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px !important;
}
.rounded-km {
    border-radius: 20px 20px 20px 20px !important;
}
.rounded-km-top {
    border-radius: 30px 30px 0px 0px !important;
}
.rounded-km-bottom {
    border-radius: 0px 0px 20px 20px !important;
}
.rounded-km-left {
    border-radius: 0px 20px 20px 0px !important;
}
// .v-btn__content {
//     display: flex;
//     flex-direction: column;
// }
.fullviewpoint {
    height: 100vh !important;
}

html,
body {
    touch-action: pan-x pan-y !important;
}

::-webkit-scrollbar {
    display: none !important;
}

.allowscrolling {
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>
