<template>
    <div class="allowscrolling fullviewpoint background-view white pa-0 ma-0 " >
            <!-- <div v-show="!loading" class="pt-5 kindmind-font-h4-bold text-center black--text" style="background-color: rgba(0, 0, 0, 0.7) !important;">Hello {{ firstName }}
            <div class="mt-0 text-center black--text" :class="smalldevice ? 'caption' : 'body-1'">
                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold black--text' : 'kindmind-font-h6-bold black--text'" >As this is your first time here, we need to work out what type of activities you enjoy the most.</div>
                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1-bold black--text' : 'kindmind-font-h6-bold black--text'">
                    To get started, click on the <span class="text-capitalize">{{ activeButton }}</span> button
                </div>
            </div>  -->
            
        <!-- Component for buttons -->
        <component :is="component" class="background-overlay" :activeButton="activeSection" @sectionsMenu="component='requirement-questions'"></component>
    </div>
</template>

<script>

export default {
    name: "home-screen",
    
    // * DATA
    data: () => ({ 
        activeSection: '',
        component: "requirement-questions"
        // 'requirement-questions'
    }),
    // * CREATED
    created() {
        // gets the section being passed to local storage and sets activeSection equal to it
        let section = localStorage.getItem('section');
        //activeSection is passed as a prop to the buttons to tell them which one is active
        this.activeSection = section;
    },
};
</script>

<style scoped>
/* Styling for the overlay */
.background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
      margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}


.background-view {
    position: relative !important;
}

.background-view::before {
    content: "";
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 9999 !important;
}

</style>