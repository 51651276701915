<template>
    <div class="d-flex align-start fullviewpoint primary">
        <v-row>
            <v-col cols="12" xs="12" class="d-flex flex-column align-center justify-center fullviewpoint">
                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h6 pb-8' : 'kindmind-title-font-h5 pb-8'" class="white--text text-center"> {{message}}</div>
                <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h7 pb-12' : 'kindmind-title-font-h6 pb-12'" class="white--text text-center">{{ messageTwo }}</div>
                <v-progress-circular :rotate="360" :size="$vuetify.breakpoint.xsOnly ? 220 : 310" :width="10" :value="percentageValue" color="white" class="kindmind-font-h2"> {{ percentageValue }}% </v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "percetage-component",
    // * DATA
    data: () => ({
        questionId: "completed",
        interval: {},
        percentageValue: 0,
        message: "",
        messageTwo: "",
    }),
    // * MOUNTED
    mounted() {
        // Times out the loading circle
        setTimeout(() => {
            this.$emit("sectionsMenu");
        }, 3000);
    },
    // * METHODS
    methods: {
        dynamicMessage(messageValue) {
        if (messageValue == 30) {
            this.message = "Thank you for answering honestly.";
            this.messageTwo = " It's time to set up your profile";
        } else if (messageValue == 50) {
            this.message = "You are are halfway there";
        } else if (messageValue == 70) {
            this.message = "Thank you for answering honestly.";
        } else if (messageValue == 90) {
            this.message = "Thank you for answering honestly.";
        } else if (messageValue == 100) {
            this.message = "You are all done";
        }
        }
    },
    // * COMPUTED
    computed: {
        // Check the height of the device
        smalldevice() {
            return window.innerWidth < 840;
        },
    },
    // * CREATED
    created() {
        this.questionId = this.$route.params.questionId;
        this.dynamicMessage(localStorage.getItem("progress"));
        // Sets the value of the progress circle from localStorage
        this.interval = setInterval(() => {
            if (this.percentageValue === 100) {
                return (this.percentageValue = 0);
            }
            this.percentageValue = localStorage.getItem("progress");
        }, 800);
        
    },
};
</script>
