<template>
    <div class="home pa-5 text-center d-flex flex-column align-center justify-center allowscrolling primary" style="height: 100vh !important">
        <img :src="pageLogo" alt="Company's Logo" class="splashPage-image" />

        <div class="white--text">
            <div :class="smalldevice ? 'mt-12 kindmind-title-font-h5-bold' : 'mt-14 kindmind-title-font-h4-bold'">Welcome to Kind Mind</div>
            <div :class="smalldevice ? 'mt-4 kindmind-font-b1' : 'mt-4 kindmind-font-h6'">Your daily wellness recommendations at your fingertips</div>
            <div class="mt-10">
                <v-progress-circular :size="100" :width="5" color="white" indeterminate></v-progress-circular>
            </div>
        </div>
    </div>
</template>

<script>
import { set } from 'vue';
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
    name: "auth-login",
    components: {},
    data: () => ({
        // page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),

    // * BEFORE MOUNT
    // beforeMount() {
    //     let t = this;
    //     let brandingData = t.GET_setBranding;
    //     console.log(brandingData);
    //     if (brandingData.brandingName != "KindMind" && brandingData.brandingName != undefined) {
    //         t.$vuetify.theme.themes.light.primary = brandingData.brandingPrimaryColour;
    //         t.$vuetify.theme.themes.light.secondary = brandingData.brandingSecondaryColour;
    //         t.pageLogo = this.MIX_imageURL + ("images/landscape/" + brandingData.brandingLogo + ".jpeg");
    //     }
    // },
    // * MOUNTED
    mounted() {
        let t = this;
        if (t.$route.query.q || (t.GET_setBranding && t.GET_setBranding.brandingName)) {
            setTimeout(() => {
                t.getBranding();
            }, 500);
        }
        // Times out the view
        setTimeout(() => {
            this.$router.push("/dashboard");
        }, 3000);
    },

    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_setBranding: "GET_setBranding",
        }),
        // Check the height of the device
        smalldevice() {
            return window.innerWidth < 840;
        },
    },

    // * METHODS
    methods: {
        ...mapActions({
            ACT_setBranding: "ACT_setBranding",
        }),

        // Gets whether the user is loggin with a specific brand to set the colour schema
        async getBranding() {
            let t = this;
            t.ACT_setBranding({});
            let result = await t.REDIS_searchFor("branding", null, null, null, null, `@brandingName:{${t.$route.query.q}} @deleted:{false}`);
            if (result.data.total > 0 && result.data.documents[0].brandingStatus[0] === "Active") {
                // console.log(t.ACT_setBranding);
                t.ACT_setBranding(result.data.documents[0]);
                if (result.data.documents[0].brandingPrimaryColour) {
                    t.$vuetify.theme.themes.light.primary = result.data.documents[0].brandingPrimaryColour;
                }
                if (result.data.documents[0].brandingSecondaryColour) {
                    t.$vuetify.theme.themes.light.secondary = result.data.documents[0].brandingSecondaryColour;
                }
                if (result.data.documents[0].brandingLogo) {
                    t.pageLogo = this.MIX_imageURL + ("images/landscape/" + result.data.documents[0].brandingLogo + ".jpeg");
                }
            } else {
                const defaultBrand = {
                    brandingName: "KindMind",
                    primary: "#7FC3D8",
                    secondary: "#46AAC9",
                    accent: "#8c9eff",
                    bkg: "#f5f5f5",
                    tertiary: "#59c090",
                    logo: require("@/assets/logo.png"),
                };
                t.pageLogo = defaultBrand.logo;
                t.ACT_setBranding(defaultBrand);
            }
        },
    },
};
</script>

<style scoped>
.splashPage-image {
    height: clamp(220px, 26vw, 300px) !important;
    /* width: clamp(120px, 22vw, 200px) !important; */
}
</style>
