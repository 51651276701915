<template>
    <div class="primary fullviewpoint allowscrolling">
        <v-row dense class="mt-1">
            <v-spacer />
            <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" class="d-flex justify-center">
                <v-spacer />
                <v-img alt="Vue logo" src="@/assets/logo.png" :max-width="smalldevice ? 120 : 200" contain />
                <v-spacer />
            </v-col>
            <v-spacer />
        </v-row>
        <v-row dense class="d-flex align-center justify-center" :class="$vuetify.breakpoint.xsOnly ? ' mt-5' : ' my-8'">
            <v-col cols="12" xs="12" sm="" md="" lg="4" xl="4" >
                <v-card class="pa-5 mx-5 elevation-0 rounded-km white ">
                    <div class="primary--text text-center">
                        <div class="font-weight-light" :class="smalldevice ? 'kindmind-title-font-h5-bold' : 'kindmind-title-font-h4'">Reset Password</div>
                        <div class="font-weight-light grey--text" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6 mt-1'">
                            We'll send you an email with a temporary password
                        </div>
                        <div class="my-5">
                            <v-form>
                                <v-row dense class="d-flex flex-column align-center justify-center">
                                    <v-col cols="12" xl="8" class="mt-2">
                                        <v-text-field solo text :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" background-color="grey lighten-3" class="rounded-km" placeholder="Email..."></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="mt-2" xl="8">
                                        <v-btn @click="$router.push('/questions/onBoarding')" depressed :height="smalldevice ? 35 : 55" class="rounded-km secondary text--white" block>Reset Password</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                        <div class="font-weight-light grey--text" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'">or login With</div>
                        <div class="my-2 d-flex align-center justify-center" style="width: 100% !important">
                            <v-btn depressed :height="smalldevice ? 40 : 55" class="mx-1 rounded-km secondary"><v-icon class="kindmind-font-icons white--text">icons8-google</v-icon></v-btn>
                            <v-btn depressed :height="smalldevice ? 40 : 55" class="mx-1 rounded-km secondary"><v-icon class="kindmind-font-icons white--text">icons8-facebook-f</v-icon></v-btn>
                            <v-btn depressed :height="smalldevice ? 40 : 55" class="mx-1 rounded-km secondary"><v-icon class="kindmind-font-icons white--text">icons8-apple-logo</v-icon></v-btn>
                        </div>
                        <div class="font-weight-light grey--text" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'">Don't have an account <a @click="sendComponentRequest('register'), $router.push('/auth/register')">Sign up</a></div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <footer-component />
    </div>
</template>

<script>
    export default {
        name: 'reset-component',
        // * DATA
        data: () => ({
            component: 'register-component',
            authId: 'resetpassword'
        }),
        // * METHODS
       methods: {
        // Sends a request to the parent for a new component
            sendComponentRequest(event) {
                if(event == 'register') {
                this.$emit('componentRequest', 'register')
                }
            }
        },
        // * COMPUTED
        computed: {
            // Checks the height of the device 
          smalldevice() {
                return window.innerHeight < 680;
            },
        },
    };
</script>
