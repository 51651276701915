<template>
    <div class=" text-center d-flex flex-column align-center justify-space-between white" style="z-index:9999 !important; height: 100vh !important;">
        <!-- Component to remind users to turn on notifications -->
       <v-row class="mx-2 d-flex justify-center">
        <v-col cols="12" xs="12" sm="6" lg="8">
        <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h5-5 mt-6 mb-6' : 'kindmind-title-font-h4 mt-6 mb-10'" class="primary--text">Get daily reminders</div>
        <div :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b1' : 'kindmind-font-h6 mb-4'">Kind mind will help you keep track of your health and well-being by sending you email reminders daily</div>
        <v-col cols="12" xs="12" sm="12" class="d-flex justify-center">
        <v-btn large @click="localStorage, $router.push('/home')" class="kindmind-font-h6 mt-6 text-capitalize white--text secondary rounded-lg" :style="$vuetify.breakpoint.xsOnly ? 'height: 6vh; width: 80vw;' : 'height: 6vh; width: 30vw;'">Next</v-btn>
        </v-col>
        <v-icon large class="secondary--text" :class="smallmobile ? 'kindmind-font-h4 mt-4' : 'kindmind-font-h1 mb-4 mt-6'">mdi-apple</v-icon> 
   </v-col>
   </v-row>
   <div class="d-flex justify-center" style="width: 100vw;" :class="smallmobile ? 'imageStyleMobile' : 'imageStyleDesktop'">
            <img src="@/assets/phone-outline.png" alt="phone outline">
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "daily-reminders",
    components: {},
    data: () => ({
        
    }),
    // * COMPUTED
        computed: {
        // Check the width of the device
          smalldevice() {
                return window.innerWidth < 840;
            },
            smallmobile() {
                return window.innerWidth < 390;
            },
        // Sets localStorage to the next section
        localStorage() {
            localStorage.setItem("section", "pause");
        }
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.imageStyleDesktop {
    position:fixed !important; 
    bottom:0px !important;
    margin: 0 !important;
    /* left: 15%; */
}

.imageStyleMobile {
    position:fixed !important; 
    bottom:-80px !important;
    margin: 0 !important;
    /* left: 15%; */
}
</style>