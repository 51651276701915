const mixin = {
    data: () => ({
        imageURL: process.env.VUE_APP_IMAGEURL,
    }),
    computed: {
        // READS THE URL FOR THE IMAGES
        MIX_imageURL() {
            return process.env.VUE_APP_IMAGEURL;
        },

        // READS THE URL FOR THE VIDEOS
        MIX_videoURL() {
            return process.env.VUE_APP_VIDEOURL;
        },

        // READS THE URL FOR THE AUDIOS
        MIX_audioURL() {
            return process.env.VUE_APP_AUDIOURL;
        },
    },
};

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
