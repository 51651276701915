<!--APP TEXT

	This is the main component for text within the app.
	<app_text> can be called with any of the 'category' attributes below which will give it a predefined size.
-->

<template>

	<div class="d-flex align-center">

		<div>
			<v-icon v-if="icon !== ''" :class="iconclass">{{ icon }}</v-icon>
		</div>

		<div :class="dynamicClass">
			<slot></slot>
		</div>

	</div>

</template>

<script>
	export default {
		props: {
			category: {
				type: String,
				default: '',
				required: false
			},
			icon: {
				type: String,
				default: '',
				required: false
			},
			iconclass: {
				type: String,
				default: '',
				required: false
			}
		},

		computed: {

			dynamicClass: function () {
				let category;
				switch (this.category) {
					case 'KM-h1':
						category = 'kindmind-font-h1'
						break
					case 'KM-h1-bold':
						category = 'kindmind-font-bold-h1'
						break
					case 'KM-h2':
						category = 'kindmind-font-h2'
						break
					case 'KM-h3':
						category = 'kindmind-font-h3'
						break
					case 'KM-h4':
						category = 'kindmind-font-h4'
						break
					case 'KM-h4-bold':
						category = 'kindmind-font-h4-bold'
						break
					case 'KM-h5':
						category = 'kindmind-font-h5'
						break
					case 'KM-h5-bold':
						category = 'kindmind-font-h5-bold'
						break
					case 'KM-h5-5':
						category = 'kindmind-font-h5-5'
						break
					case 'KM-h6':
						category = 'kindmind-font-h6'
						break
					case 'KM-h6-5':
						category = 'kindmind-font-h6-5'
						break
					case 'KM-h6-bold':
						category = 'kindmind-font-h6-bold'
						break
			case 'KM-h7':
						category = 'kindmind-font-h7'
						break
			case 'KM-h7-bold':
						category = 'kindmind-font-h7-bold'
						break
			case 'KM-b1':
						category = 'kindmind-font-b1'
						break
			case 'KM-b2':
						category = 'kindmind-font-b2'
						break
			case 'KM-cap':
						category = 'kindmind-font-cap'
						break
					default:
						category = '';
						break;
				}
				return category;
			}
		}
	}
	
</script>

<style lang="sass">
	@import 'node_modules/vuetify/src/styles/styles'

	.text-small
		font-size: 12px


	.text-small-bold
		font-size: 12px
		font-weight: bold

	.text-default
		font-size: 16px

	.text-default-bold
		font-size: 16px
		font-weight: bold

	.text-medium
		font-size: 20px

	.text-medium-bold
		font-size: 20px
		font-weight: bold

	.text-large
		font-size: 24px

	.text-large-bold
		font-size: 24px
		font-weight: bold

	.text-xlarge
		font-size: 32px

	.text-xlarge-bold
		font-size: 32px
		font-weight: bold

	.text-xxlarge
		font-size: 40px

	.text-xxlarge-bold
		font-size: 40px
		font-weight: bold


	.content-help
		font-size: 18px
		font-weight: thin

	.tab-title
		font-size: 16px
		font-weight: bold

	.tab-text
		font-size: 16px
		font-weight: normal

	.popup-title
		font-size: 16px
		font-weight: normal

	.popup-text
		font-size: 16px
		font-weight: normal

	.content-help
		font-size: 18px
		font-weight: thin

	.dialog-title
		font-size: 18px
		font-weight: thin

	.page-title
		font-size: 28px
		max-width: 600px

	.stats-error
		font-size: 40px
		max-width: 400px

	.stats-warning
		font-size: 40px
		max-width: 400px

	.stats-success
		font-size: 40px
		max-width: 400px

	.stats-total
		font-size: 40px
		max-width: 400px


	.content-title
		font-size: 20px
		max-width: 200px
</style>

