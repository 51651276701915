<template>
    <v-row v-if="isPostsDataLoaded" class="d-flex align-start justify-center mb-16" :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : ' my-8'" style="width: 100% !important">
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ma-0 pa-0 mb-10">
            <app-text v-if="content == ''" class="grey--text " style=" justify-content: center; align-content: center;" :category="$vuetify.breakpoint.width <= 700 ? 'KM-h6' : 'KM-h5'">There are no posts at the moment. Be the first to write one!</app-text>
            <v-card v-else v-for="(post, postIndex) in content" :key="postIndex" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-center pb-0">
                    <v-col cols="8" class="d-flex pa-0">
                        <v-avatar v-if="post.createdUserPhoto" class="primary mr-2 rounded-lg">
                            <v-img :src="post.createdUserPhoto" cover></v-img>
                        </v-avatar>
                        <!--If an image isn't present, render an icon-->
                        <v-avatar v-else class="primary mr-2 rounded-lg">
                            <v-icon class="icons8-user white--text" :size="$vuetify.breakpoint.xsOnly ? 38 : 42"> </v-icon>
                        </v-avatar>

                        <div class="d-flex flex-column">
                            <div class="grey--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndDown ? '' : 'kindmind-font-b2'">{{ post.createdUserName }}</div>
                            <div class="grey--text text-left" :class="smalldevice ? 'kindmind-font-b2' : 'kindmind-font-b2'">Author</div>
                        </div>
                    </v-col>
                    <v-col v-if="post.createdUserId === currentUser.id" cols="4" class="d-flex justify-end pa-0">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark icon v-bind="attrs" v-on="on">
                                    <v-icon color="grey">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="grey lighten-4" :style="'border: 1px solid #D3D3D3 !important;'">
                                <v-btn @click="deletePost(post)" x-small text>Delete Post</v-btn>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-col>
                <div class="my-5">
                    <v-row dense class="d-flex flex-column px-4">
                        <v-col cols="12" xs="12" xl="8" class="d-flex flex-column align-center justify-center">
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-h7" style="width: 100%">{{ post.postTitle }}</div>
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="align-start kindmind-font-cap" style="width: 100%" v-html="post.postText"></div>
                        </v-col>
                        <v-col cols="12" xl="8" class="d-flex flex-column align-start justify-center">
                            <v-img :src="post.postImageId" max-height="100" class="rounded mb-4"></v-img>
                            <v-btn-toggle class="grey lighten-4">
                                <v-btn v-for="flair in post.postFlair" :key="flair" x-small class="mr-2 primary white--text rounded-km">{{ flair }}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <v-divider></v-divider>
                    <div class="d-flex flew-row pa-2 align-center justify-center grey--text">
                        <v-btn small icon plain :ripple="false" depressed @click="addFavouriteContent(post.entityId, post.isFavourite)" class="mr-8 ml-2">
                            <v-icon size="28" :class="post.isFavourite ? 'red--text' : ''" depressed class="iconStyle pa-0 ma-0 pt-2">{{ post.isFavourite ? "icons8-favorite" : "icons8-favorite-2" }}</v-icon
                            ><span class="text-body-1 mt-2 ml-1">{{ post.postLikesCount }}</span>
                        </v-btn>
                        <div v-for="user in post.postLikes.slice(0, 11)" :key="user.id" class="px-0 mx-0">
                            <v-avatar v-if="user.createdUserPhoto !== ''" size="25" class="mt-2 ml-n3">
                                <v-img :src="user.createdUserPhoto" cover></v-img>
                            </v-avatar>
                            <v-avatar v-else size="25" class="mt-2 ml-n3" :color="randomPastelColor()">
                                {{ user.createdUserName.match(/\b(\w)/g).join("") }}
                            </v-avatar>
                        </div>
                        <v-spacer />
                        <v-icon size="28" class="grey--text px-2" @click="openComments(post)">icons8-communication</v-icon>{{ post.postCommentCount }}
                    </div>
                </div>
            </v-card>
            <br />
            <br />
            <br />
        </v-col>
        <div style="margin-top: 14px !important"></div>
        <div class="footerbanner"></div>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "posts-component",
    props: {
        datadown: {
            type: Array,
            default: () => [],
        },
        section: {
            type: String,
            default: null,
        },
        isPostsDataLoaded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        // data
        content: [],
        contentCreator: {},
        currentUser: {},
        defaultStat: {
            statType: "",
            statRelatedId: "",
            statRelatedType: "content",
            statRelatedSection: "",
            createdUserId: "",
            createdOrgId: "",
            createdDate: "",
            enabled: true,
            modifiedDate: null,
            modifiedOrgId: null,
            modifiedUserId: null,
            statRating: null,
            statRelatedTime: null,
            deleted: false,
            deletedDate: "",
            deletedOrgId: "",
            deletedUserId: "",
        },
        pollAnswer: "",
        stat: {
            statType: "",
            statRelatedId: "",
            statRelatedType: "content",
            statRelatedSection: "",
            createdUserId: "",
            createdOrgId: "",
            createdDate: "",
            enabled: true,
            modifiedDate: null,
            modifiedOrgId: null,
            modifiedUserId: null,
            statRating: null,
            statRelatedTime: null,
            deleted: false,
            deletedDate: "",
            deletedOrgId: "",
            deletedUserId: "",
        },
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        smalldevice() {
            return window.innerHeight < 600;
        },
    },
    methods: {
        // Gets information on current user
        async readCurrUser() {
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currentUser = userDataResult.data;
        },
        openComments(post) {
            this.$emit("openComments", post);
        },
        async addFavouriteContent(id, isfavourite) {
            if (isfavourite === false) {
                try {
                    // Find the post in the content array
                    let post = this.content.find((p) => p.entityId === id);
                    if (post) {
                        post.isFavourite = true;
                    }
                    let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                    this.stat.statType = "heart";
                    this.stat.statRelatedId = id;
                    this.stat.statRelatedSection = this.chosenSection;
                    this.stat.createdUserId = this.currentUser.id;
                    this.stat.createdOrgId = this.currentUser.userOrgId;
                    this.stat.createdUserName = this.currentUser.userName;
                    this.stat.createdUserPhoto = this.currentUser.userProfile;
                    this.stat.createdDate = currentDateTime;
                    this.stat.modifiedDate = currentDateTime;
                    this.stat.modifiedOrgId = this.currentUser.userOrgId;
                    this.stat.modifiedUserId = this.currentUser.id;
                    this.stat.statRelatedTime = currentDateTime;
                    // Create Stat
                    await this.REDIS_create("stat", this.stat);
                    this.stat = { ...this.defaultStat };
                    this.$emit("returnToSocial");
                } catch (error) {
                    console.error("Error: ", error);
                }
            } else {
                let post = this.content.find((p) => p.entityId === id);
                if (post) {
                    post.isFavourite = false;
                }
                let searchQuery = `@createdUserId:{${this.currentUser.id}} @statRelatedId:{${id}} @statType:{heart} @deleted:{false}`;
                let contentResult = await this.REDIS_searchFor("stat", null, null, null, null, searchQuery);
                let favourites = contentResult.data.documents;
                let entityId = favourites[0].entityId;
                let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                this.REDIS_delete("stat", entityId, {
                    deleted: true,
                    deletedUserId: this.currentUser.id,
                    deletedOrgId: this.currentUser.userOrgId,
                    deletedDate: currentDateTime,
                    modifiedUserId: this.currentUser.id,
                    modifiedDate: currentDateTime,
                    modifiedOrgId: this.currentUser.userOrgId,
                });
                this.$emit("returnToSocial");
            }
        },
        async deletePost(post) {
            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
            let deleteStatus = await this.REDIS_delete("post", post.entityId, {
                deleted: true,
                deletedUserId: this.currentUser.id,
                deletedOrgId: this.currentUser.userOrgId,
                deletedDate: currentDateTime,
                modifiedUserId: this.currentUser.id,
                modifiedDate: currentDateTime,
                modifiedOrgId: this.currentUser.userOrgId,
            });
            for (const comment of post.postComments) {
                    await this.REDIS_delete("postcomment", comment.entityId, {
                        deleted: true,
                        deletedUserId: this.currentUser.id,
                        deletedOrgId: this.currentUser.userOrgId,
                        deletedDate: currentDateTime,
                    });

                    // Perform desired operations on the matching post
                }
            if (deleteStatus) {
                this.$emit("returnToSocial");
            }
        },
        randomPastelColor() {
            let r = (Math.round(Math.random() * 127) + 127).toString(16);
            let g = (Math.round(Math.random() * 127) + 127).toString(16);
            let b = (Math.round(Math.random() * 127) + 127).toString(16);
            return "#" + r + g + b;
        },
        // initialise passes the information to the child component
        initialise() {
            this.content = this.datadown;
        },
    },
    watch: {
        // datadown is watching changes for new questions coming from the parent
        datadown: {
            handler() {
                this.initialise();
            },
            deep: true,
        },
    },
    async created() {
        this.readCurrUser();
        this.initialise();
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.imageStyle {
    height: 8vh;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 20vh !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
