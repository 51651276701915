<template>
    <div class="primary" style="height: 100vh !important; overflow: hidden !important">
    <v-row class="d-flex justify-center" no-gutters>

        <v-col cols="12" lg="5" class="">

            <v-row class="pt-4 px-5 text-center justify-center">

                <v-col cols="12" md="12">

					<!--Logo-->
        			<img :src="pageLogo" alt="Company's Log" style="text-align:center; margin:auto;" contain width="240" height="auto" :class="$vuetify.breakpoint.smAndUp ? 'pb-10 pt-10 mt-4' : 'pt-8 pb-6'">

					<!--Personalize information-->
					<div class="text-h6">Welcome to <span class="white--text">Kind Mind</span> </div><br /><br />

	<!-- Ask user to verify their email address -->
					<div>Please check your inbox for a verification email to confirm your email address. If not found, please check your spam or junk mail folder. Once verified, please wait for your account to be approved. </div>
					
<br><br>
					<!--User Status information & instructions-->
					<span v-if="userStatus === 'Pending'">Your account status is currently <span class="warning--text font-weight-bold">PENDING</span>, please wait for your account to be Approved. <br /><br />If you are waiting more than 48 hours for your account to be approved please contact <a :href="'mailto:support@kindmindadmin'" class=" white--text font-weight-bold">support@kindmindadmin</a></span>
					<span v-if="userStatus === 'Approved'">Your account has now been <span class="success--text font-weight-bold">APPROVED</span>, please click the button to log into your account.</span>
					<span v-if="userStatus === 'Suspended'">Your account has been <span class="warning--text font-weight-bold">SUSPENDED</span>, if you believe this is an error then contact <a :href="'mailto:support@kindmindadmin'" class="white--text font-weight-bold">support@kindmindadmin</a></span>
					<span v-if="userStatus === 'Deleted'">Your account has now been <span class="error--text font-weight-bold">DELETED</span>, if you believe this is an error then contact <a :href="'mailto:support@kindmindadmin'" class="white--text font-weight-bold">support@kindmindadmin</a></span>
					<span v-if="userStatus === 'Rejected'">Your account has been <span class="error--text font-weight-bold">REJECTED</span>, if you believe this is an error then contact <a :href="'mailto:support@kindmindadmin'" class="white--text font-weight-bold">support@kindmindadmin</a></span>
					<span v-if="userStatus === 'Archived'">Your account has been <span class="white--text font-weight-bold">ARCHIVED</span>, if you believe this is an error then contact <a :href="'mailto:support@kindmindadmin'" class="white--text font-weight-bold">support@kindmindadmin</a></span>
					<div class="mt-5 align-center"><v-btn depressed class="success" to='/auth' v-if="userStatus === 'Approved'">Home</v-btn></div>
				</v-col>

			</v-row>

		</v-col>

	</v-row>
	 <footer-component />
	</div>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
    name: "HoldingPage",
    data: () => ({
        userStatus: null,
		// page logo
        pageLogo: require("@/assets/primary logo white (2).svg"),
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    mounted(){
			
			let userDataResult = "";
			// When the userStatus will change by an admin the snapshot will fire give the according permission to the current user
			onSnapshot(doc(this.$firebase.db, 'users', this.GET_FIREBASE_userAuth.uid), (doc) => {
				userDataResult = doc.data();
				this.userStatus = userDataResult.userStatus;
			});
		},
};
</script>

