<template>
    <div class="grey lighten-4 allowscrolling fullscreenview" style="width: 100vw !important; height: 100% !important">
        <component :is="component" :datadown="activeQuestion" :activeQuestionCount="activeQuestionCount" @updateanswer="updateuseranswer" v-on:update="updateQuestions" :previousanswers="previousanswers" :sectionstyle="sectionStyle" :key="questionkey"></component>

        <!-- Bottom Navigation -->
        <div :class="sectionStyle" v-if="isVisible" class="bottomNavStyle primary rounded-km-top px-5 text-right">
            <div class="d-flex" style="width: 100%">
                <!-- <v-btn v-if="activeQuestionCount !== 0" @click="previousQuestion()" text :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'white--text kindmind-font-h7-bold'"><v-icon class="mr-2">icons8-back-to</v-icon>Back </v-btn> -->
                <v-spacer />
                <v-btn v-if="activeQuestionCount !== questionTotal - 1" @click="nextQuestion()" text :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'white--text kindmind-font-h7-bold'">Next <v-icon class="ml-2">icons8-next-page</v-icon></v-btn>
                <v-btn v-if="activeQuestionCount == questionTotal - 1" @click="complete()" text :class="$vuetify.breakpoint.xsOnly ? 'white--text' : 'white--text kindmind-font-h7-bold'">Complete <v-icon class="ml-2">icons8-next-page</v-icon></v-btn>
            </div>

            <!-- Question Progress Bars -->
            <v-row class="mb-6 d-flex flex-row align-center justify-center mx-2" :class="$vuetify.breakpoint.xsOnly ? 'mt-4' : 'mt-2'">
                <div style="width: 90vw !important" class="d-flex flex-row align-center">
                    <v-divider v-for="(n, idx) in dividersTotal" :key="idx" class="mr-1 white" style="height: 4px !important; width: 100%" inline :class="{ secondary: n-1 === activeQuestionCount }" />
                </div>
            </v-row>
        </div>
        <!-- Alert Box Component -->
        <snackbar-component :alertdata="alertData" />
    </div>
</template>

<script>
import QuestionsComponent from "@/views/questions/OnboardingQuestionsComponent.vue";
import SectionQuestionsComponent from "@/views/questions/SectionQuestionsComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "question-screen",
    component: { QuestionsComponent, SectionQuestionsComponent },
    // * DATA
    data: () => ({
        component: "questions-component",
        sectionStyle: "",
        activeQuestionCount: 0,
        questionTotal: "",
        dividersTotal: "",
        questionId: "",
        questionkey: 0,
        isVisible: true,
        activeQuestion: {},
        previousanswers: {},
        questions: [],
        userpreferences: {
            questionId: "",
            questionSection: "",
            preferenceField: "",
            preferenceAnswer: [],
            preferenceDuration: null,
            questionInsights: "",
            createdUserId: "",
            createdOrgId: "",
            createdDate: null,
            modifiedUserId: "",
            modifiedOrgId: "",
            modifiedDate: null,
            deletedUserId: "",
            deletedOrgId: "",
            deletedDate: null,
            deleted: false,
        },
        useranswer: { questionId: "", questionSection: "", questionAnswers: [], createdUserId: "", createdOrgId: "", questionCore: "", questionAnswersValue: null, createdDate: null, modifiedUserId: "", modifiedOrgId: "", modifiedDate: null, deletedUserId: "", deletedOrgId: "", deletedDate: null, deleted: false },
        useranswers: [],
        myuseranswers: [],
        defaultUseranswer: {
            questionId: "",
            questionSection: "",
            questionAnswers: [],
            createdUserId: "",
            createdOrgId: "",
            questionCore: "",
            questionAnswersValue: null,
            createdDate: null,
            modifiedUserId: "",
            modifiedOrgId: "",
            modifiedDate: null,
            deletedUserId: "",
            deletedOrgId: "",
            deletedDate: null,
            deleted: false,
        },
        userData: {},
        alertData: { title: "", message: "", color: "", timeout: "2000", show: false },
    }),
    // * METHODS
    methods: {
        ...mapActions({ 
            ACT_currentUser: "ACT_currentUser"
        }),
        // Updates active question
        updateQuestions(question) {
            this.$set(this.questions, this.activeQuestionCount, question);
        },
        /* Pulls the questions from redis
         * params: section => this defines which of the questions are being called
         * returns:  All the questions from that section and the total number of questions
         */
        async getQuestions(section) {
            // Searches for questions in the database & replace spaces in the search query with \\
            let questionsResult = await this.REDIS_searchFor("question", null, null, "questionOrder", "ASC", `@questionSection:{${section.replace(/\s/g, "\\ ")}} @deleted:{false} @enabled:{true}`);
            this.questions = questionsResult.data.documents;
            this.questionTotal = questionsResult.data.total;
            this.dividersTotal = questionsResult.data.total;
        },
        // updateuseranswer receives updates from child component to update useranswers
        updateuseranswer(answervalue) {
            this.useranswers = JSON.parse(JSON.stringify(answervalue));
        },
        // Checks that the user has selected at least one answer
        nextQuestion() {
            switch (true) {
                // If the user has not selected an answer
                case (JSON.stringify(this.useranswers.questionAnswers[this.useranswers.questionAnswers.length - 1]) == "[]"):
                    // console.log("running 2", this.useranswers.questionAnswers[this.useranswers.questionAnswers.length - 1])
                   this.alertData = { title: "Next Question", message: "Please select an answer to continue", color: "error", timeout: 2000, show: true };
                    break;
                    // If the user has selected an answer and the question is a multiple choice question
                case (this.questions[this.activeQuestionCount].questionAnswerType == "Multiple Choice"):
            // console.log("running 4", this.useranswers)

                  this.myuseranswers.push(this.useranswers);
                    this.activeQuestionCount++;
                    this.questionkey++;
                    break;
                    // If the user has selected an answer and the question is a rank order question
                case (this.questions[this.activeQuestionCount].questionAnswerType == "Rank Order"):
                    this.myuseranswers.push(this.useranswers);
                    this.activeQuestionCount++;
                    this.questionkey++;
                    break;
                    // If the user has selected an answer and the question is a single choice question and it doesn't have Core 
                case (this.questions[this.activeQuestionCount].questionAnswerType == "Single Choice" && (this.useranswers.questionCore == "" || this.useranswers.questionCore == null || this.useranswers.questionCore == undefined)):
            // console.log("running 4", this.useranswers.questionAnswers.slice(-1))
                  
                  this.useranswers.questionAnswers = this.useranswers.questionAnswers.slice(-1);
                    this.myuseranswers.push(this.useranswers);
                    this.activeQuestionCount++;
                    this.questionkey++;
                    break;
                    // If the user has selected an answer and the question is a single choice question and it has Core
                   case (this.questions[this.activeQuestionCount].questionAnswerType == "Single Choice" && (this.useranswers.questionCore != "" || this.useranswers.questionCore != null || this.useranswers.questionCore != undefined)):                   
                   this.useranswers.questionAnswers = this.useranswers.questionAnswers.slice(-1)[0];
                    let answer = this.useranswers.questionAnswers;
                    this.useranswers.questionAnswers = []
                    this.useranswers.questionAnswers.push(answer.text);

                    // Checks in case there's no score and sets it to 0
                    if (answer.score == null || answer.score == undefined || answer.score == "") {
                        this.useranswers.questionAnswersValue = 0;
                    } else {
                        this.useranswers.questionAnswersValue = answer.score;
                    }
                    this.myuseranswers.push(this.useranswers);
                    this.activeQuestionCount++;
                    this.questionkey++;
                    break;
                    // If the user has selected an answer and the question is a Sliding Scale question and it has Core
                   case (this.questions[this.activeQuestionCount].questionAnswerType == "Sliding Scale" && (this.useranswers.questionCore != "" || this.useranswers.questionCore != null || this.useranswers.questionCore != undefined)):
                    this.useranswers.questionAnswers = this.useranswers.questionAnswers.slice(-1)[0];
                    let answer2 = this.useranswers.questionAnswers;
                    this.useranswers.questionAnswers = []
                    this.useranswers.questionAnswers.push(answer2);
                    this.useranswers.questionAnswersValue = answer2;
                    this.myuseranswers.push(this.useranswers);
                    this.activeQuestionCount++;
                    this.questionkey++;
                    break;
                default:
                    break;
            }
        },
        async complete() {
            // Runs over the total of answers from useranswers and pairs the information to for useranswer. Calls sendAnswers
             if (JSON.stringify(this.useranswers.questionAnswers[this.useranswers.questionAnswers.length - 1]) == "[]" || this.useranswers.questionAnswers[this.useranswers.questionAnswers.length - 1] == undefined) {
                this.alertData = { title: "Next Question", message: "Please select an answer to continue", color: "error", timeout: 2000, show: true };
                    return;
             }
                    
            this.nextQuestion();
        
            // Prepares the answers' format and calls sendAnswers()
            for (let i = 0; i <= this.myuseranswers.length + 1; i++) {
            // console.log("running final", this.myuseranswers)

                if (this.myuseranswers[i] !== undefined) {
            // console.log("running final 2", [i], this.myuseranswers[i])

                    let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                    this.useranswer.questionId = this.myuseranswers[i].questionId;
                    this.useranswer.questionSection = this.myuseranswers[i].questionSection.replace(/\s/g, "\\ ").toLowerCase();
                    this.useranswer.questionAnswers = this.myuseranswers[i].questionAnswers;
                    this.useranswer.questionAnswersValue = this.myuseranswers[i].questionAnswersValue;
                    this.useranswer.createdDate = currentDateTime;
                    this.useranswer.questionCore = this.myuseranswers[i].questionCore;
                    this.useranswer.createdOrgId = this.userData.userOrgId;
                    this.useranswer.createdUserId = this.userData.id;
                    this.useranswer.modifiedDate = currentDateTime;
                    this.useranswer.modifiedOrgId = this.userData.userOrgId;
                    this.useranswer.modifiedUserId = this.userData.id;
                    // Checks if it's a preference question and if it is, it sends the answers to the userpreferences table
                    if (["Pause", "Move", "Fuel", "Grow", "pause", "move", "fuel", "grow"].includes(this.myuseranswers[i].questionSection)) {
                        this.userpreferences.questionId = this.myuseranswers[i].questionId;
                        this.userpreferences.questionSection = this.myuseranswers[i].questionSection.replace(/\s/g, "\\ ").toLowerCase();
                        this.userpreferences.preferenceAnswer = this.myuseranswers[i].questionAnswers;
                        this.userpreferences.preferenceField = this.myuseranswers[i].preferenceField;
                        // console.log("preferenceField", this.myuseranswers[i].preferenceField)
                        // if (this.myuseranswers[i].preferenceField == "Duration") {
                        //     this.userpreferences.preferenceDuration = this.myuseranswers[i].questionAnswers;
                        // } else {
                            this.userpreferences.preferenceDuration = null;
                        // }
                        this.userpreferences.createdDate = currentDateTime;
                        this.userpreferences.createdOrgId = this.userData.userOrgId;
                        this.userpreferences.createdUserId = this.userData.id;
                        this.userpreferences.modifiedDate = currentDateTime;
                        this.userpreferences.modifiedOrgId = this.userData.userOrgId;
                        this.userpreferences.modifiedUserId = this.userData.id;
                        // console.log("userpreferences", this.userpreferences)
                        this.sendPreference();
                        this.userpreferences.preferenceAnswer = [];
                    }
                    // Sends the answers to the useranswers table
                    // console.log("useranswer", this.useranswer)
                    this.sendAnswers();
                    this.useranswer.questionAnswers = [];
                }
            }
            // On complete button redirects the user to a new view
            let route = localStorage.getItem("section");
            // console.log("route", route);
            if (route == "dashboard") {
                await this.onboardingCompleted();
                // console.log("onboarding completed");
                this.$router.push("/dashboard");
            } else if (route == "onBoarding") {
                // this.component = "percetage-component";
                // this.component = "daily-reminders";
                // this.isVisible = false;
                // TO-DO : Once Notifications are enable uncomment the above three lines and delete the two lines below this one. Notifications view will show in the flow
                localStorage.setItem("section", "pause");
                this.$router.push("/home");
            } else {
                this.$router.push("/home");
            }
        },
        // Sends preferences to redis 
        async sendPreference() {
            await this.REDIS_create("userpreference", this.userpreferences);
        },
        // Sends answers to redis and clears useranswer
        async sendAnswers() {
            // console.log("useranswer", this.useranswer)
            await this.REDIS_create("useranswer", this.useranswer);
            this.useranswer = { ...this.defaultUseranswer };
        },
        // Sets the user information to onboarding completed
        async onboardingCompleted() {
            this.userData.onboarding = true;
            await this.MIX_firestore_updateData(this.userData.id, "users", "onboarding", this.userData.onboarding);
            let userdata = await this.MIX_firestore_read(this.userData.id, "users");
            this.ACT_currentUser(userdata);
        },
    },
    // * WATCH
    watch: {
        // watched for changes in the active question to update the qiuestion that's being passed to the child component
        activeQuestionCount(index) {
            this.activeQuestion = this.questions[index];
        },
    },
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    // * CREATED
    async created() {
        localStorage.setItem("section", "onBoarding");
        // localStorage.setItem("progress", "30");
        // Reads Current User information from Firebase
        let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
        this.userData = currUserResult.data;
        // Switching between sections
        this.questionId = this.$route.params.questionId;
        switch (this.questionId) {
            case "onBoarding":
                await this.getQuestions("Daily");
                break;
            case "pause":
                this.component = "section-questions";
                this.sectionStyle = "pause";
                await this.getQuestions("Pause");
                localStorage.setItem("section", "move");
                // localStorage.setItem("progress", "50");
                break;
            case "move":
                this.component = "section-questions";
                this.sectionStyle = "move";
                await this.getQuestions("Move");
                localStorage.setItem("section", "fuel");
                // localStorage.setItem("progress", "70");
                break;
            case "fuel":
                this.component = "section-questions";
                this.sectionStyle = "fuel";
                await this.getQuestions("Fuel");
                localStorage.setItem("section", "grow");
                // localStorage.setItem("progress", "90");
                break;
            case "grow":
                this.component = "section-questions";
                this.sectionStyle = "grow";
                await this.getQuestions("Grow");
                localStorage.setItem("section", "dashboard");
                // localStorage.setItem("progress", "100");
                localStorage.setItem("firstLogin", true);
                break;
            default:
                "";
        }
        // Sets the active question
        this.activeQuestion = this.questions[this.activeQuestionCount];
    },
};
</script>

<style scoped>
.bottomNavStyle {
    width: 100% !important;
    z-index: 1 !important;
    position: fixed !important;
    bottom: 0 !important;
}
</style>
