import { render, staticRenderFns } from "./PollsComponent.vue?vue&type=template&id=6db3e746&scoped=true&"
import script from "./PollsComponent.vue?vue&type=script&lang=js&"
export * from "./PollsComponent.vue?vue&type=script&lang=js&"
import style0 from "./PollsComponent.vue?vue&type=style&index=0&id=6db3e746&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db3e746",
  null
  
)

export default component.exports