<template>
<div >

	<!--Add Photo Profile Layout-->
			<!-- File input -->
			<input type="file"  ref="fileinput" style="display: none" @change="uploadFile" />

			<!--Add photo button | Progress bar-->

				<!--Add button-->
				<v-btn small @click.native="fileClick" class="white--text" color="primary kindmind-font-b2-bold" :loading="loading" >
					<v-icon class="mx-2 white--text" color="white" size="18">icons8-compact-camera</v-icon>Add Photo
				</v-btn>

        <!--Button-->
        <!-- <v-col cols="12" class="mt-4">
            <v-file-input dense class="rounded-lg mr-4" outlined v-bind:accept="accept" v-bind:label="label" v-model="file" :rules="rules" :hide-details="true" @change="uploadFile()" :clearable="false"></v-file-input>
        </v-col> -->

        <!--Loading-->
        <!-- <v-col cols="12" class="mt-2 grey darken-2" v-if="loading && value < 100">
            <v-progress-linear height="45" class="py-2 white--text" :buffer-value="value" large v-model="value" color="purple">
                <div class="animate__animated animate__flash animate__infinite">{{ status }}</div>
                <div class="ml-2" v-if="value > 0">({{ Math.ceil(value) }})%</div>
            </v-progress-linear>
            <div v-if="!loading && value === 100" class="success white--text" style="width: 100% !important; height: 100%">Upload Completed</div>
        </v-col> -->
    </div>
</template>

<script>
import imageCompression from "browser-image-compression";
export default {
    name: "ImageUpload-component",

    props: ["accept", "buttonClass", "compressionMaxSizeMB", "compressionmaxWidthOrHeight", "fileName", "label", "private", "progressClass", "createThumbnail", "fileData", "currentUser", "loggeduser"],

    data: () => ({
        file: null,
        loading: false,
        status: "",
        rules: [(value) => !value || value.size < 20000000 || "Image size should be less than 2 MB!"],
        value: 0,
        previewFile: true,
        imageItem: {},
    }),
    computed: {},
    methods: {
        fileClick() {
            // Trigger click on the FileInput
               this.$refs.fileinput.click();
		},

        async uploadToGoogleStorage(file, imageType) {
            let folderLocation = 'images/'
            return new Promise((resolve) => {
                let t = this;
                var upload;
                t.status = "Uploading " + imageType;

                let fileNameFirebase = this.MIX_generateId();

                upload = t.$firebase.privateStorage.ref(folderLocation + fileNameFirebase + ".jpeg").put(file);
                upload.on(
                    "state_changed",
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        t.value = progress;
                        switch (snapshot.state) {
                            case "paused":
                                // console.log("Upload is paused");
                                break;
                            case "running":
                                // console.log("Upload is running");
                                break;
                        }
                    },
                    (error) => {
                        console.error("error: ", error);
                    },
                    () => {
                        upload.snapshot.ref.getDownloadURL().then((url) => {
                            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                            let newForm = t.loggeduser
                            newForm.modifiedDateTime = currentDateTime;
                            newForm.userProfile = url;
                            t.MIX_firestore_updateMultipleFields(`${this.loggeduser.id}`, "users", newForm);
                        //    t.MIX_firestore_updateData('nOid2gyiHPO2s1HErLkeIRvcOT12', 'users', 'userProfile', url);
                        //    t.MIX_firestore_updateData('nOid2gyiHPO2s1HErLkeIRvcOT12', 'users', 'modifiedDateTime', currentDateTime);
                            resolve();
                        });
                    }
                );
            });
        },

        async uploadFile(event) {
            this.file = event.target.files[0]; // assign fileData for preview
            if (this.file != undefined || this.file != null) {
                let t = this;
                t.value = 0;
                t.loading = true;

                const options = {
                    maxSizeMB: t.compressionMaxSizeMB,
                    maxWidthOrHeight: t.compressionmaxWidthOrHeight,
                    useWebWorker: true,
                    fileType: "image/jpeg",
                };

                //* Compresses the image realtime
                const compressedFile = await imageCompression(t.file, options);

                //* Storage location in firebase
                var imageFile;
                t.status = "Compressing Image";
                imageFile = await imageCompression(t.file, compressedFile);

                t.status = "";
                await t.uploadToGoogleStorage(imageFile, "Image");

                t.loading = false;
                t.$emit("update");
            }
        },
    },

    created() {},
};
</script>
