import Vue from 'vue';
import App from './App.vue';
import vuetify from './vuetify.js';
import router from './router.js';
import store from './store.js';
import "animate.css";

// Icons8 CSS Import
import "@/assets/css/styles.min.css";
import axios from 'axios'
Vue.prototype.$axios = axios;

import moment from 'moment'
Vue.prototype.$moment = moment;

//* vue text editor for social posts
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// Vue Carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Video player
import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)
import VideoElementComponent from "@/components/VideoElementComponent.vue";
Vue.component('video-component', VideoElementComponent);

//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_firestore_mixin from "@/firebase/firebase_firestore_mixin.js"; //
Vue.use(firebase_firestore_mixin);
import firebase_auth_mixin from "@/firebase/firebase_auth_mixin.js"; //
Vue.use(firebase_auth_mixin);

// Global Components

// Footer
import Footer from "@/views/Footer.vue";
Vue.component('footer-component', Footer);

// App Update
import '@/registerServiceWorker';
import appupdate_mixin from '@/appupdate_mixin.js';
Vue.use(appupdate_mixin);
import AppUpdate from "@/components/AppUpdate.vue";
Vue.component('appupdate-component', AppUpdate);

// Redis Mixin
import redis_mixin from '@/redis_mixin.js'
Vue.use(redis_mixin)

// Mixin
import mixin from '@/mixin.js'
Vue.use(mixin)

// Computed Mixin
import computed_mixin from '@/computed_mixin.js'
Vue.use(computed_mixin)

// GENERAL COMPONENTS

// Polls Popup Component
import PollsPopupComponent from "@/components/PollsPopupComponent.vue";
Vue.component('polls-popup-component', PollsPopupComponent);
// App Text
import appText from '@/components/app_text.vue';
Vue.component('app-text', appText)

// Page Loader
import pageLoader from '@/components/pageLoading.vue';
Vue.component('pageLoader', pageLoader);

// Dropdown menus
import DropdownMenuComponent from "@/components/DropdownMenuComponent.vue";
Vue.component('dropdown-menu-component', DropdownMenuComponent);

// Logo for onboarding questions
import LogoInitialQuestions from "@/components/LogoInitialQuestions.vue";
Vue.component('logo-initial-questions', LogoInitialQuestions);

import BottomNavigation from "@/components/BottomNavigation.vue";
Vue.component('bottom-navigation', BottomNavigation);

// REMINDERS SETTINGS
import DailyRemindersComponent from "@/components/DailyRemindersComponent.vue";
Vue.component('daily-reminders', DailyRemindersComponent);

// PERCENTAGE ANIMATION
import percentageComponent from "@/components/percentageComponent.vue";
Vue.component('percetage-component', percentageComponent);

// SNACKBAR COMPONENT
import SnackbarMessageComponent from "@/components/SnackbarMessageComponent.vue";
Vue.component('snackbar-component', SnackbarMessageComponent);

// HOME -> Section Buttons
import HomeRequirementsQuestions from "@/views/home/HomeRequirementsQuestions.vue";
Vue.component('requirement-questions', HomeRequirementsQuestions);

// DASHBOARD -> Section Buttons
import ContentCards from "@/views/dashboard/ContentCards.vue";
Vue.component('content-cards', ContentCards);

// QUESTIONS  -> Onboarding Questions Component
import QuestionsComponent from "@/views/questions/OnboardingQuestionsComponent.vue";
Vue.component('questions-component', QuestionsComponent);

// QUESTIONS  -> Section Questions Component
import SectionQuestionsComponent from "@/views/questions/SectionQuestionsComponent.vue";
Vue.component('section-questions', SectionQuestionsComponent);

// AUTH -> Login Component
import LoginComponent from "@/views/auth/LoginScreen.vue";
Vue.component('login-component', LoginComponent);

// AUTH -> Register Component
import RegisterComponent from "@/views/auth/Register.vue";
Vue.component('register-component', RegisterComponent);

// AUTH -> Reset Password Component
import ResetPasswordComponent from "@/views/auth/ResetPasswordComponent.vue";
Vue.component('reset-component', ResetPasswordComponent);

// SOCIAL -> Polls messages Component
import PollsComponent from "@/views/social/PollsComponent.vue";
Vue.component('polls-component', PollsComponent);

// SOCIAL -> Posts messages Component
import PostsComponent from "@/views/social/PostsComponent.vue";
Vue.component('posts-component', PostsComponent);

// SOCIAL -> New post Component
import NewPostComponent from "@/views/social/NewPostComponent.vue";
Vue.component('new-post-component', NewPostComponent);

// PROFILE -> Profile Component
import UserInformationComponent from "@/views/userProfile/UserInformationComponent.vue";
Vue.component('user-information-component', UserInformationComponent);

// PROFILE -> Content Creator Component
import ContentCreatorComponent from "@/views/userProfile/ContentCreatorComponent.vue";
Vue.component('content-creator-component', ContentCreatorComponent);

// PROFILE -> Content Carousel Component
import UserContentCarousel from "@/views/userProfile/UserContentCarousel.vue";
Vue.component('user-content-component', UserContentCarousel);

// PROFILE -> Image Upload Component
import ImageUpload from "@/views/userProfile/ImageUpload.vue";
Vue.component('ImageUpload-component', ImageUpload);

// PROFILE -> Old Message Component
import MessagesComponent from "@/views/userProfile/MessagesComponent.vue";
Vue.component('messages-component', MessagesComponent);

// MESSAGES -> New Message Component
import messagesChat from "@/views/userProfile/messagesChat.vue";
Vue.component('messagesChat-component', messagesChat);

// APP BAR
import AppBarComponent from "@/components/AppBarComponent.vue";
Vue.component('app-bar-component', AppBarComponent); 

// EXPLORE -> Pause Page
import ExploreButtonsComponent from "@/views/explore/ExploreButtonsComponent.vue";

// EXPLORE CAROUSEL
import ActivityButtonsComponent from "@/components/ActivityButtonsComponent.vue";
Vue.component('activity-buttons-component', ActivityButtonsComponent);


import './registerServiceWorker'
Vue.component('explore-buttons-component', ExploreButtonsComponent);

//File upload
import FileUpload from "@/components/FileUpload";
Vue.component("FileUpload", FileUpload);


Vue.config.productionTip = false
firebase.auth.onAuthStateChanged(async (user) => {
  var Buffer = require('buffer/').Buffer
  if (user) {
    await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);

    if (user !== null) {
      user.getIdToken(true).then(async (idToken) => {
        var token = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString('utf8'));
        await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
      })
    }
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
