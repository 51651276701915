<template>
  
 <div class="rounded-km ma-0 pa-0" style="width:100% !important; height:100% !important; position: fixed !important; bottom 0 !important;" :class="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'">
<v-row >
              <!-- HEADER -->
            <v-col cols="12" xs="12" lg="12" >
                <div class="d-flex flex-row align-center justify-end mt-2">
                    <v-icon @click="backToMessages" class="primary--text">icons8-cancel</v-icon>
                </div>
        <!-- <div class="footerbanner pa-0 ma-0"></div> -->

            </v-col>

            <!-- Main page -->
      <v-col cols="12" xs="12" style="width:100% !important;" no-gutters>
      <div v-if="!selectedUser.id" class="pa-4">
        <app-text category="text-default-bold" class="primary--text" style=" justify-content: center;">Select a peer to start a conversation</app-text>
      </div>
      
      <div v-else class="chat-history" ref="chat">

        <div v-for="(msg, i) in chat" :key="i" class="d-flex" :class="{'flex-row-reverse ': msg.createdUserId === currentuser.id }">
        
          
          <!--Profile Images-->
          <v-img v-if="msg.createdUserId === currentuser.id && msg.messageRecipient === selectedUser.id && currentuser.userProfile" :src="currentuser.userProfile" max-height="80" max-width="80" class="rounded-km"/>
          <v-img v-else-if="msg.createdUserId === selectedUser.id && msg.messageRecipient === currentuser.id && selectedUser.userProfile" :src="selectedUser.userProfile" max-height="80" max-width="80" class="rounded-km"/>
          <v-icon v-else class="icons8-user" color="primary" size="80"></v-icon> 

          <v-card :class="msg.createdUserId === currentuser.id ? 'mr-4' : 'ml-4'" class="my-4 mt-4 pa-4  primary white--text" style="border-radius: 10px; max-width:64%" elevation="0">
            
            <v-row :class="{ 'd-flex flex-row-reverse ': msg.createdUserId === currentuser.id  }">
              <v-col cols="12">
                <span class="mt-4 ml-4 text-left">{{msg.messageSubject}}</span>
              </v-col>
              <v-col cols="12">
                <app-text category="KM-cap" caption>{{ MIX_dayTimeFormat(msg.createdDate , "short")}}</app-text>
              </v-col>
            </v-row>
            
          </v-card>
        </div>
      </div>

    
      <!-- end of chat-->

      <!--Send Button Message-->
      <div  v-if="selectedUser.id" class="messageButton">

        <v-divider class="secondary"/>

        <v-textarea
          class="mt-4 rounded-lg"
          background-color="white"
          required
          v-model="message"
          :error="errors.message"
          :error-messages="errors.messageErrorMessage"
          outlined
          label="What do you want to say?"
          rows="1"
          hide-details="auto"
          append-outer-icon="icons8-email-send"
          @click:append-outer="sendComment()"/>
      </div>
      <!-- end button-message -->
      </v-col>
</v-row>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "messagesChat-component",

       props: [ "chat", "currentuser", "selectedUser"],

    data() {
      return {

        currUserData: "",
        messageArray:[],

        messages: [],
        message:"",

        errors: {
          message:false,
          messageErrorMessage:"",
        },
        selectedUserProfile: false,
        currentUserProfile: false,
      };
    },
      
    computed: {
      ...mapGetters({
        GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
      }),

      
    },

    methods: {
      backToMessages() {
            this.$emit("returnToMessages");
        },
      // Initialises data
      initialise() {
            this.chat = this.chat ;
      },
      /**
       * Checks who is the sender of the message and returns the corresponding icon
       * @param {*} messenger The name of the sender
       * @return Returns the icons that match the sender
       */
      checkMessenger(messenger) {
        if (messenger === this.currentuser.id  ) {

          if(this.currentuser.userProfile !== null){
            return this.currentuser.userProfile;

          }else{
            return ""
          }
        } else {
          return this.selectedUser.userProfile;
        }
      },

     /**
             * This method return the date in a nice format
             * @param data The date
             * @param shortLong If is going to be a long or short date
             * @return The date
             */
             dayTimeFormat(date, shortLong){
                let formatedDate ="";
                if( shortLong === "short"){
                    formatedDate = this.$moment(date, 'X').format('DD MMM YYYY');
                }else if(shortLong === 'long'){
                    formatedDate = this.$moment(date, 'X').format('dddd Do MMMM \'YY');
                }
               return formatedDate;
            },

            /**
             * This method validated the messages before send it
             * Check if the message is empty 
             */
            validateMessage(){
                this.errors.message = false;
                this.errors.messageErrorMessage="";
                // Date
                if (!this.message.trim() ) {
                this.errors.message = true
                this.errors.messageErrorMessage = 'No message has been entered'
                }
                // Check if there any errors left
                if (!Object.values(this.errors).includes(true)) {
                return true;
                }
            },

            /**
       * Send the message to the chat, diplay it, and save it to the db
       */
      async sendComment() {
        if(this.validateMessage()){
            try {
                // Get Current Date Time
                let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                // Add Modified User Data
                let messageForm = {};
                messageForm.messageRecipient = this.selectedUser.id
                messageForm.messageSubject = this.message
                messageForm.messageBody = ""
                messageForm.messageRead = false
                messageForm.messageReadDate = ""
                messageForm.messageType = ""
                messageForm.messageOrgId = this.currentuser.userOrgId;
                messageForm.messageStatus = "Sent"

                messageForm.createdUserId = this.currentuser.id;
                messageForm.createdOrgId = this.currentuser.userOrgId;
                messageForm.createdDate = currentDateTime;             
                messageForm.modifiedUserId = this.currentuser.id;
                messageForm.modifiedOrgId = this.currentuser.userOrgId;
                messageForm.modifiedDate = currentDateTime;
                messageForm.deletedUserId = "";
                messageForm.deletedOrgId =""
                messageForm.deletedDate ="";
                messageForm.deleted = false;            

                await this.REDIS_create("message", messageForm);                  
                // this.MIX_alertBox({color: "green", timeout: 2000, message: "The message has been sent", show: true,});
                this.message = ""
                this.$emit('refreshChat', this.selectedUser, this.currentuser);
                this.$nextTick(() => {
                  this.scrollToBottom();
      });
            } catch (error) {
                // this.MIX_alertBox({color: "error", timeout: 2000, message: "Error sending the message", show: true,});
                console.error("Error: ", error);
            }
        }
    },
      
      /**
       * Ensures that the chat component always scrolls to the last message
       */
      scrollToBottom() {
      const container = this.$refs.chat;
      container.scrollTop = container.scrollHeight;
    },
      async updateMessagesToRead(chat){
                  // console.log("RUNNING", this.chat)
          chat.forEach ( message => {

                if (message.createdUserId !== this.currentuser.id && !message.messageRead){
                  // console.log("messageREAD", message)
                  message.messageRead = true
                   this.REDIS_update("message", message.entityId, message);
                }
            })
          this.unreadMessages();
      },
      // Fetch unread messages and sets the number of unread messages to the local storage
        async unreadMessages() {
            let t = this;
            // Fetches unread messages
            let unreadMessagessearchQuery = `@messageRecipient:{${t.GET_FIREBASE_userAuth.uid}} @messageStatus:{Sent} @messageRead:{false} @deleted:{false}`;
            let unreadMessages = await t.REDIS_searchFor("message", null, null, null, null, unreadMessagessearchQuery);
            let unreadNewMessages = unreadMessages.data.documents;
            let unreadmessagestotal = unreadNewMessages.length;
            let setMessages = localStorage.setItem("unreadMessages", unreadmessagestotal);
        },
    },
    // * WATCH
    watch: {
        // datadown is watching changes for new questions coming from the parent
      chat: {
        handler() {
                this.initialise();
            },
            deep: true,
      }
        },
    
    // * UPDATED 
    updated() {
      this.scrollToBottom();
      this.updateMessagesToRead(this.chat)
    },

    // * MOUNTED
    mounted(){
      this.updateMessagesToRead(this.chat)
    },

    // * CREATED
    async created() {
    },
  };
</script>

<style scoped>

  .chat-history {
    padding-top: 16px;
    padding-bottom: 16px;
    overflow-y:auto;
    height:328px;
    
  }

 @media screen and (min-height: 560px){
    .chat-history{
      height: 220px;
    }
  }

@media screen and (min-height: 660px){
    .chat-history{
      height: 320px;
    }
  }

@media screen and (min-height: 740px){
    .chat-history{
      height: 360px;
    }
  }

  @media screen and (min-height: 850px){
    .chat-history{
      height: 480px;
    }
  }

  @media screen and (min-height: 1000px){
    .chat-history{
      height: 600px;
    }
  }

  @media screen and (min-height: 1100px){
    .chat-history{
      height: 746px;
    }
  }

  @media screen and (min-height: 1200px){
    .chat-history{
      height: 840px;
    }
  }
  
  .messageButton {
    padding-top: 16px;
    padding-bottom: 16px;
    /* position: relative !important;
    bottom: 0; */
  }

  .footerbanner {
    z-index: 1 !important;
    top: 0px;
    width: 100% !important;
    position: relative !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 28px !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
