<template>
    <v-row no-gutters class="pa-0 ma-0 rounded-km">
        <v-col cols="12" xs="12" md="12" class="ma-0 pa-0 rounded-km">
            <v-stepper v-for="(poll, pollIndex) in pollsdata" :key="pollIndex" v-model="currentPollIndex" class="rounded-km">
                <v-stepper-items class="rounded-km">
                    <v-stepper-content :step="pollIndex"  class="rounded-km pa-0 ma-0" >
                        <v-card style="border: 1px solid #D3D3D3 !important;width:100%;" class="elevation-4 rounded-km grey lighten-4 d-flex flex-column align-center justify-center" min-height="40%" width="100%">
                            <!-- <v-icon @click="closePollPopUp" class="pt-4 primary--text d-flex justify-end px-2" style="width: 100%">icons8-cancel</v-icon> -->
                            <div class="py-5 d-flex flex-column align-space-between rounded-km">
                                <div v-if="!pollResults || currentPollResult !== pollIndex" class="d-flex flex-column align-center justify-center">
                                    <v-col cols="12" xs="12" xl="8" class="pa-2 d-flex flex-column align-center justify-center">
                                        <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="text-justify black--text">{{ poll.questionName }}</div>
                                    </v-col>
                                    <v-col cols="12" xs="12" xl="8" class="d-flex flex-wrap mt-2 flex-grow-1 flex-shrink-0 justify-space-around" style="min-width: 100px; max-width: 70%">
                                        <div @click="handleAnswers(pollIndex, poll.entityId, answer.text)" v-for="(answer, answerIndex) in JSON.parse(poll.questionAnswers)" :key="answerIndex" :class="smalldevice ? 'kindmind-font-h7' : 'kindmind-font-h6'" :label="`${answer.text}`" :value="answer.text">
                                            <v-btn v-model="pollAnswer" class="primary ma-1" block>{{ answer.text }}</v-btn>
                                        </div>
                                    </v-col>
                                </div>
                                <!-- Polls results -->
                                <div v-else-if="pollResults && currentPollResult === pollIndex" style="min-width: 62vw">
                                    <bar-chart :seriesdata="sortDataForChart()" seriestext="Poll Results" />
                                </div>

                                <div class="d-flex justify-center d-block">
                                    <v-btn color="primary" @click="currentPollIndex == pollsdata.length - 1 ? `${closePollPopUp()}` : `${(currentPollIndex = pollIndex + 1)}`">{{ currentPollIndex == pollsdata.length - 1 ? "Complete" : "Next" }}</v-btn>
                                </div>
                            </div>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>
import BarChart from "@/components/echarts/BarChart.vue";
export default {
    name: "polls-popup-component",
    components: {
        BarChart,
    },
    props: {
        pollsdata: {
            type: Array,
            default: () => [],
        },
        popupVisible: {
            type: Boolean,
            default: false,
        },
        currentUser: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        // Polls data
        e1: 0,
        pollAnswer: "",
        currentPollIndex: 0,

        // Polls results
        pollResults: false,
        currentPollResult: null,
        allPollAnswers: [],
        useranswer: { questionId: "", questionSection: "", questionAnswers: [], createdUserId: "", createdOrgId: "", questionAnswersValue: null, createdDate: null, modifiedUserId: "", modifiedOrgId: "", modifiedDate: null, deletedUserId: "", deletedOrgId: "", deletedDate: null, deleted: false },
        defaultUseranswer: {
            questionId: "",
            questionSection: "",
            questionInsights: "",
            questionAnswers: [],
            createdUserId: "",
            createdOrgId: "",
            createdDate: null,
            modifiedUserId: "",
            modifiedOrgId: "",
            modifiedDate: null,
            deletedUserId: "",
            deletedOrgId: "",
            deletedDate: null,
            deleted: false,
        },
        myuseranswers: [],
    }),
    methods: {
        // close pop up
        closePollPopUp() {
            this.$emit("closePollPopUp");
        },
        // handleAnswers copies the needed information from questions and the user answer and calls sendAnswers
        async handleAnswers(index, questionId, answer) {
            this.pollResults = true;
            this.currentPollResult = index;

            // Get Current User Data
            // Get Current Date Time
            let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
            this.useranswer.questionId = questionId;
            this.useranswer.questionSection = "Poll";
            this.useranswer.questionAnswers = [answer];
            this.useranswer.createdUserId = this.currentUser.createdUserId;
            this.useranswer.createdOrgId = this.currentUser.userOrgId;
            this.useranswer.createdDate = currentDateTime;
            await this.sendPollAnswers();
            this.getAnswers(questionId);
            this.useranswer.questionAnswers = [];
        },
        // Sends answers to redis and clears useranswer
        async sendPollAnswers() {
            // console.log("running 2", this.useranswer);
            await this.REDIS_create("useranswer", this.useranswer);
            this.useranswer = { ...this.defaultUseranswer };
        },
        //gets answers from redis for the polls once they've been answered
        async getAnswers(questionId) {
            let t = this;
            let searchQuery = `@questionId:{${questionId}} @deleted:{false}`;
            let contentResult = await this.REDIS_searchFor("useranswer", null, null, null, null, searchQuery);
            t.allPollAnswers = contentResult.data.documents;
            this.sortDataForChart();
        },
        // sorts the data for the chart in preparation for the user answers to the charts
        sortDataForChart() {
            let t = this;
            let stats = [];
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.questionAnswers] = (acc[obj.questionAnswers] || 0) + 1;
                return acc;
            }, {});
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map((k) => ({
                name: k,
                value: objResult[k],
            }));
            //* Assign key value pairs to graph structure
            arrResult.forEach((stat) => {
                stats.push({
                    name: stat.name,
                    value: stat.value,
                });
            });
            return stats;
        },
    },

    // * COMPUTED
    computed: {
                smalldevice() {
            return window.innerWidth < 700;
        },
    }
};
</script>
