<template>
    <div v-if="getContent" class="fullviewpoint allowscrolling white mb-10">
        <app-bar-component app :appbarsection="'Social'" :appbarcolour="'primary'" :backbutton="false" :setmargin="$vuetify.breakpoint.xsOnly ? 'mx-4' : 'mx-10'" />
        <!--Page Loader-->
        <pageLoader v-if="isPageLoading" />
        <div v-if="$vuetify.breakpoint.smAndUp">
            <br />
        </div>
        <div v-if="!computedIsPageLoading" class="white--text d-flex align-center flex-column white" style="background-color: white !important; min-height: 100vh !important" :class="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'">
            <!-- MENU BUTTONS -->
            <div v-if="!hidebuttons" class="d-flex align-center justify-start" style="width: 100%">
                <v-chip-group v-model="filters" row class="my-4" active-class="primary">
                    <v-chip filled
                            v-for="(button, buttonIndex) in menuButtons"
                            :key="buttonIndex"
                            @click="handleClickedButton(button)"
                            :class="currentFilter === button ? 'primary white--text' : ''"
                            class="rounded-km text-capitalize black--text px-4"
                            :value="button">
                        {{ button }}
                    </v-chip>
                </v-chip-group>
                <v-spacer />
                <v-btn v-if="!hideNewPostButton" @click="newPostRequest" fab small class="primary rounded-circle black--text kindmind-font-h4" max-height="10vw"><v-icon>icons8-plus-math</v-icon> </v-btn>
            </div>
            <!-- Main content -->

            <component :key="componentKey" :is="component" :currentUser="currentUser" :isPostsDataLoaded="isPostsDataLoaded" :datadown="content" :section="section" @returnToSocial="refreshFeed" @openComments="openComments" :class="$vuetify.breakpoint.xsOnly ? '' : 'px-10'"></component>
            <div v-if="$vuetify.breakpoint.xsAndUp">
                <br />
                <br />
                <br />
            </div>
        </div>
        <!-- BOTTOM NAV -->
        <bottom-navigation :setpadding="$vuetify.breakpoint.xsOnly ? 'px-4' : 'px-10'" />
    </div>
</template>

<script>
import BottomNavigation from "@/components/BottomNavigation.vue";
import PollsComponent from "./PollsComponent.vue";
import PostsComponent from "./PostsComponent.vue";
import PostComments from "./PostComments.vue";
import { mapGetters } from "vuex";

export default {
    components: { PollsComponent, BottomNavigation, PostsComponent, PostComments },
    name: "social-feed",
    data: () => ({
        // searquery for redis
        searchQuery: "",

        //components
        component: "posts-component",
        componentKey: 0,
        polls: false,
        section: "post",
        filters: "",
        currentFilter: "New",

        content: [],
        currentUser: {},
        computedPost: {
            postTitle: "",
            postText: "",
            postImageId: "",
            postVideoId: "",
            postAudioId: "",
            postLink: "",
            postLocation: "",
            postStatus: "",
            createdUser: "",
            createdOrgId: "",
        },

        computedPostsData: [],
        defaultcomputedPost: {
            postTitle: "",
            postText: "",
            postImageId: "",
            postVideoId: "",
            postAudioId: "",
            postLink: "",
            postLocation: "",
            postStatus: "",
            createdUser: "",
            createdOrgId: "",
        },

        isfavourite: false,

        // page loaders
        isPageLoading: false,
        isPostsDataLoaded: false,

        //buttons menu
        hidebuttons: false,
        menuButtons: ["New", "Hot", "Polls"],
        hideNewPostButton: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        computedIsPageLoading() {
            const t = this;
            t.isPageLoading = !t.isPostsDataLoaded;
            return t.isPageLoading;
        },
    },
    methods: {
        // handle the clicking between sections
        handleClickedButton(buttonclicked) {
            if (buttonclicked === "Polls") {
                this.searchQuery = `@questionSection:{Poll}`;
                this.hideNewPostButton = true;
                this.section = "question";
                this.component = "polls-component";
                this.currentFilter = "Polls";
                this.getContent(buttonclicked);
            } else if (buttonclicked === "Hot") {
                // if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                    this.searchQuery = `@createdDate:[${this.$moment().subtract(7, "days").unix()} ${this.$moment().unix()}] @createdOrgId:{${this.currentUser.userOrgId}}`;
                // } else {
                //     this.searchQuery = `@createdDate:[${this.$moment().subtract(7, "days").unix()} ${this.$moment().unix()}]`;
                // }
                this.component = "posts-component";
                this.section = "post";
                this.hideNewPostButton = false;
                this.currentFilter = "Hot";
                this.getContent(buttonclicked);
            } else {
                // if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                    this.searchQuery = `@createdOrgId:{${this.currentUser.userOrgId}}`;
                // }
                this.component = "posts-component";
                this.section = "post";
                this.hideNewPostButton = false;
                this.currentFilter = "New";
                this.getContent(buttonclicked);
            }
        },
        // fetches content from redis with the given filters. If posts, then it will fetch likes and comments and adds them to the object
        async getContent(buttonclicked) {
            try {
                this.isPostsDataLoaded = false;
                let section = this.section;

                if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G" && section === `post` && !buttonclicked) {
                    this.searchQuery = `@createdOrgId:{${this.currentUser.userOrgId}}`;
                }
                let contentResult = await this.REDIS_searchFor(`${section}`, null, null, null, null, `@deleted:{false} + ${this.searchQuery}`);
                let finalContent = contentResult.data.documents;

                // If the button clicked is polls, then it will filter the content to only show polls
                // console.log(buttonclicked);
                if (buttonclicked === "Polls") {
                    this.content = [];
                    // Checks user organisation and filters the polls to only show the ones that are for the user's organisation and department
                    // if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                        let usersMatchesOrg = finalContent.filter((content) => content.questionOrgRecipient == this.currentUser.userOrgId);
                        let usersMatchesDepartment = usersMatchesOrg.filter((content) => content.questionDepartment == this.currentUser.userDepartment || content.questionDepartment == "N/A" || content.questionDepartment == "Other" || content.questionDepartment == "");
                        finalContent = usersMatchesDepartment;
                    // }

                    // Gets the current date and time
                    let currentDateTime = parseInt(this.MIX_formatDateTime(new Date(), "yyyy-MM-dd HH:mm:ss", "X"));
                    let contentDate = [];
                    // Checks if the polls are within the date range
                    for (let i = 0; i < finalContent.length; i++) {
                        let unixStartDate = this.MIX_formatDateTime(finalContent[i].questionFrom, "YYYY-MM-DDTHH:mm:ss.SSSZ", "X");
                        let unixEndDate = this.MIX_formatDateTime(finalContent[i].questionTo, "YYYY-MM-DDTHH:mm:ss.SSSZ", "X");
                        if (unixStartDate <= currentDateTime && unixEndDate >= currentDateTime) {
                        finalContent[i].alreadyRepliedTo = false;
                            contentDate.push(finalContent[i]);
                        }
                    }
                    // decides whether the user has already replied to the poll or not
                    for (let poll of contentDate) {
                        let searchQuery = `@createdUserId:{${this.currentUser.id}} @deleted:{false} @questionId:{${poll.entityId}}`;
                        let contentResult = await this.REDIS_searchFor("useranswer", null, null, null, null, searchQuery);
                        // console.log(searchQuery);
                        let pollAnswer = contentResult.data.documents;
                        poll.alreadyRepliedTo = pollAnswer && pollAnswer.length > 0;
                    }
                    // sorts data - newest first
                    contentDate.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
                    this.content = contentDate;
                } else {
                    //* Get comments for each post
                    for (let post of finalContent) {
                        let searchQuery = "";
                        // if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                            searchQuery += ` @publishOrgId:{${this.currentUser.userOrgId}}`;
                        // }
                        let getPostComments = await this.REDIS_searchFor(`postcomment`, null, null, null, null, `@postEntityId:{${post.entityId}} + ${searchQuery}`);
                        let searchQueryStat = `@statType:{heart} @deleted:{false} @statRelatedId:{${post.entityId}}`;
                        // if (this.currentUser.userOrgId !== "01GWPH8N0ET78SMT603TEQSJ8G") {
                            searchQueryStat += ` @createdOrgId:{${this.currentUser.userOrgId}}`;
                        // }
                        let getLikes = await this.REDIS_searchFor("stat", null, null, null, null, searchQueryStat);
                        post.postComments = getPostComments.data.documents;
                        post.postCommentCount = getPostComments.data.documents.length;
                        post.postLikes = getLikes.data.documents;
                        post.isFavourite = getLikes.data.documents.some((like) => {
                            if (like.createdUserId === this.currentUser.id) {
                                return true;
                            }
                        });
                        post.postLikesCount = getLikes.data.documents.length;
                    }

                    if (buttonclicked === "Hot") {
                        finalContent.sort((a, b) => b.postLikesCount - a.postLikesCount);
                        this.content = finalContent;
                    } else {
                        finalContent.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
                        this.content = finalContent;
                    }
                }
                this.componentKey++;
                this.isPostsDataLoaded = true;
            } catch (error) {
                console.error("Error: ", error);
            }
        },
        async readCurrUser() {
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, "users");
            this.currentUser = userDataResult.data;
            this.getContent();
        },
        newPostRequest() {
            this.$router.push("/social/newPost");
            this.hidebuttons = true;
            this.component = "new-post-component";
        },
        async refreshFeed(value) {
            this.hidebuttons = false;
            if (value === "Poll") {
                this.hideNewPostButton = true;
                this.component = "polls-component";
                this.section = "question";
            } else {
                this.hideNewPostButton = false;
                this.component = "posts-component";
                this.section = "post";
            }
            await this.getContent();
            this.componentKey++;
        },
        openComments(value) {
            this.hidebuttons = true;
            this.component = "post-comments";
            this.componentKey++;
            this.content = value;
        },
    },
    async created() {
        this.readCurrUser();
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.menuDisplay {
    display: none !important;
}
</style>
