<template>
    <v-row class="d-flex align-start justify-center mb-16" :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : ' my-8'" style="width: 100% !important">
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="ma-0 pa-0 mb-10">
            <v-col cols="12" sm="12" md="" lg="12" class="d-flex justify-start align-center">
                <v-btn @click="backToSocial(post)" icon large><v-icon class="grey--text">icons8-back-to</v-icon></v-btn>
                <div class="d-flex align-start font-weight-bold kindmind-font-cap">Viewing comments for {{ post.postTitle ? post.postTitle : post.questionName }}</div>
            </v-col>
            <v-card v-if="section === 'post'" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-center pb-0">
                    <v-col cols="8" class="d-flex pa-0">
                        <v-avatar class="primary mr-2 rounded-lg">
                            <v-img :src="post.createdUserPhoto" contain></v-img>
                        </v-avatar>
                        <div class="d-flex flex-column">
                            <div class="grey--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndDown ? '' : 'kindmind-font-b2'">{{ post.createdUserName }}</div>
                            <div class="grey--text text-left" :class="smalldevice ? 'kindmind-font-b2' : 'kindmind-font-b2'">Author</div>
                        </div>
                    </v-col>
                </v-col>
                <div class="my-5">
                    <v-row dense class="d-flex flex-column px-4">
                        <v-col cols="12" xs="12" xl="8" class="d-flex flex-column align-center justify-center">
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-h7" style="width: 100%">{{ post.postTitle }}</div>
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="align-start kindmind-font-cap" style="width: 100%" v-html="post.postText"></div>
                        </v-col>
                        <v-col cols="12" xl="8" class="d-flex flex-column align-start justify-center">
                            <v-img :src="post.postImageId" max-height="250" class="rounded mb-4"></v-img>
                            <v-btn-toggle class="grey lighten-4">
                                <v-btn v-for="flair in post.postFlair" :key="flair" x-small class="mr-2 primary white--text rounded-km">{{ flair }}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </div>
                <v-divider></v-divider>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-start pa-0">
                    <v-col cols="10" class="pb-0">
                        <v-text-field v-model="postComment" outlined text :height="smalldevice ? 35 : 55" hide-details="auto" class="rounded-km mb-8" placeholder="Post a comment..." dense></v-text-field>
                    </v-col>
                    <v-col cols="2" class="pb-0">
                        <v-btn icon large class="primary" @click="sendPostComment"><v-icon class="white--text" color="white">icons8-email-send</v-icon></v-btn>
                    </v-col>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex flex-column align-center pb-0">
                    <v-card v-for="(comment, commentIndex) in postComments" :key="commentIndex" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'" width="100%">
                        <v-card-title :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-h7" style="width: 100%">
                            <v-avatar v-if="comment.createdUserPhoto" class="rounded mr-2">
                                <v-img v-if="comment.createdUserPhoto" :src="comment.createdUserPhoto" contain></v-img>
                            </v-avatar>
                            <v-avatar v-else class="rounded mr-2" :color="randomPastelColor()">
                                {{ comment.createdUserName.match(/\b(\w)/g).join('') }}
                            </v-avatar>
                            {{ comment.createdUserName }}
                        </v-card-title>
                        <v-card-text :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-cap" style="width: 100%">
                            {{ comment.postComment }}
                        </v-card-text>
                        <v-card-actions :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-end justify-end font-weight-bold kindmind-font-cap" style="width: 100%">
                            Posted on {{ $moment(comment.createdDate).format('DD/MM/YYYY HH:mm a') }}
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-card>
            <!-- Polls comments -->
            <v-card v-if="section === 'question'" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-center pb-0">
                    <v-col cols="8" class="d-flex pa-0">
                        <v-avatar class="primary mr-2 rounded-lg">
                            <v-img :src="post.createdUserPhoto" contain></v-img>
                        </v-avatar>
                        <div class="d-flex flex-column">
                            <div class="grey--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndDown ? '' : 'kindmind-font-b2'">{{ post.createdUserName }}</div>
                            <div class="grey--text text-left" :class="smalldevice ? 'kindmind-font-b2' : 'kindmind-font-b2'">Author</div>
                        </div>
                    </v-col>
                </v-col>
                <div class="my-5">
                    <v-row dense class="d-flex flex-column px-4">
                        <v-col cols="12" xs="12" xl="8" class="d-flex flex-column align-center justify-center">
                            <div :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-h7" style="width: 100%">{{ post.questionName }}</div>
                        </v-col>
                        <div>
                            <bar-chart :seriesdata="sortDataForChart()" seriestext="Poll Results" />
                        </div>
                    </v-row>
                </div>
                <v-divider></v-divider>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex align-start pa-0">
                    <v-col cols="10" class="pb-0">
                        <v-text-field v-model="postComment" outlined text :height="smalldevice ? 35 : 55" hide-details="auto" class="rounded-km mb-8" placeholder="Post a comment..." dense></v-text-field>
                    </v-col>
                    <v-col cols="2" class="pb-0">
                        <v-btn icon large class="primary" @click="sendPostComment"><v-icon class="white--text" color="white">icons8-email-send</v-icon></v-btn>
                    </v-col>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex flex-column align-center pb-0">
                    <v-card v-for="(comment, commentsIndex) in postComments" :key="commentsIndex" class="pa-2 elevation-0 rounded-km grey lighten-4 my-2" :style="'border: 1px solid #D3D3D3 !important;'" width="100%">
                        <v-card-title :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-h7" style="width: 100%">
                            <v-avatar v-if="comment.createdUserPhoto" class="rounded mr-2">
                                <v-img v-if="comment.createdUserPhoto" :src="comment.createdUserPhoto" contain></v-img>
                            </v-avatar>
                            <v-avatar v-else class="rounded mr-2" :color="randomPastelColor()">
                                {{ comment.createdUserName.match(/\b(\w)/g).join('') }}
                            </v-avatar>
                            {{ comment.createdUserName }}
                        </v-card-title>
                        <v-card-text :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-start font-weight-bold kindmind-font-cap" style="width: 100%">
                            {{ comment.postComment }}
                        </v-card-text>
                        <v-card-actions :dense="smalldevice" :height="smalldevice ? 35 : 55" hide-details="auto" class="d-flex align-end justify-end font-weight-bold kindmind-font-cap" style="width: 100%">
                            Posted on {{ $moment(comment.createdDate).format('DD/MM/YYYY HH:mm a') }}
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-card>
        </v-col>
        <div style="margin-top: 12px !important"></div>
        <div class="footerbanner"></div>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import BarChart from '@/components/echarts/BarChart.vue'

export default {
    name: 'post-comments',
    props: {
        datadown: {
            type: Object,
            default: () => {}
        },
        section: {
            type: String,
            default: null
        }
    },
    components: {
        BarChart
    },
    data: () => ({
        allPollAnswers: [],
        currentUser: {},
        comment: {},
        post: {},
        postComment: '',
        postComments: []
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth'
        }),
        smalldevice() {
            return window.innerHeight < 600
        }
    },
    methods: {
        async getPostComments() {
            this.postComments = []
            let searchQuery = `@postEntityId:{${this.post.entityId}} @publishOrgId:{${this.currentUser.userOrgId}}`
              // Checks user organisation and filters the polls to only show the ones that are for the user's organisation and department
            // if (this.currentUser.userOrgId !== '01GWPH8N0ET78SMT603TEQSJ8G') {
            //     searchQuery += `@publishOrgId:{${this.currentUser.userOrgId}}`;
            // }
            let getPostComments = await this.REDIS_searchFor(`postcomment`, null, null, null, null, searchQuery)
            // console.log('getPostComments: ', getPostComments)
            if (getPostComments.data !== {}) {
                getPostComments.data.documents.forEach((comment) => {
                    this.postComments.push(comment)
                })
                this.postComments.sort((a, b) => b.createdDate.localeCompare(a.createdDate))
            }
        },
        async sendPostComment() {
            try {
                this.comment.postEntityId = this.post.entityId
                this.comment.postComment = this.postComment
                this.comment.createdUserId = this.currentUser.id
                this.comment.createdUserName = this.currentUser.userName
                this.comment.createdUserPhoto = this.currentUser.userProfile
                this.comment.publishOrgId = this.currentUser.userOrgId
                this.comment.createdDate = parseInt(this.MIX_formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss', 'X'))
                this.comment.deletedUserId = ''
                this.comment.deletedDate = null
                this.comment.deleted = false
                const redisCreate = await this.REDIS_create('postcomment', this.comment)
                if (redisCreate.data !== {}) {
                    this.alertData = { title: 'Comment posted', message: 'Success! your comment has been posted', color: 'success', timeout: 2000, show: true }
                    this.getPostComments()
                    this.postComment = ''
                }
            } catch (error) {
                this.alertData = { title: 'Error posting', message: 'Error posting comment', color: 'error', timeout: 2000, show: true }
                console.error('Error: ', error)
            }
        },
        async getAnswers(entityId) {
            let t = this
            let searchQuery = `@questionId:{${entityId}} @deleted:{false}`
            let contentResult = await this.REDIS_searchFor('useranswer', null, null, null, null, searchQuery)
            t.allPollAnswers = contentResult.data.documents
            this.sortDataForChart()
        },
        sortDataForChart() {
            let t = this
            let stats = []
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.questionAnswers] = (acc[obj.questionAnswers] || 0) + 1
                return acc
            }, {})
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map((k) => ({
                name: k,
                value: objResult[k]
            }))
            //* Assign key value pairs to graph structure
            arrResult.forEach((stat) => {
                stats.push({
                    name: stat.name,
                    value: stat.value
                })
            })
            return stats
        },
        randomPastelColor() {
            let r = (Math.round(Math.random() * 127) + 127).toString(16)
            let g = (Math.round(Math.random() * 127) + 127).toString(16)
            let b = (Math.round(Math.random() * 127) + 127).toString(16)
            return '#' + r + g + b
        },
        async readCurrUser() {
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users')
            this.currentUser = userDataResult.data
            this.getPostComments()
        },
        backToSocial(post) {
            if (post.questionSection !== '') {
                this.$emit('returnToSocial', post.questionSection)
            } else {
                this.$emit('returnToSocial')
            }
        }
    },
    mounted() {
        this.readCurrUser()
        this.post = this.datadown
        this.section === 'question' ? this.getAnswers(this.post.entityId) : null
    }
}
</script>

<style scoped>
* {
    box-shadow: none !important;
}

.imageStyle {
    height: 8vh;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 20vh !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
