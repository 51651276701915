<template>
    <div v-if="readCreators" class="allowscrolling" style="background-color: white !important; height: 100% !important">
        <div :class="$vuetify.breakpoint.xsOnly ? 'ma-4' : 'ma-10'">
            <div class="d-flex py-1">
                <!-- Settings Button -->
                <v-spacer />
            </div>
            <!-- Profile image and info  -->
            <v-row class="d-flex justify-start mt-0" no-gutters>
                <v-col cols="12" xs="8" lg="6" xl="6" :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-space-between align-end' : 'd-flex align-end'">
                        <div class="d-flex">
                        <img v-if="contentCreator.userProfile" class="rounded-km" :src="contentCreator.userProfile" :style="$vuetify.breakpoint.xsOnly ? 'aspect-ratio: 1/1 !important; width: 160px !important;height: 160px !important;' : 'aspect-ratio: 1/1 !important; width: 200px !important;height: 200px !important;'"/>

                    <!--If an image isn't present, render an icon-->
                         <div v-else :class="`primary rounded-km d-flex justify-center align-center`" :style="$vuetify.breakpoint.xsOnly ? 'width: 160px !important;height: 160px !important;' : 'width: 200px !important;height: 200px !important;'">
                         <v-icon  class="icons8-user white--text" :size="$vuetify.breakpoint.xsOnly ? 102 : 142"> </v-icon></div>

                       <div class="d-flex flex-column pl-2">
                        <div text class="text-start align-center d-flex primary--text" style="height: 20% !important" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-title-font-h6' : 'ml-4 kindmind-title-font-h5'">{{ contentCreator.userName }}</div>
                        <div class="text-start" :class="$vuetify.breakpoint.xsOnly ? 'kindmind-font-b2' : 'ml-4 kindmind-font-h7'">{{ contentCreator.userLevel }}</div>
                   </div> 
                   </div>
                </v-col>
            </v-row>

            <!-- Notifications panel -->
            <v-row v-if="contentCreator.userBio && contentCreator.userBio !== ''">
                <v-col cols="12" xs="12" sm="12" lg="8" class="rounded-km d-flex flex-row justify-space-between">
                    <v-card text>
                        <div class="primary--text" :class="$vuetify.breakpoint.smAndUp ? `text-h6 ` : `text-h7`">About {{ firstName }}</div>
                        <div>{{contentCreator.userBio}}</div>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Component - Other content by creator -->
            <v-row class="d-flex flex-row my-5" no-gutters>
                <v-col cols="12" xs="12" lg="8">
                    <div class="primary--text pb-2" :class="$vuetify.breakpoint.smAndUp ? `text-h6 ` : `text-h7`">More by <span>{{ firstName }}</span> </div>
                    <user-content-component :datadown="sections" @newResource="openSelectedMedia" />

                </v-col>
                <div class="footerbanner"></div>
            </v-row>
        </div>
         <div v-if="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>
</template>

<script>
import UserContentCarousel from "@/views/userProfile/UserContentCarousel.vue";
export default {
    components: { UserContentCarousel },
    name: "content-creator-component",
    props: ["creatorprofile"],

    data: () => ({
        userfavourites: [],
        overlay: false,
        contentCreator: [],
        content: [],
        firstName: "",
        chosenSection: "",
        sections: [
            { section: "pause", content: [] },
            { section: "move", content: [] },
            { section: "fuel", content: [] },
            { section: "grow", content: [] },
        ],
    }),
    // * METHODS
    methods: {
        // Gets information from redis
        async getCreatorContent() {
            let searchQuery = `@creatorUserId:{${this.creatorprofile}} @deleted:{false}`;
            for (let i = 0; i < this.sections.length; i++) {
                let contentResult = await this.REDIS_searchFor(`${this.sections[i].section}`, null, null, null, `${this.sections[i].section}Title`, `${searchQuery} @${this.sections[i].section}Status:{Live}`);
                if (contentResult !== [] && contentResult !== "" && contentResult.data.documents.length !== 0) {
                    this.sections[i].content.push(contentResult.data.documents);
                }
            }
            this.firstNamefunc();
        },
        // Retrives userName and cuts it so it only returns the first name
        firstNamefunc() {
            let userName = this.contentCreator.userName;
            let indexOfSpace = userName.indexOf(" ");
            if (indexOfSpace === -1) {
                this.firstName = userName;
            } else {
                this.firstName = userName.substring(0, indexOfSpace);
            }
        },
        // Receives the information passed from ActivityButtonsComponent and converts it into an object so it can be passed down to the media view
        openSelectedMedia(value, section) {
            this.MIX_go({ name: "media-page", params: { data: value, section: section, explore: this.fromExplore, id: `${value.entityId}` } });
        },
    },
    // * COMPUTED
    computed: {
        // Reads the information from the creator of the post
        async readCreators() {
            try {
                this.contentCreator = await this.MIX_firestore_readManyWhereCondition("users", "id", "==", `${this.creatorprofile}`);
                return (this.contentCreator = this.contentCreator[0]);
            } catch (error) {
                console.error("Error: ", error);
            }
        },
    },

    // * CREATED
    created() {
        // renders redis content on loading
        this.exploreId = this.$route.params.exploreId;
        this.getCreatorContent();
    },
};
</script>

<style scoped>
* {
    box-shadow: none !important;
}

img {
    /* height: 20vh !important; */
    width: 20vh !important;
}

.footerbanner {
    z-index: 1 !important;
    bottom: 0px;
    width: 100vw !important;
    position: absolute !important;
    /* left: 24%; */
    /* margin-bottom: 80px !important; */
    height: 20vh !important;
    background: -moz-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(255, 255, 255) 65%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
