<template>
<div>
    <v-select v-if="inputType === 'select'"
    id="mySelect"
                  @input="updateValue($event)"
                  class="rounded-pill white--text"
                  :background-color="backgroundColorSet ? `${backgroundColorSet}` : 'grey lighten-3'"
                  :clearable="clearable"
                  :append-icon="appendIcon"
                  :chips="chips"
                  :deletable-chips="deletableChips"
                  :error="error"
                  :error-messages="errorMessages"
                  filled
                  :menu-props="{ top: false, offsetY: true }"
                  flat
                  :size="iconSize"
                  dense
                  solo
                  hide-details="auto"
                  :items="items"
                  :item-text="itemText && itemText"
                  :label="label"
                  :multiple="multiple"
                  :return-object="returnObject"
                  :small-chips="smallChips"
                  :value="value"/>
                  


    <v-menu offset-y v-if="inputType === 'burger'">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :class="`${buttonStyle}`" :color="colour" text v-bind="attrs" v-on="on" solo small > <v-icon @click="deleteFilter" :class="`${iconstyle}`" :size="iconSize">{{icon}} </v-icon><span class="text-capitalize">{{text}}</span></v-btn>
        </template>
        <v-list class="grey lighten-3">
            <v-list-item @click="handleSelection(dropdownindex, text)" class="py-0 ma-0" v-for="(item, dropdownindex) in datadown" :key="dropdownindex">
                <v-list-item-title class="kindmind-font-b2">{{ item.dropdownItem }} </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</div>

</template>

<script>
export default {
    name: "dropdown-menu-component",
    props: {
        appendIcon: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        buttonStyle: {
            type: String,
            default: null,
        },
        iconstyle: {
            type: String,
            default: null,
        },
        colour: {
            type: String,
            default: null,
        },
        datadown: {
            type: Array,
            default: () => [],
        },
         iconSize: {
            type: String,
            default: null,
        },
        inputType: {
            type: String,
            default: null,
        },
        appendIcon: {
            type: String,
            default: null,
        },
        backgroundColorSet: {
            type: String,
            default: null,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        chips: {
            type: String,
            default: null,
        },
        backgroundColor: {
            type: String,
            default: null,
        },
        deletableChips: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        error: {
            type: String,
            default: null,
        },
       smallChips: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        itemText: {
            type: String,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    label: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
    },

    // * COMPUTED
    computed: {

    },
    // * METHODS
        methods: {
            // handleSelection: takes one parameter, the index of the seelected option from the dropdown menu and sends this information to its parent "AppBarComponent"
            handleSelection(item, text) {
                let selector = text
                this.$emit("handleSelection", item, selector);
            },
            deleteFilter() {
                this.$emit("deleteFilter");

            },         
            
            updateValue(value) {
            this.$emit('input', value)
        }
    
        },
            // * WATCH
    watch: {
        // Search Query
        handleSelection: {
            handler() {
                this.handleSelection();
            },
            deep: true,
        },
    },

};
</script>

<style >
#mySelect .v-input__slot .v-select {
    color: red !important;
}
</style>
<style scoped>

.buttonshape {
    border: none;
}

.feed-filterbutton-desktopView {
    height: 4vh !important;
    width: clamp(77px, 18vw, 220px) !important;
}

.feed-filterbutton-tabletView {
    height: 3vh !important;
    width: 22vw !important;
}

.feed-filterbutton-mobileView {
    height: 4vh !important;
    width: 29vw !important;
}
</style>
